import axios, { AxiosRequestConfig } from 'axios';

export type HttpMethod = 'GET' | 'POST' | 'PUT' | 'DELETE';

export interface ApiEndpoint<Req = undefined, Res = undefined, Path = void> {
  getUrl: (pathParams: Path) => string;
  method: HttpMethod;
  requestType?: new () => Req;
  responseType?: new () => Res;
  route: string;
}

export const createApiEndpoint = <Req, Res, Path>(config: {
  method: HttpMethod;
  route: string;
}): ApiEndpoint<Req, Res, Path> => {
  const { method, route } = config;
  return {
    getUrl: (pathParams?: Path) => {
      if (pathParams) {
        // Replace placeholders in the route with actual path parameters
        return Object.keys(pathParams).reduce(
          (url, key) => url.replace(`:${key}`, String((pathParams as any)[key])),
          route
        );
      }

      return route;
    },
    method,
    route
  };
};

type UrlParams<Path> = Path extends undefined | void ? { urlParams?: never } : { urlParams: Path };

type ApiCallParams<Req, Path> = UrlParams<Path> & {
  axiosConfig?: AxiosRequestConfig;
  requestBody?: Req;
};

export async function apiCall<Req, Res, Path>(
  endpoint: ApiEndpoint<Req, Res, Path>,
  params: ApiCallParams<Req, Path>
): Promise<Res> {
  const { axiosConfig, requestBody, urlParams } = params;

  const url = endpoint.getUrl(urlParams!);

  const config: AxiosRequestConfig = {
    headers: {
      'Content-Type': 'application/json'
    },
    method: endpoint.method,
    url,
    ...axiosConfig
  };

  if (endpoint.method !== 'GET' && endpoint.method !== 'DELETE') {
    config.data = requestBody;
  }

  const response = await axios(config);

  return response.data;
}
