import { Category } from './types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { initialCategoryState, parentCategoryIdOfRoot } from './data';

export const categoriesSlice = createSlice({
  initialState: initialCategoryState,
  name: 'categories',
  reducers: {
    categoryCollapsed: (draft, action: PayloadAction<Category>) => {
      const category = action.payload;
      const index = draft.expandedCategoryIds.findIndex((i) => i === category.parentCategoryId) + 1;

      draft.expandedCategoryIds.splice(index, draft.expandedCategoryIds.length - index);
    },
    categoryExpanded: (draft, action: PayloadAction<Category>) => {
      const category = action.payload;
      const index =
        draft.expandedCategoryIds.findIndex(
          (i) => i === (category?.parentCategoryId ?? parentCategoryIdOfRoot)
        ) + 1;

      draft.expandedCategoryIds.splice(
        index,
        draft.expandedCategoryIds.length - index,
        category?.id ?? parentCategoryIdOfRoot
      );
    },
    expandedCategoryIdsUpdated: (draft, action: PayloadAction<string[]>) => {
      draft.expandedCategoryIds = action.payload;
    }
  }
});

export default categoriesSlice.reducer;

export const { categoryCollapsed, categoryExpanded, expandedCategoryIdsUpdated } =
  categoriesSlice.actions;
