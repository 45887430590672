import { AppThunk } from 'RootTypes';
import { FIREBASETOKEN, getSettingsKey } from 'helpers/localStorage';
import {
  MessagePayload,
  NotificationPayload,
  getMessaging,
  getToken,
  onMessage
} from 'firebase/messaging';
import { apiUrls } from 'api';
import { firebaseSubscriptionFailed } from 'store/slices/session/slice';
import { getConfigs, useConfigQuery } from './settings';
import { initializeApp } from 'firebase/app';
import {
  loadFromLocalStorage,
  removeFromLocalStorage,
  saveToLocalStorage,
  useCatNavigate
} from '@borda/cat-core';
import { showSnackbar } from './snackbar';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useMarkNotificationAsReadByIdMutation } from 'views/Notifications/queries';
import axios from 'axios';

const createMessagingDynamically = () => {
  // Initialize Firebase
  const configs = getConfigs();
  const app = initializeApp(configs.common.firebase);
  const messaging = getMessaging(app);

  return messaging;
};

// getToken func. automatically call browser's allow notification pop-up
const getFirebaseToken = () => {
  const configs = getConfigs();
  const messaging = createMessagingDynamically();

  return getToken(messaging, {
    vapidKey: configs.common.firebase.vapidKey
  })
    .then((currentToken) => currentToken ?? undefined)
    .catch((err) => {
      // eslint-disable-next-line no-console
      console.log('An error occurred while retrieving firebase token. ', err);
      return undefined;
      // catch error while creating client token
    });
};

export const useFirebaseForegroundMessaging = () => {
  const { isSuccess } = useConfigQuery();
  const navigate = useCatNavigate();

  const { mutateAsync: markNotificationAsRead } = useMarkNotificationAsReadByIdMutation();

  useEffect(() => {
    if (isSuccess) {
      const messaging = createMessagingDynamically();

      onMessage(messaging, (payload) => {
        const { data, fcmOptions, notification } = payload as MessagePayload;
        const { body, title } = notification;

        const onClick = () => {
          const { pathname, search } = new URL(fcmOptions?.link);

          const targetUrl = pathname + search;

          const { notificationId } = data ?? {};
          if (notificationId) {
            markNotificationAsRead(notificationId);
          }

          navigate(targetUrl);
        };

        showSnackbar({
          message: body,
          onClick: fcmOptions?.link ? onClick : undefined,
          title,
          type: 'success'
        });

        if (data.AutoClickAction === 'True') {
          onClick();
        }
      });
    }
  }, [isSuccess, markNotificationAsRead, navigate]);
};

interface MessagePayloadInternal extends MessagePayload {
  isFirebaseMessaging?: boolean;
  messageType?: 'push-received' | 'notification-clicked';
  notification: NotificationPayloadInternal;
}

interface NotificationPayloadInternal extends NotificationPayload {
  click_action?: string;
}

export const useFirebaseBackgroundHelper = () => {
  const location = useLocation();
  const navigate = useCatNavigate();

  const { mutateAsync: markNotificationAsRead } = useMarkNotificationAsReadByIdMutation();

  useEffect(() => {
    const handleMessage = (event: MessageEvent<MessagePayloadInternal>) => {
      const payload = event.data;
      // Firebase sdk posts notification-clicked message after notification click op.
      // We use this event to navigate correct link after focusing
      if (
        !payload.isFirebaseMessaging ||
        payload.messageType !== 'notification-clicked' ||
        !payload.notification?.click_action
      ) {
        return;
      }

      const targetPathName = new URL(payload.notification.click_action).pathname;
      if (targetPathName === location.pathname) {
        return;
      }

      const notificationId = payload.data?.notificationId;
      if (notificationId) {
        markNotificationAsRead(notificationId);
      }

      navigate(targetPathName);
    };

    navigator.serviceWorker.addEventListener('message', handleMessage);

    return () => {
      navigator.serviceWorker.removeEventListener('message', handleMessage);
    };
  }, [location.pathname, markNotificationAsRead, navigate]);
};

export const subscribeToFirebase = (): AppThunk => async (dispatch) => {
  const firebaseToken = await getFirebaseToken();

  if (firebaseToken) {
    saveToLocalStorage(getSettingsKey(FIREBASETOKEN), firebaseToken);
    await subscribeNotificationWithToken(firebaseToken);
  } else {
    removeFromLocalStorage(getSettingsKey(FIREBASETOKEN));
    dispatch(firebaseSubscriptionFailed());
  }
};

export const unsubscribeFromFirebase = async () => {
  const firebaseToken = loadFromLocalStorage(getSettingsKey(FIREBASETOKEN));
  if (firebaseToken) {
    await unsubscribeNotificationWithToken(firebaseToken);
    removeFromLocalStorage(getSettingsKey(FIREBASETOKEN));
  }
};

const subscribeNotificationWithToken = async (notificationToken: string) => {
  await axios.post(apiUrls.notification.subscribe(), {
    notificationToken
  });
};

const unsubscribeNotificationWithToken = async (notificationToken: string) => {
  await axios.post(apiUrls.notification.unsubscribe(), {
    notificationToken
  });
};
