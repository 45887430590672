import FiltersAndSort from './FiltersAndSort/FiltersAndSort';
import Header from './Header';
import QuickStatusFilters from './QuickStatusFilters';

type Props = {
  onClose: () => void;
};

function PanelHeader(props: Props) {
  const { onClose } = props;

  return (
    <div>
      <Header onClose={onClose} />
      <QuickStatusFilters />
      <FiltersAndSort />
    </div>
  );
}

export default PanelHeader;
