export const quattroGeofenceLabel = 'quattro';

const geofencUrls = {
  addGeofence: () => '/api/public/middleware/geofences',
  deleteGeofence: (geofenceId: string) => `/api/public/middleware/geofences/${geofenceId}`,
  getGeofences: (floorId: string) =>
    `/api/public/middleware/geofences?locationId=${floorId}&labels=${quattroGeofenceLabel}`,
  updateGeofence: (geofenceId: string) => `/api/public/middleware/geofences/${geofenceId}`
};

const locationUrls = {
  getAllBranches: () => '/api/public/middleware/branches',
  getAllBuildings: () => '/api/public/middleware/buildings',
  getAllClients: () => '/api/public/middleware/clients',
  getAllFloors: () => '/api/public/middleware/floors',
  getFloorsByBuildingId: (buildingId: string) =>
    `/api/public/middleware/buildings/${buildingId}/floors`
};

export default { ...geofencUrls, ...locationUrls };
