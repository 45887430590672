import { CancelIcon, LoadingIcon, NotFoundIcon, SearchIcon } from '@borda/cat-icons';
import { CatChip, CatIconButton, CatTextField } from '@borda/cat-ui';
import { InputAdornment } from '@borda/cat-mui';
import { useCatTranslation } from 'utils/localization';

type Props = {
  found?: boolean;
  loading?: boolean;
  onChange?: (inputText: string) => void;
  onKeyDown?: (e: any) => void;
  searchText?: string;
};

function InputField(props: Props) {
  const { found, loading, onChange, onKeyDown, searchText } = props;
  const { t } = useCatTranslation();

  const handleInputChange = (e: any) => {
    onChange(e.target.value);
  };

  const clearInputValue = () => {
    onChange('');
  };

  const renderEndIcon = () => {
    if (loading) {
      return <LoadingIcon color="blue" contained={false} />;
    }
    if (!found && searchText) {
      return (
        <CatChip
          icon={<NotFoundIcon fontSize="small" />}
          label={t('layout.content_layout.search.not_found')}
          onClick={clearInputValue}
          onDelete={clearInputValue}
          onMouseDown={(e) => e.preventDefault()}
          size="medium"
          variant="outlined"
        />
      );
    }
    if (searchText) {
      return (
        <CatIconButton onClick={() => clearInputValue()} onMouseDown={(e) => e.preventDefault()}>
          <CancelIcon color="darkGrey" />
        </CatIconButton>
      );
    }
    return <div />;
  };

  return (
    <CatTextField
      autoFocus
      focused
      InputProps={{
        endAdornment: <InputAdornment position="end">{renderEndIcon()}</InputAdornment>,
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon contained={false} fontSize="medium" hoverable={false} opacity={0.8} />
          </InputAdornment>
        )
      }}
      onChange={handleInputChange}
      onKeyDown={onKeyDown}
      placeholder={t('layout.content_layout.search.search_placeholder')}
      value={searchText}
    />
  );
}

export default InputField;
