import { TASKLIST, getColumnsKey } from 'helpers/localStorage';
import { TaskItem } from '../../common/type';
import { createListAdapter } from '@borda/cat-core';
import { createSlice } from '@reduxjs/toolkit';
import { initialColumnDynamicData } from './data';

export const listAdapter = createListAdapter<TaskItem>({
  initialDynamicListDefinitions: initialColumnDynamicData,
  localStorageKey: getColumnsKey(TASKLIST)
});

export const taskListSlice = createSlice({
  initialState: listAdapter.getInitialState({}),
  name: 'taskList',
  reducers: {
    ...listAdapter.getReducers()
  }
});

export const taskListActions = taskListSlice.actions;

export default taskListSlice.reducer;
