import { combineReducers } from 'redux';
import myExportsFilterSlice from './myExportsFilter';
import myExportsListSlice from './myExportsList';

const myExportsReducer = combineReducers({
  filter: myExportsFilterSlice,
  list: myExportsListSlice
});

export default myExportsReducer;
