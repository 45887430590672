import { getMixPanelEnabled } from './common';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';

interface Options {
  enabled?: boolean;
}

export const useTrackPageLoad = (pageName: string, options: Options = {}) => {
  const { enabled = true } = options;
  const { '*': _, ...queryParams } = useParams();

  useEffect(() => {
    if (getMixPanelEnabled() && enabled && pageName) {
      mixpanel.track(`Page View - ${pageName}`, { ...queryParams });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabled, pageName]);
};

export const trackMixpanelEvent = (eventName: string, data?: any, options: Options = {}) => {
  const { enabled = true } = options;
  const mixPanelEnabled = getMixPanelEnabled();

  if (enabled && mixPanelEnabled) {
    mixpanel.track(eventName, { data });
  }
};
