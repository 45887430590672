import { AppThunk, RootState } from 'RootTypes';
import { CYLERALIST, getFilterKey } from 'helpers/localStorage';
import { MacStatus } from './types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { loadFromLocalStorage } from '@borda/cat-core';
import { selectSessionUserId } from 'store/slices/session';

export interface CyleraFilter {
  localStorageInitialized: boolean;
  macAddress?: string;
  statuses?: MacStatus[];
}

export const defaultCyleraFilter: CyleraFilter = {
  localStorageInitialized: false,
  macAddress: null,
  statuses: ['unassigned']
};

export const cyleraFilterSlice = createSlice({
  initialState: defaultCyleraFilter,
  name: 'cyleraFilter',
  reducers: {
    filterCleared: (draft) => {
      draft.statuses = defaultCyleraFilter.statuses;
      draft.macAddress = defaultCyleraFilter.macAddress;
    },
    localStorageInitialized: (draft, action) => {
      draft.statuses = action.payload.macStatuses;
      draft.localStorageInitialized = true;
    },
    localStorageStatusReset: (draft) => {
      draft.localStorageInitialized = false;
    },
    macAddressFilterChanged: (draft, action: PayloadAction<string>) => {
      draft.macAddress = action.payload;
    },
    macStatusFilterChanged: (draft, action: PayloadAction<MacStatus>) => {
      const status = action.payload;
      const index = draft.statuses.findIndex((i) => i === status);
      if (index !== -1) {
        draft.statuses = [];
      } else {
        draft.statuses = [status];
      }
    }
  }
});

export const loadCyleraFilterFromLocalStorage = (): AppThunk<void> => (dispatch, getState) => {
  const state = getState();
  const userId = selectSessionUserId(state);

  const cyleraFilterStorage = loadFromLocalStorage(getFilterKey(CYLERALIST), userId);
  const checkMacStatusesIsArray = Array.isArray(cyleraFilterStorage?.macStatuses);

  const macStatuses: MacStatus[] = checkMacStatusesIsArray
    ? cyleraFilterStorage?.macStatuses
    : defaultCyleraFilter.statuses;

  dispatch(localStorageInitialized({ ...cyleraFilterStorage, macStatuses }));
};

export const selectCyleraFilter = (state: RootState) => state.integrations.cylera.filter;
export const selectCyleraFilterStatuses = (state: RootState) =>
  state.integrations.cylera.filter.statuses;
export const selectCyleraFilterMacAddress = (state: RootState) =>
  state.integrations.cylera.filter.macAddress;

export const selectLocalStorageInitialized = (state: RootState) =>
  state.integrations.cylera.filter.localStorageInitialized;

export const {
  filterCleared,
  localStorageInitialized,
  localStorageStatusReset,
  macAddressFilterChanged,
  macStatusFilterChanged
} = cyleraFilterSlice.actions;
export default cyleraFilterSlice.reducer;
