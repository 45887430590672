import { Category } from './types';
import { IdResponse } from 'store/common';
import { createApiEndpoint } from 'api/utils';

export const categoryEndpoints = {
  addCategory: createApiEndpoint<Category, IdResponse, null>({
    method: 'POST',
    route: '/api/category/categories'
  }),
  getCategoriesByParentId: createApiEndpoint<void, Category[], { parentCategoryId: string }>({
    method: 'GET',
    route: `/api/category/categories?parentCategoryId=:parentCategoryId`
  }),
  updateCategory: createApiEndpoint<Category, null, { categoryId: string }>({
    method: 'PUT',
    route: `/api/category/categories/:categoryId`
  })
};
