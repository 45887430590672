import { CatIconButton, CatTooltip } from '@borda/cat-ui';
import { PreviewIcon, TrashIcon } from '@borda/cat-icons';
import { selectIsAnyChecked } from 'store/slices/notifications/list/selector';
import { useCallback } from 'react';
import { useCatTranslation } from 'utils/localization';
import {
  useDeleteNotificationsMutation,
  useMarkNotificationsAsReadMutation
} from 'views/Notifications/queries';
import { useDialogState } from '@borda/cat-core';
import { useTypedSelector } from 'hooks';
import DeleteDialog from './DeleteDialog';

type Props = {
  isFilterRead: boolean | null;
  total: number;
};

function ActionButtons(props: Props) {
  const { isFilterRead, total } = props;

  const { t } = useCatTranslation();

  const isAnyChecked = useTypedSelector((state) => selectIsAnyChecked(state, total));

  const { isPending: isMarkAsReadPending, mutateAsync: markNotificationsAsRead } =
    useMarkNotificationsAsReadMutation();

  const { isPending: isDeletePending, mutateAsync: deleteNotifications } =
    useDeleteNotificationsMutation(isFilterRead);

  const { isOpen: deleteDialogOpen, togglePopup: toggleDeletePopup } = useDialogState();

  const handleDeleteAllClose = () => {
    toggleDeletePopup(false);
  };

  const handleDeleteAllConfirm = useCallback(async () => {
    await deleteNotifications();
    toggleDeletePopup(false);
  }, [toggleDeletePopup, deleteNotifications]);

  const handleDeleteButtonClick = () => {
    toggleDeletePopup(true);
  };

  const handleMarkAsReadButtonClick = async () => {
    await markNotificationsAsRead();
  };

  const isAnyActionLoading = isMarkAsReadPending || isDeletePending;

  return (
    <>
      <div className="flex gap-2">
        <CatTooltip title={t('common.delete')}>
          <span>
            <CatIconButton
              disabled={!isAnyChecked || isAnyActionLoading}
              onClick={handleDeleteButtonClick}
            >
              <TrashIcon color="red" />
            </CatIconButton>
          </span>
        </CatTooltip>
        <CatTooltip title={t('notifications.mark_as_read')}>
          <span>
            <CatIconButton
              disabled={isFilterRead === true || !isAnyChecked || isAnyActionLoading}
              loading={isMarkAsReadPending}
              onClick={handleMarkAsReadButtonClick}
            >
              <PreviewIcon color="blue" />
            </CatIconButton>
          </span>
        </CatTooltip>
      </div>
      <DeleteDialog
        deleteLoading={isDeletePending}
        onClose={handleDeleteAllClose}
        onDelete={handleDeleteAllConfirm}
        open={deleteDialogOpen}
        total={total}
      />
    </>
  );
}

export default ActionButtons;
