import { Priority, ResponseStatuses } from 'views/Alert/types';
import AlertCard from '../AlertCard/AlertCard';
import React from 'react';
import Scrollbars from 'react-custom-scrollbars-2';

function PanelContent() {
  const alertStatus: ResponseStatuses = 'inProgress';
  const priority: Priority = 'high';

  return (
    <div className="flex grow flex-col pt-2.5">
      <Scrollbars>
        <div className="grid content-start justify-items-center px-6">
          <div className="grid w-full gap-2">
            <AlertCard
              alertStatus={alertStatus}
              alertTitle="Staff In Danger!"
              index={0}
              priority={priority}
            />
          </div>
        </div>
      </Scrollbars>
    </div>
  );
}

export default PanelContent;
