import { combineReducers } from 'redux';
import cameraListFilterSlice from './cameraListFilter';
import cameraSlice from './slice';

const cameraListReducer = combineReducers({
  filter: cameraListFilterSlice,
  list: cameraSlice
});

export default cameraListReducer;
