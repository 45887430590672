import { combineReducers } from '@reduxjs/toolkit';
import assetConfigurationReducer from './slices/assetConfiguration';
import assetsReducer from './slices/asset';
import attributesReducer from './slices/attributes';
import branchesReducer from './slices/branches';
import categoriesLegacyReducer from './slices/categories/slice';
import contractsReducer from './slices/contracts';
import dashboardReducer from './slices/dashboard';
import integrationReducer from './slices/integrations';
import locationsReducer from './slices/locations';
import notificationReducer from './slices/notifications';
import patientReducer from './slices/patient';
import plansReducer from './slices/plans';
import profileSettingsReducer from './slices/profileSettings';
import protectionCalendarReducer from './slices/protectionCalendar';
import sessionReducer from './slices/session';
import tagConfigurationReducer from './slices/tagConfiguration';
import taskConfigurationReducer from './slices/taskConfiguration';
import tasksReducer from './slices/tasks';
import userManagementReducer from './slices/userManagement';

const rootReducer = combineReducers({
  assetConfiguration: assetConfigurationReducer,
  assets: assetsReducer,
  attributes: attributesReducer,
  branches: branchesReducer,
  categoriesLegacy: categoriesLegacyReducer,
  contracts: contractsReducer,
  dashboard: dashboardReducer,
  integrations: integrationReducer,
  locations: locationsReducer,
  notifications: notificationReducer,
  patients: patientReducer,
  plans: plansReducer,
  profileSettings: profileSettingsReducer,
  protectionCalendar: protectionCalendarReducer,
  session: sessionReducer,
  tagConfiguration: tagConfigurationReducer,
  taskConfiguration: taskConfigurationReducer,
  tasks: tasksReducer,
  userManagement: userManagementReducer
});

export default rootReducer;
