import { CatTypography } from '@borda/cat-ui';
import { CloseIconButton } from 'packages/cat-wrappers';
import { ReminderIcon } from '@borda/cat-icons';
import { useCatTranslation } from 'utils/localization';
import ActionBar from './ActionBar';
import HeaderContainer from 'views/Alert/AlertPanel/Common/HeaderContainer';

type Props = {
  onClose: () => void;
  total: number;
};

function Header(props: Props) {
  const { onClose, total } = props;
  const { t } = useCatTranslation();

  return (
    <>
      <HeaderContainer className="justify-between py-4">
        <div className="flex items-center gap-2">
          <ReminderIcon color="darkGrey" contained={false} hoverable={false} />
          <CatTypography variant="h2">{t('notifications.header')}</CatTypography>
        </div>
        <CloseIconButton onClick={onClose} />
      </HeaderContainer>
      <ActionBar total={total} />
    </>
  );
}

export default Header;
