import { FileInfo, FileProgressCheckResponse } from './types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { initialState } from './data';

export const assetImportSlice = createSlice({
  initialState,
  name: 'assetImport',
  reducers: {
    assetImportCleared: (draft) => {
      draft.dialogOpen = initialState.dialogOpen;
      draft.status = undefined;
      draft.step1 = initialState.step1;
      draft.step2 = initialState.step2;
      draft.step3 = initialState.step3;
      draft.step4 = initialState.step4;
    },
    branchIdUpdated: (draft, action: PayloadAction<string>) => {
      draft.step1.selectedBranchId = action.payload;
    },
    dialogToggled: (draft, action: PayloadAction<boolean>) => {
      draft.dialogOpen = action.payload;
    },
    fileConfirmedUpdated: (draft, action: PayloadAction<boolean>) => {
      draft.step2.fileConfirmed = action.payload;
    },
    fileInfoUpdated: (draft, action: PayloadAction<FileInfo>) => {
      draft.step2.fileInfo = action.payload;
    },
    fileUploadSchemaErrorsUpdated: (draft, action: PayloadAction<string[]>) => {
      draft.step2.schemaErrors = action.payload;
    },
    importProcessCleared: (draft) => {
      draft.status = undefined;
      draft.step2.fileInfo = initialState.step2.fileInfo;
      draft.step2.percentage = initialState.step2.percentage;
      draft.step4.percentage = initialState.step4.percentage;
    },
    mainCategoryIdUpdated: (draft, action: PayloadAction<string>) => {
      draft.step1.selectedMainCategoryId = action.payload;
    },
    summaryUpdated: (draft, action: PayloadAction<FileProgressCheckResponse>) => {
      const { branchId, errorCount, file, mainCategoryId, percentage, status, successCount } =
        action.payload;

      draft.step2.fileInfo = { ...draft.step2.fileInfo, ...file };
      draft.step1.selectedBranchId = branchId;
      draft.step1.selectedMainCategoryId = mainCategoryId;
      draft.status = status;

      const step4case =
        status === 'imported' ||
        status === 'importing' ||
        status === 'importingCanceling' ||
        status === 'importedCanceled';
      if (step4case) {
        draft.step3.uploadStarted = true;
        draft.step4.percentage = percentage;
        draft.step4.errorCount = errorCount;
        draft.step4.successCount = successCount;
      } else {
        draft.step2.percentage = percentage;
        draft.step3.errorCount = errorCount;
        draft.step3.successCount = successCount;
      }
    },
    templateDownloadedUpdated: (draft, action: PayloadAction<boolean>) => {
      draft.step1.templateDownloaded = action.payload;
    },
    uploadAgainTriggered: (draft) => {
      draft.status = undefined;
      draft.step1 = initialState.step1;
      draft.step2 = initialState.step2;
      draft.step3 = initialState.step3;
      draft.step4 = initialState.step4;
    },
    uploadStarted: (draft, action: PayloadAction<boolean>) => {
      draft.step3.uploadStarted = action.payload;
    }
  }
});

export const {
  assetImportCleared,
  branchIdUpdated,
  dialogToggled,
  fileConfirmedUpdated,
  fileInfoUpdated,
  fileUploadSchemaErrorsUpdated,
  importProcessCleared,
  mainCategoryIdUpdated,
  summaryUpdated,
  templateDownloadedUpdated,
  uploadAgainTriggered,
  uploadStarted
} = assetImportSlice.actions;

export default assetImportSlice.reducer;
