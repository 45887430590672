import { TaskListFilter } from './types';

export const defaultTaskListFilter: TaskListFilter = {
  branchIds: [],
  dateFilters: [{ type: 'today' }, { type: 'tomorrow' }, { type: 'preceding' }],
  localStorageInitialized: false,
  personnelFilters: [
    {
      type: 'me'
    },
    {
      type: 'allMyTeam'
    }
  ],
  priorities: [],
  statuses: ['open', 'inProgress', 'paused'],
  taskTypes: []
};
