import { CancelButton, GoBackButton } from 'packages/cat-wrappers';
import { CatButton, CatMenuItem, CatTypography } from '@borda/cat-ui';
import { DownloadIcon, InfoIcon } from '@borda/cat-icons';
import { FormSelect } from 'components/Form';
import { MainCategory, selectAllMainCategories, selectUserLanguage } from 'store/slices/session';
import {
  assetImportCleared,
  dialogToggled,
  templateDownloadedUpdated
} from 'store/slices/asset/asset/import/slice';
import { getTemplate } from 'store/slices/asset/asset/import/actions';
import { selectBranchId, selectMainCategoryId } from 'store/slices/asset/asset/import/selectors';
import { useCatTranslation } from 'utils/localization';
import { useDialogState } from '@borda/cat-core';
import { useForm } from 'react-hook-form';
import { useLoading, useTypedDispatch, useTypedSelector } from 'hooks';
import { useSelectableBranches } from 'components/Branch/helpers';
import { yupResolver } from '@hookform/resolvers/yup';
import DiscardDialog from 'packages/cat-wrappers/Dialogs/DiscardDialog';
import React, { useEffect } from 'react';
import clsx from 'clsx';
import saveAs from 'file-saver';
import yup from 'utils/yup/yupExtended';

const schema = yup.object().shape({
  branchId: yup.string().nullable().required(),
  mainCategoryId: yup.string().nullable().required()
});

function Step1() {
  const { t } = useCatTranslation();

  const dispatch = useTypedDispatch();
  const [templateLoading, templateLoadingDispatch] = useLoading<string>();

  const { isOpen, togglePopup } = useDialogState();

  const branches = useSelectableBranches('all', 'asset');
  const mainCategories = useTypedSelector(selectAllMainCategories);

  const branchIdStore = useTypedSelector(selectBranchId);
  const mainCategoryIdStore = useTypedSelector(selectMainCategoryId);
  const userLanguage = useTypedSelector(selectUserLanguage);

  const {
    control,
    formState: { isValid },
    setValue,
    watch
  } = useForm({
    defaultValues: { branchId: branchIdStore, mainCategoryId: mainCategoryIdStore },
    mode: 'all',
    resolver: yupResolver(schema)
  });

  const branchId = watch('branchId');
  const mainCategoryId = watch('mainCategoryId');

  useEffect(() => {
    if (branches?.length === 1) {
      setValue('branchId', branches[0].id, { shouldDirty: true, shouldValidate: true });
    }
    if (mainCategories?.length === 1) {
      setValue('mainCategoryId', mainCategories[0].id, { shouldDirty: true, shouldValidate: true });
    }
  }, [branches, mainCategories, setValue]);

  const handleDownload = async () => {
    const url = await templateLoadingDispatch(getTemplate(branchId, mainCategoryId));
    await saveAs(url, `asset_template_${userLanguage}.xlsx`);
    dispatch(templateDownloadedUpdated(true));
  };

  const handleClose = async () => {
    dispatch(dialogToggled(false));
    dispatch(assetImportCleared());
  };

  const isBackButtonCancel =
    (branches.length > 1 && !!branchId) || (mainCategories.length > 1 && !!mainCategoryId);

  return (
    <div className="flex gap-4 px-6">
      <DownloadIcon className="opacity-80" />
      <div className="grid w-full">
        <div className="mb-6 grid gap-1 opacity-80">
          <CatTypography className="opacity-80" variant="body1">
            {t('assets.asset_import.step1.title')}
          </CatTypography>
          <CatTypography variant="subtitle1">
            {t('assets.asset_import.step1.subtitle')}
          </CatTypography>
        </div>
        <div className="flex w-full gap-3">
          <FormSelect
            control={control}
            disabled={branches.length === 1}
            fullWidth
            label={t('assets.asset_import.step1.branch_field')}
            name="branchId"
            required
          >
            {branches.map((b) => (
              <CatMenuItem key={b.id} value={b.id}>
                {b.name}
              </CatMenuItem>
            ))}
          </FormSelect>
          <FormSelect
            control={control}
            disabled={mainCategories.length === 1}
            fullWidth
            label={t('assets.asset_import.step1.main_category_field')}
            name="mainCategoryId"
            required
          >
            {mainCategories.map((mc: MainCategory) => (
              <CatMenuItem key={mc.id} value={mc.id}>
                {mc.name}
              </CatMenuItem>
            ))}
          </FormSelect>
        </div>
        <div
          className={clsx(
            'border-darkGrey flex gap-1 rounded-2xl border border-solid border-opacity-10 transition-all duration-300',
            !!branchId && !!mainCategoryId
              ? 'mt-6 p-4 opacity-80'
              : 'max-h-0 overflow-hidden opacity-0'
          )}
        >
          <InfoIcon fontSize="small" />
          <div className="grid gap-1">
            <CatTypography variant="body1">{t('assets.asset_import.step1.info')}</CatTypography>
            <CatTypography className="opacity-80" variant="body2">
              {t('assets.asset_import.step1.info_detail')}
            </CatTypography>
          </div>
        </div>
        <div className="mt-6 flex items-center justify-start">
          {isBackButtonCancel ? (
            <CancelButton onClick={() => togglePopup()} />
          ) : (
            <GoBackButton onClick={handleClose} />
          )}
          <div className="divider-vertical" />
          <CatButton
            color="green"
            disabled={!isValid}
            endIcon={<DownloadIcon />}
            loading={templateLoading}
            onClick={handleDownload}
            size="large"
          >
            {t('common.download')}
          </CatButton>
        </div>
      </div>
      <DiscardDialog onAction={handleClose} onClose={() => togglePopup()} open={isOpen} />
    </div>
  );
}

export default Step1;
