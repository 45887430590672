import { ActionIcon } from '@borda/cat-icons';
import { CatButton, CatButtonProps } from '@borda/cat-ui';
import { Ref, forwardRef } from 'react';
import { useCatTranslation } from 'utils/localization';

function ActionButton(props: CatButtonProps, ref: Ref<any>) {
  const { t } = useCatTranslation();
  const {
    children = t('common.action'),
    color = 'blue',
    endIcon = <ActionIcon />,
    ...rest
  } = props;
  return (
    <CatButton color={color} endIcon={endIcon} ref={ref} {...rest}>
      {children}
    </CatButton>
  );
}

export default forwardRef(ActionButton);
