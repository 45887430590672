import { CatAreaButton } from '@borda/cat-ui';
import { ConfirmButton, GoBackButton } from 'packages/cat-wrappers';
import { Control, useController } from 'react-hook-form';
import { DownloadIcon } from '@borda/cat-icons';
import {
  importProcessCleared,
  templateDownloadedUpdated
} from 'store/slices/asset/asset/import/slice';
import { selectFileInfo } from 'store/slices/asset/asset/import/selectors';
import { uploadFile } from 'store/slices/asset/asset/import/actions';
import { useCatTranslation } from 'utils/localization';
import { useLoading, useTypedDispatch, useTypedSelector } from 'hooks';
import React, { useRef } from 'react';

interface Props {
  control: Control<any, any>;
}

function BeforeUpload(props: Props) {
  const { control } = props;

  const { field } = useController({ control, name: 'excelFile' });
  const { onChange } = field;

  const { t } = useCatTranslation();
  const dispatch = useTypedDispatch();

  const [fileUploadLoading, fileUploadLoadingDispatch] = useLoading();
  const hiddenFileInputRef = useRef(null);

  const fileInfo = useTypedSelector(selectFileInfo);

  const handleClose = () => {
    dispatch(importProcessCleared());
    dispatch(templateDownloadedUpdated(false));
  };

  const handleAddFileClick = () => {
    hiddenFileInputRef.current.click();
  };

  const handleFileSelected = async (event: any) => {
    // Get first file from input
    const selectedFile = event.target?.files[0] as File;
    // Upload file and get errors if there is any
    onChange(selectedFile);
    await fileUploadLoadingDispatch(uploadFile(selectedFile));

    // eslint-disable-next-line no-param-reassign
    event.target.value = null;
  };

  return (
    <>
      <CatAreaButton
        className="h-[72px] max-w-xs"
        onClick={handleAddFileClick}
        startIcon={<DownloadIcon />}
      >
        {t('assets.asset_import.step2.button_info')}
      </CatAreaButton>
      <input
        accept=".xls, .xlsx"
        className="hidden"
        onChange={handleFileSelected}
        ref={hiddenFileInputRef}
        type="file"
      />
      <div className="mt-4 flex items-center justify-start">
        <GoBackButton onClick={handleClose} />
        <div className="divider-vertical" />
        <ConfirmButton disabled={!fileInfo} loading={fileUploadLoading} onClick={undefined} />
      </div>
    </>
  );
}

export default BeforeUpload;
