import { CatButton, CatIconButton, CatPopover, CatTypography } from '@borda/cat-ui';
import { CatTrans, useCatTranslation } from 'utils/localization';
import { CloseIcon, LighthouseIcon, MailIcon, PostIcon } from '@borda/cat-icons';
import { Divider } from '@borda/cat-mui';
import { PopupState } from 'material-ui-popup-state/hooks';
import { bindPopover } from 'material-ui-popup-state';

interface Props {
  onAction: () => void;
  onClose: () => void;
  popupState: PopupState;
}

function InformationPopover(props: Props) {
  const { onAction, onClose, popupState } = props;
  const { isOpen } = popupState;

  const { t } = useCatTranslation('translation', { keyPrefix: 'layout.teamwork_desk_info' });

  return (
    <CatPopover
      {...bindPopover(popupState)}
      anchorOrigin={{
        horizontal: 'right',
        vertical: 'top'
      }}
      onClose={() => {}}
      open={isOpen}
      transformOrigin={{
        horizontal: 'right',
        vertical: 'bottom'
      }}
      width="342px"
    >
      <div className="grid h-[500px] content-between p-4">
        <div className="grid">
          <div className="flex justify-between">
            <div className="flex items-center gap-2">
              <LighthouseIcon fontSize="medium" />
              <CatTypography variant="h2">{t('title')}</CatTypography>
            </div>
            <CatIconButton onClick={onClose}>
              <CloseIcon color="darkGrey" />
            </CatIconButton>
          </div>
          <Divider className="my-4" />
        </div>
        <div className="grid gap-2">
          <div className="grid justify-items-center text-[64px]">
            <LighthouseIcon fontSize="inherit" />
            <div className="-mt-2 flex rounded-full bg-white">
              <MailIcon
                alwaysHovered
                className="border border-solid border-white"
                color="blue"
                fontSize="small"
              />
            </div>
          </div>
          <div className="grid gap-1 text-center">
            <CatTypography variant="h2">{t('message_heading')}</CatTypography>
            <CatTypography variant="body1">
              <CatTrans i18nKey="alert_message" t={t} />
            </CatTypography>
            <CatTypography color="darkgray" variant="body2">
              <CatTrans i18nKey="info_message" t={t} />
            </CatTypography>
          </div>
        </div>
        <CatButton
          className="justify-self-center"
          color="blue"
          endIcon={<PostIcon />}
          onClick={onAction}
          size="large"
        >
          {t('action')}
        </CatButton>
      </div>
    </CatPopover>
  );
}

export default InformationPopover;
