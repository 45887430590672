import { combineReducers } from '@reduxjs/toolkit';
import detailsSlice from './details/slice';
import filterSlice from './filter/slice';
import listSlice from './list/slice';
import selectorSlice from './userSelector';

const usersReducer = combineReducers({
  details: detailsSlice,
  filter: filterSlice,
  list: listSlice,
  userSelector: selectorSlice
});

export default usersReducer;
