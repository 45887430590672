import { DialogWrapperContext } from '@borda/cat-ui';
import { TransitionProps } from '@borda/cat-mui';
import React, { useState } from 'react';

interface DialogProps {
  TransitionProps?: TransitionProps;
  open: boolean;
}

export function withDialogWrapper<T extends DialogProps>(
  WrappedComponent: React.FunctionComponent<T>
) {
  return function DialogWrapper(props: T) {
    const { open } = props;
    const [exited, setExited] = useState(true);

    if (!open && exited) {
      return null;
    }

    // eslint-disable-next-line react/jsx-no-constructed-context-values
    const contextValue = {
      onTransitionEnter: () => setExited(false),
      onTransitionExited: () => setExited(true)
    };

    return (
      <DialogWrapperContext.Provider value={contextValue}>
        <WrappedComponent
          TransitionProps={{
            onEnter: () => setExited(false),
            onExited: () => setExited(true)
          }}
          {...props}
        />
      </DialogWrapperContext.Provider>
    );
  };
}
