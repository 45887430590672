import { combineReducers } from '@reduxjs/toolkit';
import filterSlice from './filter/slice';
import listSlice from './list/list';

const myAssetsReducer = combineReducers({
  filter: filterSlice,
  list: listSlice
});

export default myAssetsReducer;
