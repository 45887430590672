import {
  ArrayParameters,
  Notification,
  NotificationListItem,
  PaginatedNotificationRequest,
  Parameters,
  StringParameters,
  UnreadNotificationResponse,
  arrayFields
} from 'store/slices/notifications/list/types';
import { AssetCardAsset } from 'store/slices/asset/asset/list/types';
import { GetAssetByAssetIdsRequest } from 'store/slices/common/types';
import { PagedResult, isArrayNullOrEmpty } from '@borda/cat-core';
import { apiUrls } from 'api';
import { clearChecked } from 'store/slices/notifications/list/slice';
import { getNextPageParam, queryClient } from 'utils/react-query';
import { notificationsKeys } from './queryKeys';
import { selectListState } from 'store/slices/notifications/list/selector';
import { selectNotificationFilter } from 'store/slices/notifications/filter/notificationFilter';
import { showDeleteSuccessSnackbar, showUpdateSuccessSnackbar } from 'utils/snackbar';
import { useCallback } from 'react';
import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';
import { useTypedDispatch, useTypedSelector } from 'hooks';
import axios from 'axios';

export type NotificationsRequest = {
  exclude: string[];
  include: string[];
  isRead?: boolean | null;
};

export const useInfiniteNotificationListQuery = () => {
  const notificationFilter = useTypedSelector(selectNotificationFilter);

  const queryInfo = useInfiniteQuery({
    getNextPageParam,
    initialPageParam: 1,
    queryFn: async ({ pageParam, signal }) => {
      const requestData: PaginatedNotificationRequest = {
        ...notificationFilter,
        page: pageParam,
        size: 20
      };

      const response = await axios.post<PagedResult<NotificationListItem>>(
        apiUrls.notification.searchNotifications(),
        requestData,
        { signal }
      );

      return response.data;
    },
    queryKey: notificationsKeys.list.byFilter(notificationFilter)
  });

  return queryInfo;
};

export const useGetUnreadNotificationCountQuery = () => {
  const queryInfo = useQuery({
    queryFn: async ({ signal }) => {
      const response = await axios.get<UnreadNotificationResponse>(
        apiUrls.notification.getUnreadNotificationCount(),
        { signal }
      );

      return response.data;
    },
    queryKey: notificationsKeys.unreadCount()
  });

  return queryInfo;
};

export const useMarkNotificationsAsReadMutation = () => {
  const dispatch = useTypedDispatch();
  const { excludedIds, includedIds } = useTypedSelector(selectListState);

  const mutation = useMutation({
    mutationFn: async () => {
      const requestData: NotificationsRequest = {
        exclude: excludedIds,
        include: includedIds
      };

      await axios.put(apiUrls.notification.markNotificationsAsRead(), requestData);
    },
    onSuccess: async () => {
      dispatch(clearChecked());
      await queryClient.invalidateQueries({ queryKey: notificationsKeys.all() });
      showUpdateSuccessSnackbar();
    }
  });

  return mutation;
};

export const useMarkNotificationAsReadByIdMutation = () => {
  const dispatch = useTypedDispatch();

  const mutation = useMutation({
    mutationFn: async (id: string) => {
      const requestData: NotificationsRequest = {
        exclude: [],
        include: [id]
      };

      await axios.put(apiUrls.notification.markNotificationsAsRead(), requestData);
    },
    onSuccess: async () => {
      dispatch(clearChecked());
      await queryClient.invalidateQueries({ queryKey: notificationsKeys.all() });
    }
  });

  return mutation;
};

export const useDeleteNotificationsMutation = (isRead: boolean | null) => {
  const dispatch = useTypedDispatch();
  const { excludedIds, includedIds } = useTypedSelector(selectListState);

  const mutation = useMutation({
    mutationFn: async () => {
      const requestData: NotificationsRequest = {
        exclude: excludedIds,
        include: includedIds,
        isRead
      };

      await axios.delete(apiUrls.notification.deleteNotifications(), { data: requestData });
    },
    onSuccess: async () => {
      dispatch(clearChecked());
      await queryClient.invalidateQueries({ queryKey: notificationsKeys.all() });
      showDeleteSuccessSnackbar();
    }
  });

  return mutation;
};

export const getNotificationDisplayCount = (total: number) => {
  if (total > 99) {
    return '99+';
  }

  return total.toString();
};

export const useGetNotificationAssetsQuery = (assetIds: string[]) => {
  const queryInfo = useQuery({
    enabled: !isArrayNullOrEmpty(assetIds),
    queryFn: async ({ signal }) => {
      const request: GetAssetByAssetIdsRequest = {
        assetIds,
        page: 1,
        size: 499
      };
      const response = await axios.post<PagedResult<AssetCardAsset>>(
        apiUrls.listing.getAssetsByAssetIds(),
        request,
        { signal }
      );

      return response.data.items;
    },
    queryKey: notificationsKeys.notificationDialog.assetsByIds(assetIds)
  });

  return queryInfo;
};

export const useGetNotificationByNotificationIdQuery = (notificationId: string) => {
  const queryInfo = useQuery({
    enabled: !!notificationId,
    queryFn: async ({ signal }) => {
      const response = await axios.get<Notification>(
        apiUrls.notification.getNotificationById(notificationId),
        { signal }
      );

      return response.data;
    },
    queryKey: notificationsKeys.notificationDialog.byId(notificationId),
    select: useCallback((apiNotification: Notification) => {
      const parameters: Parameters = {};

      apiNotification.parameters.forEach((parameter) => {
        const { key, value } = parameter;

        if (arrayFields.includes(key as keyof ArrayParameters)) {
          parameters[key] = value ? JSON.parse(value) : [];
        } else {
          parameters[key as keyof StringParameters] = value;
        }
      });

      return parameters;
    }, [])
  });

  return queryInfo;
};

export type NotificationKeys = keyof Parameters;
