import { AssetImportState } from './types';

export const initialState: AssetImportState = {
  dialogOpen: false,
  step1: {
    selectedBranchId: '',
    selectedMainCategoryId: '',
    templateDownloaded: false
  },
  step2: {
    fileConfirmed: false,
    fileInfo: null,
    percentage: 0,
    schemaErrors: []
  },
  step3: {
    errorCount: null,
    successCount: null,
    uploadStarted: false
  },
  step4: {
    errorCount: null,
    percentage: 0,
    successCount: null
  }
};
