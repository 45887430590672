import { combineReducers } from 'redux';
import accessPointFilterReducer from './filter/accessPointFilter';
import accessPointMatchReducer from './match/slice';
import accessPointReducer from './list/slice';
import locationReducer from './locations/slice';

const accessPointsReducer = combineReducers({
  filter: accessPointFilterReducer,
  list: accessPointReducer,
  locations: locationReducer,
  match: accessPointMatchReducer
});

export default accessPointsReducer;
