import { registrationEndpoints } from './registration/endpoints';
import assetImportUrls from './assetImport';
import breakdownUrls from './breakdown';
import categoryUrls from './category';
import flowUrls from './flow';
import gatewayUrls from './gateway';
import integrationUrls from './integration';
import listingUrls from './listing';
import locationUrls from './location';
import middlewareUrls from './middleware';
import notificationUrls from './notification';
import patientUrls from './patient';
import protectionUrls from './protection';
import registrationUrls from './registration';
import rtlsUrls from './rtls';
import sharedUrls from './shared';
import tagManagementUrls from './tagManagement';
import taskManagementUrls from './taskManagement';
import userUrls from './user';

export const apiUrls = {
  assetImport: assetImportUrls,
  breakdown: breakdownUrls,
  category: categoryUrls,
  flow: flowUrls,
  gateway: gatewayUrls,
  integration: integrationUrls,
  listing: listingUrls,
  location: locationUrls,
  middleware: middlewareUrls,
  notification: notificationUrls,
  patient: patientUrls,
  protection: protectionUrls,
  registration: registrationUrls,
  rtls: rtlsUrls,
  shared: sharedUrls,
  tagManagement: tagManagementUrls,
  taskManagement: taskManagementUrls,
  user: userUrls
};

export const apiEndpoints = {
  registration: registrationEndpoints
};
