import { CancelButton, ConfirmButton } from 'packages/cat-wrappers';
import { Control, useController } from 'react-hook-form';
import { cancelFileProgress } from 'store/slices/asset/asset/import/actions';
import { fileConfirmedUpdated } from 'store/slices/asset/asset/import/slice';
import { useLoading, useTypedDispatch } from 'hooks';
import React from 'react';
import UploadedFileCard from './UploadedFileCard';

interface Props {
  control: Control<any, any>;
}

function UploadSuccess(props: Props) {
  const { control } = props;

  const { field } = useController({ control, name: 'excelFile' });
  const { onChange } = field;

  const dispatch = useTypedDispatch();
  const [fileCancelLoading, fileCancelLoadingDispatch] = useLoading();

  const handleCancel = async () => {
    await fileCancelLoadingDispatch(cancelFileProgress());
    onChange(null);
  };

  const handleConfirm = () => {
    dispatch(fileConfirmedUpdated(true));
  };

  return (
    <>
      <UploadedFileCard control={control} />
      <div className="mt-4 flex items-center justify-start">
        <CancelButton loading={fileCancelLoading} onClick={handleCancel} />
        <div className="divider-vertical" />
        <ConfirmButton disabled={fileCancelLoading} onClick={handleConfirm} />
      </div>
    </>
  );
}

export default UploadSuccess;
