import { AlertInProgressIcon, AlertOpenIcon, CheckIcon } from '@borda/cat-icons';
import { AlertPanelFilter } from '../types';
import { CatToggleButton } from '@borda/cat-ui';
import { ResponseStatuses } from 'views/Alert/types';
import { useCatTranslation } from 'utils/localization';
import { useController, useFormContext } from 'react-hook-form';
import HeaderContainer from '../Common/HeaderContainer';

function QuickStatusFilters() {
  const { t: tAlert } = useCatTranslation('translation', {
    keyPrefix: 'alert.statuses'
  });

  const { control } = useFormContext<AlertPanelFilter>();

  const {
    field: { onChange: onStatusChange, value: selectedStatuses }
  } = useController({ control, name: 'statuses' });

  const toggleQuickFilter = (status: ResponseStatuses) => {
    const newStatus = [...selectedStatuses];
    const index = newStatus.findIndex((i) => i === status);

    if (index > -1) {
      newStatus.splice(index, 1);
    } else {
      newStatus.push(status);
    }

    onStatusChange(newStatus);
  };

  const isFilterSelected = (status: ResponseStatuses) => selectedStatuses.includes(status);

  return (
    <HeaderContainer className="py-2">
      <CatToggleButton
        color="red"
        icon={<AlertOpenIcon />}
        loading={false}
        onClick={() => toggleQuickFilter('open')}
        selected={isFilterSelected('open')}
        subtitle="2"
        title={tAlert('open')}
      />
      <CatToggleButton
        color="orange"
        icon={<AlertInProgressIcon />}
        loading={false}
        onClick={() => toggleQuickFilter('inProgress')}
        selected={isFilterSelected('inProgress')}
        subtitle="1"
        title={tAlert('inProgress')}
      />
      <CatToggleButton
        color="green"
        icon={<CheckIcon />}
        loading={false}
        onClick={() => toggleQuickFilter('closed')}
        selected={isFilterSelected('closed')}
        subtitle="3"
        title={tAlert('closed')}
      />
    </HeaderContainer>
  );
}

export default QuickStatusFilters;
