import { combineReducers } from 'redux';
import trackableDeviceFilterSlice from './trackableDevicesFilter';
import trackableDeviceListSlice from './trackableDevicesList';

const tagConfigurationReducer = combineReducers({
  filter: trackableDeviceFilterSlice,
  list: trackableDeviceListSlice
});

export default tagConfigurationReducer;
