import { AssetListResponse } from 'store/slices/asset/asset/list/types';
import { InfiniteData } from '@tanstack/react-query';
import { ListAttributeAssignment } from 'views/AttributeConfigurations/types';
import { isArrayNullOrEmpty } from '@borda/cat-core';
import { tCat } from 'utils/localization';
import React from 'react';

export const useListResponseConverter = () =>
  React.useCallback((data: InfiniteData<AssetListResponse>) => {
    const dataArray = data.pages.flatMap((i) => i.items);
    const items = [...new Map(dataArray.map((item) => [item.assetId, item])).values()];

    const formattedItems = items.map((item) => {
      const customFields = Object.fromEntries(
        item.assetFormFields.map((field) => [field.formFieldId, field.value])
      );

      const attributes = Object.fromEntries(
        item.attributeAssignments.map((assignment) => [
          assignment.attributeId,
          getAttributeValue(assignment)
        ])
      );

      const formattedItem = { ...item, ...customFields, ...attributes };
      delete formattedItem.assetFormFields;
      delete formattedItem.attributeAssignments;
      return formattedItem;
    });

    const selectResponse = {
      ...data,
      items: formattedItems ?? [],
      locationSignalErrors: data.pages[0].locationSignalErrors,
      statusCounts: data.pages[0].statusCounts,
      total: data.pages[0].total
    };

    return selectResponse;
  }, []);

function getAttributeValue(attribute: ListAttributeAssignment) {
  const { isAssigned, optionValues } = attribute;
  if (!isAssigned) {
    return tCat('common.not_assigned');
  }

  if (isArrayNullOrEmpty(optionValues)) {
    return '-';
  }

  return optionValues.join(', ');
}
