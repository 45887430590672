import { appVersion, useConfigQuery } from 'utils/settings';
import { browserTracingIntegration, init, setTags, setUser } from '@sentry/react';
import { selectSessionUser } from 'store/slices/session';
import { useEffect } from 'react';
import { useTypedSelector } from '../useTypedSelector';

export function useSentry() {
  const { data: config, isSuccess: isConfigSuccess } = useConfigQuery();

  useEffect(() => {
    if (!isConfigSuccess) {
      return;
    }

    const { deployment } = config;
    const { sentry: sentryConfig } = config.common;
    const { dsn, enabled, environment, tracesSampleRate } = sentryConfig;

    if (!enabled) {
      return;
    }

    init({
      dsn,
      environment,
      integrations: [browserTracingIntegration()],
      release: appVersion,
      tracesSampleRate
    });

    setTags({
      deployment,
      source: 'auto'
    });
  }, [config, isConfigSuccess]);

  const sessionUser = useTypedSelector(selectSessionUser);
  useEffect(() => {
    if (!sessionUser) {
      setUser(null);
    } else {
      setUser({ username: sessionUser.username });
    }
  }, [sessionUser]);
}
