import {
  AssetFilter,
  AssetFilterStateShape,
  AttributeFilter,
  ContractFilter,
  DefinitionPlanTypes,
  InformationFilter,
  PlanDefinitionsFilter
} from './types';

export const definitionPlanTypes: DefinitionPlanTypes[] = ['maintenance', 'calibration'];

export const emptyAttributeFilter: AttributeFilter = {
  excludedAttributes: [],
  includedAttributes: []
};
export const emptyInformationFilter: InformationFilter = {
  assetStatuses: ['usable', 'caution', 'down', 'partialDown'],
  branches: [],
  brandModels: [],
  categories: [],
  codes: [],
  createdByUsers: [],
  createdDate: { from: null, to: null, type: 'any' },
  custody: { custodies: [], haveWaitingForApprovalCustody: false, noCustody: false },
  location: { activeLocations: [], locations: [], noLocation: false },
  productionDate: { from: null, to: null, type: 'any' },
  purchasedDate: { from: null, to: null, type: 'any' },
  purchasedFirm: { noPurchasedFirm: false, purchasedFirms: [] }
};

export const emptyContractFilter: ContractFilter = {
  contactPerson: { contactPersons: [], noContactPerson: false },
  endDate: { from: null, to: null, type: 'any' },
  firm: { contractFirms: [], noContractFirm: false },
  noContractAssigned: { contractTypes: [], noContract: false },
  partPolicyTypes: [],
  types: []
};

export const emptyPlanDefinitionsFilter: PlanDefinitionsFilter = {
  doesNotRequirePlan: false,
  endDate: { from: null, to: null, type: 'any' },
  noPlanButRequired: false,
  planIds: [],
  requirePlan: false
};

export const emptyFilter: AssetFilter = {
  attribute: emptyAttributeFilter,
  contract: emptyContractFilter,
  definition: {
    calibration: emptyPlanDefinitionsFilter,
    maintenance: emptyPlanDefinitionsFilter
  },
  information: emptyInformationFilter
};

export const assetFilterInitialState: AssetFilterStateShape = {
  activeFilter: emptyFilter,
  extraFilters: []
};
