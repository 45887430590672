import { combineReducers } from '@reduxjs/toolkit';
import notificationFilterReducer from './filter/notificationFilter';
import notificationListReducer from './list/slice';

const notificationReducer = combineReducers({
  filter: notificationFilterReducer,
  list: notificationListReducer
});

export default notificationReducer;
