import { AppThunk, RootState } from 'RootTypes';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Task } from '../common/type';
import { WFCLIST, getColumnsKey, getFilterKey } from 'helpers/localStorage';
import { createListAdapter, loadFromLocalStorage } from '@borda/cat-core';
import { initialColumnDynamicData } from './data';
import { selectAllBranchIds } from 'store/slices/branches';
import { selectSessionUserId } from 'store/slices/session';

export type WFCPageRequestType = {
  branchIds: string[];
  page: number;
  size: number;
};

export type WfcFilter = {
  branchIds: string[];
  localStorageInitialized: boolean;
};

export type WFCPageStateType = {
  filter: {
    branchIds: string[];
    localStorageInitialized: boolean;
  };
};

export const defaultState: WFCPageStateType = {
  filter: {
    branchIds: [],
    localStorageInitialized: false
  }
};

const listAdapter = createListAdapter<Task>({
  initialDynamicListDefinitions: initialColumnDynamicData,
  localStorageKey: getColumnsKey(WFCLIST)
});

export const waitingForConfirmationSlice = createSlice({
  initialState: listAdapter.getInitialState(defaultState),
  name: 'waitingForConfirmation',
  reducers: {
    ...listAdapter.getReducers(),
    branchIdsUpdated: (draft, action: PayloadAction<string[]>) => {
      draft.filter.branchIds = action.payload;
    },
    filterCleared: (draft) => {
      draft.filter.branchIds = defaultState.filter.branchIds;
    },
    initFromLocalStorage: (draft, action) => {
      draft.filter.branchIds = action.payload.branchIds;
      draft.filter.localStorageInitialized = true;
    },
    resetLocalStorageStatus: (draft) => {
      draft.filter.localStorageInitialized = false;
    }
  }
});

export const loadWfcFilterFromLocalStorage = (): AppThunk<void> => (dispatch, getState) => {
  const state = getState();
  const allBranchIds = selectAllBranchIds(state);
  const userId = selectSessionUserId(state);

  const wfcFilterStorage = loadFromLocalStorage(getFilterKey(WFCLIST), userId);

  const checkBranchIdsIsArray = Array.isArray(wfcFilterStorage?.branchIds);
  let branchIds = [];
  if (checkBranchIdsIsArray) {
    branchIds = wfcFilterStorage?.branchIds.filter((branchId: string) =>
      allBranchIds.includes(branchId)
    );
  }
  dispatch(initFromLocalStorage({ ...wfcFilterStorage, branchIds }));
};

export const { selectListState } = listAdapter.getSelectors<RootState>(
  (state) => state.tasks.waitingForConfirmationList
);

export const selectWfcFilter = (state: RootState) => state.tasks.waitingForConfirmationList.filter;

export const selectWfcFilterBranchIds = (state: RootState) =>
  state.tasks.waitingForConfirmationList.filter.branchIds;

export default waitingForConfirmationSlice.reducer;

export const { branchIdsUpdated, filterCleared, initFromLocalStorage, resetLocalStorageStatus } =
  waitingForConfirmationSlice.actions;

export const WFCActions = waitingForConfirmationSlice.actions;
