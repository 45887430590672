import { CatIconButton, CatTypography } from '@borda/cat-ui';
import { CatTrans, useCatTranslation } from 'utils/localization';
import { DownloadIcon, InfoCautionIcon } from '@borda/cat-icons';
import { getValidationErrorFile } from 'store/slices/asset/asset/import/actions';
import { selectInvalidAssetCount } from 'store/slices/asset/asset/import/selectors';
import { selectUserLanguage } from 'store/slices/session';
import { useLoading, useTypedSelector } from 'hooks';
import React from 'react';
import saveAs from 'file-saver';

export default function InvalidAsset() {
  const { t } = useCatTranslation();
  const [loading, dispatch] = useLoading<string>();
  const invalidAssetCount = useTypedSelector(selectInvalidAssetCount);
  const userLanguage = useTypedSelector(selectUserLanguage);

  const handleDownloadFile = async () => {
    const url = await dispatch(getValidationErrorFile());
    await saveAs(url, `asset_template_validation_error_${userLanguage}.xlsx`);
  };

  return (
    <>
      <CatTypography className="opacity-60" variant="body1">
        <CatTrans
          count={invalidAssetCount}
          i18nKey="assets.asset_import.step3.report.error.title"
          t={t}
        />
      </CatTypography>
      <div
        className="border-red/30 mt-4 grid justify-self-start overflow-hidden rounded-2xl border border-solid"
        style={{ minWidth: '300px' }}
      >
        <div className="bg-red/10 grid items-center justify-items-center gap-1 px-4 py-2">
          <div className="flex items-center gap-2">
            <InfoCautionIcon
              className="opacity-40"
              color="red"
              contained={false}
              hoverable={false}
            />
            <CatTypography className="text-red/80 text-[15px] font-bold" variant="body1">
              {t('assets.asset_import.step3.report.error.desc')}
            </CatTypography>
          </div>
          <CatTypography className="opacity-60" variant="body1">
            <CatTrans
              count={invalidAssetCount}
              i18nKey="assets.asset_import.step3.report.error.invalid_asset"
              t={t}
            />
          </CatTypography>
        </div>
        <div
          className="bg-red/20 grid cursor-pointer grid-flow-col items-center justify-end gap-1 p-4"
          onClick={handleDownloadFile}
        >
          <CatTypography className="opacity-60" variant="body1">
            <CatTrans
              count={invalidAssetCount}
              i18nKey="assets.asset_import.step3.report.error.download_file"
              t={t}
            />
          </CatTypography>
          <CatIconButton loading={loading}>
            <DownloadIcon />
          </CatIconButton>
        </div>
      </div>
      <div className="mt-4 flex items-center gap-1">
        <InfoCautionIcon color="red" contained={false} fontSize="small" hoverable={false} />
        <CatTypography className="text-red" variant="body2">
          {t('assets.asset_import.step3.report.error.tip')}
        </CatTypography>
      </div>
    </>
  );
}
