import { combineReducers } from '@reduxjs/toolkit';
import categoriesReducer from './categories';
import categoryTaskFilterSlice from './categoryTasks/filter/slice';

const assetConfigurationReducer = combineReducers({
  categories: categoriesReducer,
  categoryTaskFilter: categoryTaskFilterSlice
});

export default assetConfigurationReducer;
