import { Location, LocationLevel } from '../../common/types';
import { PayloadAction, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { initialState, parentLocationIdOfRoot } from './data';

export const locationsAdapter = createEntityAdapter<Location, string>({
  selectId: (location) => location.id
});

export const locationsSlice = createSlice({
  initialState: locationsAdapter.getInitialState(initialState),
  name: 'accessPointLocations',
  reducers: {
    accessPointLocationsFetched: (draft, action: PayloadAction<Location[]>) => {
      const locations = action.payload;

      locations?.forEach((location) => {
        locationsAdapter.upsertOne(draft, location);

        const childrenLocationIds = draft.childrenLocationIds[location.parentLocationId];

        if (!childrenLocationIds) {
          draft.childrenLocationIds[location.parentLocationId] = [location.id];
        } else {
          const locationIndex = childrenLocationIds.findIndex((i) => i === location.id);
          if (locationIndex === -1) {
            draft.childrenLocationIds[location.parentLocationId].push(location.id);
          }
        }
      });
    },
    expandedLocationsCleared: (draft) => {
      draft.expandedLocationIds = initialState.expandedLocationIds;
    },
    expandedLocationsSet: (draft, action: PayloadAction<Location[]>) => {
      const locations = action.payload;

      const expandedLocationIds = locations.map((l) => l.parentLocationId);
      draft.expandedLocationIds = expandedLocationIds;
    },
    locationCollapsed: (draft, action: PayloadAction<Location>) => {
      const location = action.payload;
      const locationIndex =
        draft.expandedLocationIds.findIndex((i) => i === location.parentLocationId) + 1;

      draft.expandedLocationIds.splice(
        locationIndex,
        draft.expandedLocationIds.length - locationIndex
      );
    },
    locationExpanded: (draft, action: PayloadAction<Location>) => {
      const location = action.payload;

      const locationIndex =
        draft.expandedLocationIds.findIndex(
          (i) => i === (location?.parentLocationId ?? parentLocationIdOfRoot)
        ) + 1;

      draft.expandedLocationIds.splice(
        locationIndex,
        draft.expandedLocationIds.length - locationIndex,
        location?.id ?? parentLocationIdOfRoot
      );
    },
    locationLevelsInitialized: (draft, action: PayloadAction<LocationLevel[]>) => {
      const locationLevels = action.payload;
      draft.levels = locationLevels;
    },
    locationsCleared: (draft) => {
      draft.childrenLocationIds = initialState.childrenLocationIds;
      draft.expandedLocationIds = initialState.expandedLocationIds;
      draft.levels = initialState.levels;
    },
    locationsFetched: (
      draft,
      action: PayloadAction<{
        locations: Location[];
        parentLocationId: string;
      }>
    ) => {
      const { locations, parentLocationId } = action.payload;
      locationsAdapter.upsertMany(draft, locations);
      draft.childrenLocationIds[parentLocationId] = locations.map((i) => i.id);
    }
  }
});

export default locationsSlice.reducer;

export const {
  accessPointLocationsFetched,
  expandedLocationsCleared,
  expandedLocationsSet,
  locationCollapsed,
  locationExpanded,
  locationLevelsInitialized,
  locationsCleared,
  locationsFetched
} = locationsSlice.actions;
