import { Category, DisabledState } from './types';
import { CheckboxState, booleanCompare, temporaryCategoryId } from 'store/common';
import { PayloadAction, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { initialState, parentCategoryIdOfRoot } from './data';
import _ from 'lodash';

export const categoriesAdapter = createEntityAdapter<Category, string>({
  selectId: (category) => category.id,
  sortComparer: (first, second) => {
    let result = booleanCompare(
      first.id !== temporaryCategoryId,
      second.id !== temporaryCategoryId
    );

    if (result === 0) {
      result = first.name.localeCompare(second.name);
    }

    return result;
  }
});

export const categoriesSlice = createSlice({
  initialState: categoriesAdapter.getInitialState(initialState),
  name: 'categories',
  reducers: {
    addCategory: categoriesAdapter.addOne,
    addCategoryToDisplayedIds: (
      draft,
      action: PayloadAction<{
        childId: string;
        parentId: string;
      }>
    ) => {
      const { childId, parentId } = action.payload;
      if (!draft.displayedCategoryIds[parentId]) {
        draft.displayedCategoryIds[parentId] = [];
      }

      draft.displayedCategoryIds[parentId].push(childId);
    },
    addEmptyCategory: (draft, action: PayloadAction<string>) => {
      const addEmptyModel: Category = {
        code: null,
        id: temporaryCategoryId,
        name: null,
        parentCategoryId: action.payload
      };
      draft.checkedEntities = {};
      categoriesAdapter.upsertOne(draft, addEmptyModel);
    },
    addToInSessionRemovedIdsMultiple: (draft, action: PayloadAction<string[]>) => {
      const ids = action.payload;
      ids.forEach((id) => {
        draft.isInSessionRemovedById[id] = true;
        draft.lastRemovedInSessionId = id;
      });
    },
    addToInSessionsMultiple: (draft, action: PayloadAction<string[]>) => {
      action.payload.forEach((id) => {
        draft.isInSessionsById[id] = true;
      });
    },
    check: (draft, action: PayloadAction<{ id: string; state: CheckboxState }>) => {
      const { id, state } = action.payload;
      if (action.payload.state === false) {
        delete draft.checkedEntities[id];
      } else {
        draft.checkedEntities[id] = state;
      }
    },
    checkMultiple: (
      draft,
      action: PayloadAction<{
        ids: string[];
        state: CheckboxState;
      }>
    ) => {
      const { ids, state } = action.payload;
      ids.forEach((id) => {
        draft.checkedEntities[id] = state;
      });
    },
    clearCheckedCategories: (draft) => {
      draft.checkedEntities = {};
    },
    clearDisabledCategories: (draft) => {
      draft.disabledEntities = {};
    },
    clearExpandedCategories: (draft) => {
      draft.expandedCategoryIds = [parentCategoryIdOfRoot];
    },
    clearInSessionCategories: (draft) => {
      draft.isInSessionsById = {};
    },
    clearInSessionRemovedCategories: (draft) => {
      draft.isInSessionRemovedById = {};
      draft.lastRemovedInSessionId = undefined;
    },
    clearMainCategoriesDisplayed: (draft) => {
      draft.mainCategoriesDisplayed = initialState.mainCategoriesDisplayed;
    },
    clearSelectedCategory: (draft) => {
      draft.selectedCategoryId = undefined;
    },
    collapseCategory: (draft, action: PayloadAction<Category>) => {
      const index =
        draft.expandedCategoryIds.findIndex((i) => i === action.payload.parentCategoryId) + 1;

      draft.expandedCategoryIds.splice(index, draft.expandedCategoryIds.length - index);
    },
    confirmRemovalOfInSessions: (draft) => {
      const ids = Object.keys(draft.isInSessionRemovedById);
      ids.forEach((id) => {
        delete draft.isInSessionsById[id];
      });

      draft.isInSessionRemovedById = {};
      draft.lastRemovedInSessionId = undefined;
    },
    deleteCategories: (draft, action: PayloadAction<string[]>) => {
      categoriesAdapter.removeMany(draft, action.payload);
      action.payload.forEach((id) => {
        delete draft.checkedEntities[id];
        _.remove(draft.expandedCategoryIds, (expandedId) => id === expandedId);
      });
    },
    deleteTemporaryCategory: (draft) => {
      categoriesAdapter.removeOne(draft, temporaryCategoryId);
    },
    disableMultiple: (
      draft,
      action: PayloadAction<{
        ids: string[];
        state: DisabledState;
      }>
    ) => {
      const { ids, state } = action.payload;
      ids.forEach((id) => {
        draft.disabledEntities[id] = state;
      });
    },
    expandCategory: (draft, action: PayloadAction<Category>) => {
      const index =
        draft.expandedCategoryIds.findIndex(
          (i) => i === (action.payload?.parentCategoryId ?? parentCategoryIdOfRoot)
        ) + 1;

      draft.expandedCategoryIds.splice(
        index,
        draft.expandedCategoryIds.length - index,
        action.payload?.id ?? parentCategoryIdOfRoot
      );
    },
    removeCategoryIdFromDisplayedIds: (
      draft,
      action: PayloadAction<{
        childId: string;
        parentId: string;
      }>
    ) => {
      const { childId, parentId } = action.payload;
      const index = draft.displayedCategoryIds[parentId].findIndex((i) => i === childId);

      if (index !== -1) {
        draft.displayedCategoryIds[parentId].splice(index, 1);
      }
    },
    setDisplayedCategoryIds: (
      draft,
      action: PayloadAction<{
        categoryIds: string[];
        parentId: string;
      }>
    ) => {
      const { categoryIds, parentId } = action.payload;
      draft.displayedCategoryIds[parentId] = categoryIds;
    },
    setExpandedCategories: (draft, action: PayloadAction<string[]>) => {
      const categoryIds = action.payload;
      const finalCategoryIds = categoryIds.filter((id) =>
        id ? !draft.entities[id].hasBrandModel : true
      );
      draft.expandedCategoryIds = finalCategoryIds;
    },
    setMainCategoriesDisplayed: (draft, action: PayloadAction<boolean>) => {
      draft.mainCategoriesDisplayed = action.payload;
    },
    setSelectedCategory: (draft, action: PayloadAction<string>) => {
      draft.selectedCategoryId = action.payload;
    },
    updateCategories: categoriesAdapter.upsertMany,
    updateCategory: categoriesAdapter.upsertOne
  }
});

export default categoriesSlice.reducer;

export const {
  addEmptyCategory,
  addToInSessionsMultiple,
  clearCheckedCategories,
  clearDisabledCategories,
  clearExpandedCategories,
  clearInSessionCategories,
  clearMainCategoriesDisplayed,
  clearSelectedCategory,
  collapseCategory,
  confirmRemovalOfInSessions,
  deleteTemporaryCategory,
  setDisplayedCategoryIds,
  setExpandedCategories,
  setMainCategoriesDisplayed,
  updateCategories
} = categoriesSlice.actions;
