import { RootState } from 'RootTypes';
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

export interface Model {
  id?: string;
  name?: string;
  photoPath?: string;
}

const modelsAdapter = createEntityAdapter<Model, string>({ selectId: (model) => model.id });

const modelSlice = createSlice({
  initialState: modelsAdapter.getInitialState(),
  name: 'assetModels',
  reducers: {
    upsertModels: modelsAdapter.upsertMany
  }
});

export const { upsertModels } = modelSlice.actions;

export const { selectById: selectModelById } = modelsAdapter.getSelectors<RootState>(
  (state) => state.assets.assets.models
);

export default modelSlice.reducer;
