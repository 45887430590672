import {
  REDIRECT_URL_KEY,
  checkUser,
  selectSessionStatus,
  silentRefresh,
  userSignedOutFromAnotherTab
} from 'store/slices/session';
import { getUserManager } from 'utils/userManager';
import { isSafari } from 'react-device-detect';
import { useCatNavigate } from '@borda/cat-core';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTypedDispatch } from '../useTypedDispatch';
import { useTypedSelector } from '../useTypedSelector';

export const useAuthInit = (serverSettingsLoaded: boolean) => {
  const dispatch = useTypedDispatch();

  const sessionStatus = useTypedSelector(selectSessionStatus);
  const navigate = useCatNavigate();

  useEffect(() => {
    const accessTokenExpiring = async () => {
      await dispatch(silentRefresh());
    };

    const userSignedOut = () => {
      dispatch(userSignedOutFromAnotherTab());
    };

    const asyncCall = async () => {
      const userManager = await getUserManager();

      userManager.events.addAccessTokenExpiring(accessTokenExpiring);
      userManager.events.addUserSignedOut(userSignedOut);
      dispatch(checkUser());
    };

    const dismountCall = async () => {
      const userManager = await getUserManager();

      userManager.events.removeAccessTokenExpiring(accessTokenExpiring);
      userManager.events.removeUserSignedOut(userSignedOut);
    };

    if (serverSettingsLoaded) {
      asyncCall();
    }

    return () => {
      if (serverSettingsLoaded) {
        dismountCall();
      }
    };
  }, [dispatch, serverSettingsLoaded]);

  const { pathname, search } = useLocation();
  useEffect(() => {
    if (sessionStatus === 'loggedOut') {
      navigate({ pathname: '/auth/login', search: `${REDIRECT_URL_KEY}=${pathname + search}` });
    }
  }, [navigate, sessionStatus, pathname, search]);

  useEffect(() => {
    // Try slient refresh on window focus for Safari
    // https://dev.azure.com/bordasw/Quattro/_workitems/edit/13123
    if (!isSafari || sessionStatus !== 'loggedIn') {
      return () => {};
    }

    const handler = () => {
      dispatch(silentRefresh(true, true));
    };

    window.addEventListener('focus', handler);

    return () => {
      window.removeEventListener('focus', handler);
    };
  }, [dispatch, sessionStatus]);
};
