import { Export } from './types';
import { MYEXPORTSLIST, getColumnsKey } from 'helpers/localStorage/storageConstant';
import { RootState } from 'RootTypes';
import { createListAdapter } from '@borda/cat-core';
import { createSlice } from '@reduxjs/toolkit';
import { initialColumnDynamicData } from './data';

const listAdapter = createListAdapter<Export>({
  initialDynamicListDefinitions: initialColumnDynamicData,
  localStorageKey: getColumnsKey(MYEXPORTSLIST)
});

const myExportsSlice = createSlice({
  initialState: listAdapter.getInitialState({}),
  name: 'myExportsList',
  reducers: {
    ...listAdapter.getReducers()
  }
});

export const { selectListState, selectSorts: selectmyExportsListSorts } =
  listAdapter.getSelectors<RootState>((state) => state.profileSettings.myExports.list);

export const myExportsActions = myExportsSlice.actions;

export default myExportsSlice.reducer;
