import { AccessPoint } from '../../common/types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { defaultState } from './data';

export const accessPointMatchSlice = createSlice({
  initialState: defaultState,
  name: 'accessPointMatch',
  reducers: {
    accessPointChecked: (draft, action: PayloadAction<AccessPoint>) => {
      const { id } = action.payload;
      const index = draft.selectedAccessPointIds.findIndex((i) => i === id);

      if (index !== -1) {
        draft.selectedAccessPointIds.splice(index, 1);
      } else {
        draft.selectedAccessPointIds.push(id);
      }
    },
    accessPointLocationAssigned: (
      draft,
      action: PayloadAction<{
        accessPointIds: string[];
        locationId: string;
      }>
    ) => {
      const { accessPointIds, locationId } = action.payload;

      accessPointIds.forEach((apId) => {
        const accessPoint = draft.draft.accessPoints.find((ap) => ap.id === apId);
        accessPoint.locationId = locationId;
      });

      draft.selectedAccessPointIds = defaultState.selectedAccessPointIds;
    },
    accessPointLocationChanged: (
      draft,
      action: PayloadAction<{
        accessPointIds: string[];
        locationId: string;
      }>
    ) => {
      const { accessPointIds, locationId } = action.payload;

      accessPointIds.forEach((apId) => {
        const accessPoint = draft.draft.accessPoints.find((ap) => ap.id === apId);
        accessPoint.locationId = locationId;
      });
    },
    accessPointLocationUnassigned: (draft, action: PayloadAction<string[]>) => {
      const accessPointIds = action.payload;

      accessPointIds.forEach((apId) => {
        const accessPoint = draft.draft.accessPoints.find((ap) => ap.id === apId);
        accessPoint.locationId = null;
      });

      draft.selectedAccessPointIds = defaultState.selectedAccessPointIds;
    },
    allAccessPointLocationsUnassigned: (draft) => {
      draft.draft.accessPoints = draft.draft.accessPoints.map((accessPoint) => ({
        ...accessPoint,
        locationId: null
      }));

      draft.selectedAccessPointIds = defaultState.selectedAccessPointIds;
    },
    allAccessPointsChecked: (draft, action: PayloadAction<AccessPoint[]>) => {
      const accessPoints = action.payload;
      const accessPointIds = accessPoints.map((ap) => ap.id);
      draft.selectedAccessPointIds = accessPointIds;
    },
    bulkAccessPointsChecked: (draft, action: PayloadAction<AccessPoint[]>) => {
      const accessPoints = action.payload;
      const accessPointIds = accessPoints.map((ap) => ap.id);
      draft.selectedAccessPointIds = accessPointIds;
    },
    checkedAccessPointsCleared: (draft) => {
      draft.selectedAccessPointIds = defaultState.selectedAccessPointIds;
    },
    matchCleared: (draft) => {
      draft.draft = defaultState.draft;
      draft.initial = defaultState.initial;
      draft.selectedAccessPointIds = defaultState.selectedAccessPointIds;
    },
    matchInitialized: (draft, action: PayloadAction<AccessPoint[]>) => {
      const accessPoints = action.payload;
      draft.draft.accessPoints = accessPoints;
      draft.initial.accessPoints = accessPoints;
    }
  }
});

export default accessPointMatchSlice.reducer;

export const {
  accessPointChecked,
  accessPointLocationAssigned,
  accessPointLocationChanged,
  accessPointLocationUnassigned,
  allAccessPointLocationsUnassigned,
  allAccessPointsChecked,
  bulkAccessPointsChecked,
  checkedAccessPointsCleared,
  matchCleared,
  matchInitialized
} = accessPointMatchSlice.actions;
