import { Priority, ResponseStatuses } from 'views/Alert/types';
import { getAlertCardBorderHoverColor } from './helpers';
import Header from './Header';
import clsx from 'clsx';

type Props = {
  alertStatus: ResponseStatuses;
  alertTitle: string;
  index: number;
  priority: Priority;
};

function AlertCard(props: Props) {
  const { alertStatus, alertTitle, index, priority } = props;

  const borderHoverColor = getAlertCardBorderHoverColor(alertStatus);

  const handleAlertCardClick = () => {
    // TODO: Alert Card Clicked => Open Dialog
  };

  return (
    <div
      className={clsx(
        'bg-lightGrey flex flex-col gap-2 rounded-2xl border-[2px] border-transparent px-4 py-2 duration-200 hover:cursor-pointer',
        borderHoverColor
      )}
      onClick={handleAlertCardClick}
      role="button"
      tabIndex={index}
    >
      <Header alertTitle={alertTitle} priority={priority} status={alertStatus} />
      <div className="divider-horizontal" />
    </div>
  );
}

export default AlertCard;
