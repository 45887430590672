import {
  CommonFilter,
  getCommonFilterItemFromLocalStorage,
  setCommonFilterItemToLocalStorage
} from 'helpers/localStorage/management';
import { ProductKeys } from 'utils/settings';
import { isArrayNullOrEmpty } from '@borda/cat-core';
import {
  selectAllBranchesByProduct,
  selectAssetEnabledBranches,
  selectPatientEnabledBranches,
  selectRtlsEnabledBranches
} from 'store/slices/branches';
import { useCallback, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTypedSelector } from 'hooks';

export type BranchType = 'all' | 'rtls';

export const useSelectableBranches = (branchType: BranchType, productKey?: ProductKeys) => {
  const rtlsBranches = useTypedSelector(selectRtlsEnabledBranches);

  const allBranches = useGetBranchesByProductKey(productKey);

  if (branchType === 'rtls') {
    return rtlsBranches;
  }

  return allBranches;
};

export const getBranchQueryKey = (): keyof CommonFilter => 'branch';
const getLocalStorageBranchId = () => getCommonFilterItemFromLocalStorage(getBranchQueryKey());

export const useQueryBranch = (branchType: BranchType, productKey?: ProductKeys) => {
  const branches = useSelectableBranches(branchType, productKey);

  const { queryBranchId, setQueryBranchId } = useBranchQueryState();

  const selectedBranchId = useMemo(() => {
    if (isArrayNullOrEmpty(branches)) {
      return '';
    }

    if (branches.find((i) => i.id === queryBranchId)) {
      return queryBranchId;
    }

    const localStorageBranchId = getLocalStorageBranchId();
    if (branches.find((i) => i.id === localStorageBranchId)) {
      return localStorageBranchId;
    }

    return branches[0].id;
  }, [branches, queryBranchId]);

  useEffect(() => {
    if (queryBranchId !== selectedBranchId) {
      setQueryBranchId(selectedBranchId);
    }

    const localStorageBranchId = getLocalStorageBranchId();
    if (localStorageBranchId !== selectedBranchId) {
      setCommonFilterItemToLocalStorage(getBranchQueryKey(), queryBranchId);
    }
  }, [queryBranchId, selectedBranchId, setQueryBranchId]);

  return selectedBranchId;
};

export const useBranchQueryState = () => {
  const branchQueryKey = getBranchQueryKey();
  const [searchParams, setSearchParams] = useSearchParams();
  const queryBranchId = searchParams.get(branchQueryKey);

  const setQueryBranchId = useCallback(
    (branch: string) => {
      const updatedSearchParams = new URLSearchParams(searchParams.toString());
      updatedSearchParams.set(branchQueryKey, branch);
      setSearchParams(updatedSearchParams.toString());

      const queryKey = getBranchQueryKey();
      setCommonFilterItemToLocalStorage(queryKey, branch);
    },
    [branchQueryKey, searchParams, setSearchParams]
  );

  return { queryBranchId, setQueryBranchId };
};

const useGetBranchesByProductKey = (productKey: ProductKeys) => {
  const allBranches = useTypedSelector(selectAllBranchesByProduct);
  const assetEnabledBranches = useTypedSelector(selectAssetEnabledBranches);
  const patientEnabledBranches = useTypedSelector(selectPatientEnabledBranches);

  if (productKey === 'asset') {
    return assetEnabledBranches;
  }

  if (productKey === 'patient') {
    return patientEnabledBranches;
  }

  return allBranches;
};
