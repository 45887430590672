import { PayloadAction, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'RootTypes';

type AttributeOption = {
  id: string;
  isValid?: boolean;
  title: string;
};

export type Attribute = {
  id: string;
  options: AttributeOption[];
  title: string;
};

export const attributeAdapter = createEntityAdapter<Attribute>();

export const attributeSlice = createSlice({
  initialState: attributeAdapter.getInitialState(),
  name: 'attributes',
  reducers: {
    upsertAttributes: (draft, action: PayloadAction<Attribute[]>) => {
      action.payload.forEach((attribute) => {
        const existingAttr = draft.entities[attribute.id];
        if (existingAttr) {
          const existingOptionIds = new Set(existingAttr.options.map((option) => option.id));
          attribute.options.forEach((option) => {
            if (!existingOptionIds.has(option.id)) {
              existingAttr.options.push(option);
            }
          });
        } else {
          attributeAdapter.addOne(draft, attribute);
        }
      });
    }
  }
});

export const { selectById: selectAttributeById } = attributeAdapter.getSelectors<RootState>(
  (state) => state.attributes.attributeSelector
);

export const { upsertAttributes } = attributeSlice.actions;

export default attributeSlice.reducer;
