import { HttpTransportType, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { useEffect, useState } from 'react';
import { useEventCallback } from '@borda/cat-core';
import axios from 'axios';

type ConnectionOptions = {
  connectionUrl: string;
  disabled?: boolean;
  eventName?: string;
  logLevel?: LogLevel;
};

type ConnectionCallbacks<T> = {
  onConnected?: () => void;
  onDataReceived?: (data: T) => void | Promise<void>;
  onDisconnected?: () => void;
  onError?: (error: Error) => void;
};

type SignalRConnection<T> = {
  data: T;
  error: Error;
  isConnected: boolean;
};

export const useSignalR = <T>(
  options: ConnectionOptions,
  callbacks: ConnectionCallbacks<T> = {}
): SignalRConnection<T> => {
  const {
    connectionUrl,
    disabled = false,
    eventName = 'DataReceived',
    logLevel = LogLevel.None
  } = options;
  const { onConnected, onDataReceived: rawOnDataReceived, onDisconnected, onError } = callbacks;

  const onDataReceived = useEventCallback(rawOnDataReceived);

  const accessToken = axios.defaults.headers.common.Authorization as string;
  const [isConnected, setIsConnected] = useState<boolean>(false);
  const [data, setData] = useState<T>(null);
  const [error, setError] = useState<Error>(null);

  useEffect(() => {
    if (disabled) {
      return () => {};
    }

    const connection = new HubConnectionBuilder()
      .withUrl(connectionUrl, {
        accessTokenFactory: () => accessToken,
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets
      })
      .configureLogging(logLevel)
      .build();

    connection
      .start()
      .then(() => {
        setIsConnected(true);
      })
      .catch((err) => {
        setError(err);
        onError?.(err);
      });

    connection.onclose(() => {
      setIsConnected(false);
      setData(null);
      setError(null);

      onDisconnected?.();
    });

    connection.on('connected', () => {
      setIsConnected(true);
      onConnected?.();
    });

    connection.on('error', (err) => {
      setError(err);
      onError?.(err);
    });

    connection.on(eventName, async (receivedData: T) => {
      setData(receivedData);
      await onDataReceived?.(receivedData);
    });

    return () => {
      connection
        .stop()
        .then(() => {
          setIsConnected(false);
          setData(null);
          setError(null);
        })
        .catch((err) => {
          setError(err);
          onError?.(err);
        });
    };
  }, [
    accessToken,
    connectionUrl,
    disabled,
    eventName,
    logLevel,
    onConnected,
    onDataReceived,
    onDisconnected,
    onError
  ]);

  return { data, error, isConnected };
};
