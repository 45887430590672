import { useConfigQuery } from 'utils/settings';
import { useEffect, useRef, useState } from 'react';
import { useEventCallback } from '@borda/cat-core';
import { usePopupState } from 'material-ui-popup-state/hooks';
import InformationPopover from './InformationPopover';
import MailIcon, { mailIconId } from './MailIcon';

const teamworkDeskTriggerQuery = '#deskWidgetMain .toggle-trigger';

function TeamworkDeskWidget() {
  const scriptRef = useRef<HTMLScriptElement>();
  const styleRef = useRef<HTMLStyleElement>();
  const anchorRef = useRef<HTMLDivElement>();

  const { data, isSuccess } = useConfigQuery();
  const [, setPopoverInitialized] = useState(false);

  const popupState = usePopupState({ popupId: 'teamwork-desk-info-popover', variant: 'popover' });
  const { toggle } = popupState;

  const createStyle = () => {
    const css = '.widget-iframe-hold { display: none !important }';
    styleRef.current = document.createElement('style');
    styleRef.current.appendChild(document.createTextNode(css));
    document.body.appendChild(styleRef.current);
  };

  const removeStyle = () => {
    if (document.body.contains(styleRef.current)) {
      document.body.removeChild(styleRef.current);
    }
  };

  const createScript = () => {
    scriptRef.current = document.createElement('script');

    scriptRef.current.src = '/static/js/teamwork-desk-config.js';
    scriptRef.current.id = 'teamwork-desk-snippet';
    scriptRef.current.async = true;

    document.body.appendChild(scriptRef.current);
  };

  const removeScript = () => {
    if (document.body.contains(scriptRef.current)) {
      document.body.removeChild(scriptRef.current);
    }
  };

  const initTeamworkDesk = useEventCallback(() => {
    createScript();
    createStyle();

    let intervalFinished = false;
    const interval = setInterval(() => {
      const teamworkDeskTriggerElement = getTeamworkDeskTriggerElement();
      if (!teamworkDeskTriggerElement) {
        // Retry for 5 secs
        return;
      }

      const customIconClone = document.getElementById(mailIconId).cloneNode(true) as SVGElement;
      if (!customIconClone) {
        return;
      }

      customIconClone.removeAttribute('id');
      customIconClone.classList.remove('hidden');
      teamworkDeskTriggerElement.replaceChildren(customIconClone);

      teamworkDeskTriggerElement.onclick = () => {
        if (popupState.isOpen) {
          return;
        }

        setPopoverInitialized((initialized) => {
          if (!initialized) {
            toggle(anchorRef.current);
            return false;
          }

          return true;
        });
      };

      clearInterval(interval);
      intervalFinished = true;
    }, 100);

    setTimeout(() => {
      if (!intervalFinished) {
        // eslint-disable-next-line no-console
        console.log("Couldn't initialize teamwork desk widget.");
      }

      clearInterval(interval);
    }, 5000);
  });

  const cleanupTeamworkDesk = useEventCallback(() => {
    removeScript();
    removeStyle();
  });

  const enabled = data?.common?.teamworkDesk?.enabled;
  useEffect(() => {
    if (enabled && isSuccess) {
      initTeamworkDesk();
    }

    return () => {
      if (enabled) {
        cleanupTeamworkDesk();
      }
    };
  }, [cleanupTeamworkDesk, enabled, initTeamworkDesk, isSuccess]);

  const handleInformationPopoverClose = () => {
    toggle();
    getTeamworkDeskTriggerElement().click();
  };

  const handleInformationPopoverConfirm = () => {
    setPopoverInitialized(true);
    toggle();
    removeStyle();
  };

  return (
    <>
      <div className="absolute bottom-14 right-0" ref={anchorRef} />
      <InformationPopover
        onAction={handleInformationPopoverConfirm}
        onClose={handleInformationPopoverClose}
        popupState={popupState}
      />
      <MailIcon />
    </>
  );
}

const getTeamworkDeskTriggerElement = () =>
  document.querySelector<HTMLSpanElement>(teamworkDeskTriggerQuery);

export default TeamworkDeskWidget;
