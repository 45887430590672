import { CancelButton, ConfirmButton } from 'packages/cat-wrappers';
import {
  CatDialog,
  CatDialogAction,
  CatDialogContent,
  CatDialogTitle,
  CatTypography
} from '@borda/cat-ui';
import { CatTrans, useCatTranslation } from 'utils/localization';
import { TrashIcon } from '@borda/cat-icons';
import { selectCheckedItemCount } from 'store/slices/notifications/list/selector';
import { useTypedSelector } from 'hooks';
import { withDialogWrapper } from 'packages/cat-wrappers/hooks';
import KeyboardButton from 'packages/cat-wrappers/KeyboardButton';

type Props = {
  deleteLoading: boolean;
  onClose: () => void;
  onDelete: () => Promise<void>;
  open: boolean;
  total: number;
};
function DeleteDialog(props: Props) {
  const { deleteLoading, onClose, onDelete, open, total } = props;
  const { t } = useCatTranslation('translation', { keyPrefix: 'notifications.delete_dialog' });

  const selectedCount = useTypedSelector((state) => selectCheckedItemCount(state, total));

  return (
    <CatDialog open={open}>
      <CatDialogTitle iconComponent={TrashIcon} title={t('title')} />
      <CatDialogContent className="grid gap-4">
        <CatTypography variant="body1">
          <CatTrans count={selectedCount} i18nKey="desc" t={t} />
        </CatTypography>
        <CatTypography className="font-bold" variant="body1">
          {t('query')}
        </CatTypography>
      </CatDialogContent>
      <CatDialogAction>
        <KeyboardButton
          component={CancelButton}
          disabled={deleteLoading}
          keyboardKey="Escape"
          onClick={onClose}
        />
        <KeyboardButton component={ConfirmButton} keyboardKey="Enter" onClick={onDelete} />
      </CatDialogAction>
    </CatDialog>
  );
}

export default withDialogWrapper(DeleteDialog);
