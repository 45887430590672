import { CatTrans, useCatTranslation } from 'utils/localization';
import { CatTypography } from '@borda/cat-ui';
import { CheckIcon } from '@borda/cat-icons';
import { selectSuccessAssetCount } from 'store/slices/asset/asset/import/selectors';
import { useTypedSelector } from 'hooks';
import React from 'react';

export default function SuccessCard() {
  const { t } = useCatTranslation('translation', {
    keyPrefix: 'assets.asset_import.step4.result.error'
  });

  const successAssetCount = useTypedSelector(selectSuccessAssetCount);

  return (
    <div className="bg-green/10 border-green/20 mt-4 flex items-center gap-1 rounded-2xl border border-solid p-4">
      <CheckIcon color="green" contained={false} hoverable={false} />
      <CatTypography className="opacity-80" variant="subtitle1">
        <CatTrans count={successAssetCount} i18nKey="uploaded" t={t} />
      </CatTypography>
    </div>
  );
}
