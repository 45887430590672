import { CategoryTaskFilter, DateFilterOptions } from './types';

export const defaultCategoryTaskFilter: CategoryTaskFilter = {
  categoryIds: [],
  createdByUserBranches: [],
  createdByUserIds: [],
  dateFilter: null,
  showCompleted: false
};

export const dateFilterOptions: DateFilterOptions[] = [
  'thisDay',
  'thisWeek',
  'thisMonth',
  'betweenDates'
];
