import { RootState } from 'RootTypes';

export const selectExtraFilters = (state: RootState) => state.assets.assets.filter.extraFilters;

export const selectActiveFilter = (state: RootState) => state.assets.assets.filter.activeFilter;

export const selectHasUsableInExtraFilter = (state: RootState) =>
  state.assets.assets.filter.extraFilters.includes('usable');

export const selectHasCautionInExtraFilter = (state: RootState) =>
  state.assets.assets.filter.extraFilters.includes('caution');

export const selectHasPartialDownInExtraFilter = (state: RootState) =>
  state.assets.assets.filter.extraFilters.includes('partialDown');

export const selectHasDownInExtraFilter = (state: RootState) =>
  state.assets.assets.filter.extraFilters.includes('down');

export const selectHasActiveLocationFilter = (state: RootState) =>
  state.assets.assets.filter.activeFilter.information.location.activeLocations.length > 0;
