const userModalCommonUrls = {
  checkCurrentUserAuthorizedOnAllBranches: () =>
    '/api/user/currentuser/check-authorized-on-all-branches',
  getBranchUserCounts: () => '/api/user/currentuser/branches-user-count'
};

const userEditUrls = {
  updateGeneralAdminPersonalInformation: (id: string) => `/api/user/users/${id}/general-admin`,
  updateStandardUser: (id: string) => `/api/user/users/${id}/standard-user`
};

const userAddUrls = {
  addGeneralAdmin: () => '/api/user/users/add/general-admin',
  addStandardUser: () => '/api/user/users/add/standard-user',
  inviteGeneralAdmin: () => '/api/user/users/invite/general-admin',
  inviteStandardUser: () => '/api/user/users/invite/standard-user'
};

const profileSettingsUrls = {
  getCurrentUserDetails: () => '/api/user/currentuser/details'
};

const departmentUrls = {
  addDepartment: () => '/api/user/departments',
  deleteDepartment: (departmentId: string) => `/api/user/departments/${departmentId}`,
  getAllDepartments: () => '/api/user/departments',
  updateDepartment: (departmentId: string) => `/api/user/departments/${departmentId}`
};

const registerUserUrls = {
  getInvitationIsValid: (token: string) => `/api/public/user/users/invitation/${token}/is-valid`,
  getTermsAndConditions: () => '/api/public/user/terms',
  getUserDetailsByToken: (userId: string, token: string) =>
    `/api/public/user/users/${userId}/details/invitation-token/${token}`,
  updatePersonalInformationByToken: (userId: string, token: string) =>
    `/api/public/user/users/${userId}/basics/invitation-token/${token}`
};

const operationsUrls = {
  assignTag: (userId: string) => `/api/user/users/${userId}/assign-active-tag`,
  cancelInvitation: () => '/api/user/users/cancel-invitation',
  demoteUser: (userId: string) => `/api/user/users/${userId}/demote`,
  promoteUser: (userId: string) => `/api/user/users/${userId}/promote`,
  resendInvitation: () => '/api/user/users/resend',
  resignUser: () => `/api/user/users/resign`,
  unassignTag: (userId: string) => `/api/user/users/${userId}/unassign-active-tag`,
  unresignUser: () => `/api/user/users/unresign`
};

const searchUrls = {
  getUserJobTitles: () => '/api/user/users/job-titles',
  usersByAllBranches: () => '/api/user/users/search/by-all-branches',
  usersByAnyBranches: () => '/api/user/users/search/by-any-branch',
  usersByAuthorizations: () => '/api/user/users/search/by-authorizations',
  usersByIds: () => '/api/user/users/search/by-ids'
};

const actionUrls = {
  getUserActionsValidation: () => '/api/user/users/check-users-actions'
};

const patientUrls = {
  getTagAssignmentMedicalUnits: () => '/api/user/currentuser/tag-assignment-medical-units'
};

export default {
  ...departmentUrls,
  ...profileSettingsUrls,
  ...userAddUrls,
  ...userEditUrls,
  ...userModalCommonUrls,
  ...registerUserUrls,
  ...operationsUrls,
  ...searchUrls,
  ...actionUrls,
  ...patientUrls
};
