import { getAppSettings } from 'utils/settings';
import { showSnackbar } from 'utils/snackbar';
import { useEffect } from 'react';

function LiveTranslationWarning() {
  useEffect(() => {
    const { enableLiveTranslations } = getAppSettings();
    if (enableLiveTranslations) {
      showSnackbar({
        message:
          "Live translations is a development only feature. It can cause serious performance problems on both client and server. Please disabled it when you're done.",
        persist: true,
        type: 'error'
      });
    }
  }, []);

  return <></>;
}

export default LiveTranslationWarning;
