import { PROTECTIONLIST, getColumnsKey } from 'helpers/localStorage';
import { TaskItem } from '../../common/type';
import { createListAdapter } from '@borda/cat-core';
import { createSlice } from '@reduxjs/toolkit';
import { initialColumnDynamicData } from './data';

export const protectionListAdapter = createListAdapter<TaskItem>({
  initialDynamicListDefinitions: initialColumnDynamicData,
  localStorageKey: getColumnsKey(PROTECTIONLIST)
});

const initialState = protectionListAdapter.getInitialState({});

export const protectionListSlice = createSlice({
  initialState,
  name: 'protectionList',
  reducers: {
    ...protectionListAdapter.getReducers(),
    checkClear: protectionListAdapter.clearChecked
  }
});

export default protectionListSlice.reducer;

export const { checkClear } = protectionListSlice.actions;

export const protectionListActions = protectionListSlice.actions;
