import { FilterInterval } from 'utils/turboard';
import { addWeeks, formatISO, subWeeks } from 'date-fns';
import { createSlice } from '@reduxjs/toolkit';

export interface UtilizationFilter {
  endDate?: string;
  interval?: FilterInterval;
  startDate?: string;
}

export const utilizationFilterIntervals: FilterInterval[] = [
  'daily',
  'weekly',
  'monthly',
  'yearly'
];

const today = new Date();
export const defaultUtilizationFilter: UtilizationFilter = {
  endDate: formatISO(addWeeks(today, 1)),
  interval: 'monthly',
  startDate: formatISO(subWeeks(today, 1))
};

export const utilizationFilterSlice = createSlice({
  initialState: defaultUtilizationFilter,
  name: 'utilizationFilter',
  reducers: {}
});

export default utilizationFilterSlice.reducer;
