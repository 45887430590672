import { DynamicListDefinition } from '@borda/cat-core';
import { TaskItem } from '../../common/type';

export const initialColumnDynamicData: DynamicListDefinition<TaskItem> = {
  columns: [
    {
      column: 'statuses',
      visible: true,
      width: 150
    },
    {
      column: 'taskId',
      visible: true,
      width: 100
    },
    {
      column: 'viewType',
      visible: false,
      width: 100
    },
    {
      column: 'categoryName',
      visible: true,
      width: 120
    },
    {
      column: 'brandName',
      visible: true,
      width: 150
    },
    {
      column: 'modelName',
      visible: true,
      width: 100
    },
    {
      column: 'serialNo',
      visible: true,
      width: 100
    },
    {
      column: 'code',
      visible: true,
      width: 180
    },
    {
      column: 'requestedDate',
      visible: true,
      width: 150
    },
    {
      column: 'responsiblePersonnel',
      visible: true,
      width: 150
    },
    {
      column: 'substatus',
      visible: false,
      width: 150
    },
    {
      column: 'lastSeenTime',
      visible: false,
      width: 150
    },
    {
      column: 'activeTag',
      visible: false,
      width: 150
    }
  ],
  sorts: [{ column: 'requestedDate', direction: 'asc' }]
};
