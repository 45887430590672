import { getImportSummary } from 'store/slices/asset/asset/import/actions';
import {
  selectActiveStep,
  selectImportStatus,
  selectStep2ActiveStage
} from 'store/slices/asset/asset/import/selectors';
import { useTypedDispatch, useTypedSelector } from 'hooks';
import React, { useEffect, useRef } from 'react';
import Step2MinimizeView from './Step2MinimizeView';
import Step4Progress from './Step4Progress';

function MinimizedAssetImport() {
  const dispatch = useTypedDispatch();
  const status = useTypedSelector(selectImportStatus);
  const activeStep = useTypedSelector(selectActiveStep);
  const step2ActiveStage = useTypedSelector(selectStep2ActiveStage);
  const intervalRef = useRef<NodeJS.Timeout>(null);

  const preventRequest =
    status === 'validatedFail' ||
    status === 'imported' ||
    status === 'validatedSuccess' ||
    status === 'importedCanceled' ||
    !status;

  useEffect(() => {
    if (preventRequest) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
      return;
    }

    if (!intervalRef.current) {
      const progressCheckInterval = setInterval(() => {
        dispatch(getImportSummary());
      }, 1000);
      intervalRef.current = progressCheckInterval;
    }
  }, [dispatch, preventRequest]);

  // Cleanup
  useEffect(
    () => () => {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    },
    []
  );

  useEffect(() => {
    // Checking if there is any ongoing upload progress
    dispatch(getImportSummary());
  }, [dispatch]);

  const step2Condition =
    (activeStep === 'step2' &&
      step2ActiveStage !== 'fileUploadedError' &&
      step2ActiveStage !== 'beforeFileUpload') ||
    activeStep === 'step3';

  if (step2Condition) {
    return <Step2MinimizeView />;
  }

  if (activeStep === 'step4') {
    return <Step4Progress />;
  }

  return null;
}

export default MinimizedAssetImport;
