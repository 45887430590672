import { Navigate, Outlet, Route } from 'react-router-dom';
import { RouteGuard } from 'components/RouteGuards/AuthGuard';
import { SuspenseOutlet, delayedLazy } from '../helpers';

const AccountInformation = delayedLazy(
  () => import('views/ProfileSettings/AccountInformation/AccountInformation')
);

const LocalizationSettings = delayedLazy(
  () => import('views/ProfileSettings/LocalizationSettings/LocalizationSettings')
);

const DeveloperOptions = delayedLazy(
  () => import('views/ProfileSettings/DeveloperOptions/DeveloperOptions')
);

const MyExports = delayedLazy(() => import('views/ProfileSettings/MyExports/MyExports'));

const NotificationSettings = delayedLazy(
  () => import('views/ProfileSettings/NotificationSettings/NotificationSettings')
);

const VideoCapturedDetail = delayedLazy(
  () =>
    import(
      'views/ProfileSettings/AccountInformation/Notifications/VideoCaptured/VideoCapturedDetail'
    )
);

export default function renderProfileSettingsRoutes() {
  return (
    <Route element={<SuspenseOutlet />} path="profile-settings">
      <Route element={<AccountInformation />} path="account-information">
        <Route element={<Outlet />} path="notifications">
          <Route element={<VideoCapturedDetail />} path="video-captured/:eventRecordingId" />
        </Route>
      </Route>
      <Route element={<LocalizationSettings />} path="localization-settings" />
      <Route
        element={
          <RouteGuard authKey="myExports">
            <MyExports />
          </RouteGuard>
        }
        path="my-files"
      />
      <Route element={<DeveloperOptions />} path="developer-options" />
      <Route
        element={
          <RouteGuard development>
            <NotificationSettings />
          </RouteGuard>
        }
        path="notification-settings"
      />
      <Route element={<Navigate replace to="account-information" />} index />
    </Route>
  );
}
