import { CatTypography } from '@borda/cat-ui';
import { DownloadIcon } from '@borda/cat-icons';
import { selectStep2ActiveStage } from 'store/slices/asset/asset/import/selectors';
import { useCatTranslation } from 'utils/localization';
import { useForm } from 'react-hook-form';
import { useTypedSelector } from 'hooks';
import { yupResolver } from '@hookform/resolvers/yup';
import BeforeUpload from './BeforeUpload';
import Processing from './Processing';
import Summary from './Summary';
import UploadError from './UploadError';
import UploadSuccess from './UploadSuccess';
import yup from 'utils/yup/yupExtended';

const schema = yup.object().shape({
  excelFile: yup.object().shape({
    name: yup.string().nullable().required(),
    size: yup.number().nullable().required()
  })
});

function Step2() {
  const { control } = useForm({
    defaultValues: { excelFile: null },
    mode: 'all',
    resolver: yupResolver(schema)
  });

  const { t } = useCatTranslation();
  const activeStage = useTypedSelector(selectStep2ActiveStage);

  return (
    <div className="grid gap-6 px-6">
      <Summary />
      <div className="divider-horizontal" />
      <div className="flex gap-4">
        <DownloadIcon className="opacity-80" />
        <div className="grid w-full gap-2">
          <div className="grid gap-1 opacity-80">
            <CatTypography className="opacity-80" variant="body1">
              {t('assets.asset_import.step2.title')}
            </CatTypography>
            <CatTypography variant="subtitle1">
              {t('assets.asset_import.step2.subtitle')}
            </CatTypography>
          </div>
          {activeStage === 'beforeFileUpload' && <BeforeUpload control={control} />}
          {activeStage === 'fileUploadedError' && <UploadError control={control} />}
          {activeStage === 'processing' && <Processing />}
          {activeStage === 'fileUploadedSuccess' && <UploadSuccess control={control} />}
        </div>
      </div>
    </div>
  );
}

export default Step2;
