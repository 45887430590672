import { LinearProgress } from '@borda/cat-mui';

interface Props {
  className?: string;
}

function Redirecting(props: Props) {
  const { className } = props;
  return <LinearProgress className={className} />;
}

export default Redirecting;
