import { ACCESSPOINTLIST, getColumnsKey } from 'helpers/localStorage';
import { AccessPoint } from '../../common/types';
import { createListAdapter } from '@borda/cat-core';
import { createSlice } from '@reduxjs/toolkit';
import { defaultState, initialColumnDynamicData } from './data';

export const accessPointlistAdapter = createListAdapter<AccessPoint>({
  initialDynamicListDefinitions: initialColumnDynamicData,
  localStorageKey: getColumnsKey(ACCESSPOINTLIST)
});
export const initialState = accessPointlistAdapter.getInitialState(defaultState);

export const accessPointsSlice = createSlice({
  initialState,
  name: 'accessPoints',
  reducers: {
    ...accessPointlistAdapter.getReducers(),
    accessPointsPageCleared: accessPointlistAdapter.clear,
    checkClear: accessPointlistAdapter.clearChecked
  }
});

export default accessPointsSlice.reducer;

export const { accessPointsPageCleared, checkAll, checkClear, checkItem } =
  accessPointsSlice.actions;

export const accessPointsActions = accessPointsSlice.actions;
