import { Navigate, Route } from 'react-router-dom';
import { SuspenseOutlet } from 'routes/helpers';
import { lazy } from 'react';
import AuthLayout from 'layouts/Auth';
import PublicGuard from 'components/RouteGuards/PublicGuard';

const Login = lazy(() => import('views/Auth/Login'));
const Callback = lazy(() => import('views/Auth/Callback'));

export default function renderAuthRoutes() {
  return (
    <Route
      element={
        <PublicGuard>
          <AuthLayout>
            <SuspenseOutlet />
          </AuthLayout>
        </PublicGuard>
      }
      path="auth"
    >
      <Route element={<Login />} path="login" />
      <Route element={<Callback />} path="callback" />
      <Route element={<Navigate to="/errors/error-404" />} index />
    </Route>
  );
}
