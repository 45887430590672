import { AlertIcon } from '@borda/cat-icons';
import { CatTypography } from '@borda/cat-ui';
import { Priority, ResponseStatuses } from 'views/Alert/types';
import { getHeaderIconColor } from './helpers';
import AlertStatusChip from 'views/Alert/Common/AlertStatusChip';
import PriorityChip from 'views/Alert/Common/PriorityChip';
import TimeChip from 'views/Common/Time/TimeChip';

type Props = {
  alertTitle: string;
  priority: Priority;
  status: ResponseStatuses;
};

function Header(props: Props) {
  const { alertTitle, priority, status } = props;

  const iconColor = getHeaderIconColor(status);

  return (
    <div className="flex items-center justify-between gap-2">
      <div className="flex items-center gap-2">
        <AlertIcon color={iconColor} contained hoverable={false} />
        <CatTypography className="font-bold">{alertTitle}</CatTypography>
      </div>
      <div className="flex flex-none gap-0.5 self-start">
        <PriorityChip priority={priority} variant="outlined" />
        <AlertStatusChip status={status} />
        <TimeChip
          timeItem={{
            type: 'minutes',
            value: 20
          }}
          timeUnitForm="short"
        />
      </div>
    </div>
  );
}

export default Header;
