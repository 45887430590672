import { CatButton, CatButtonProps } from '@borda/cat-ui';
import { MinimizeIcon } from '@borda/cat-icons';
import { useCatTranslation } from 'utils/localization';
import React from 'react';

function MinimizeButton(props: CatButtonProps) {
  const { t } = useCatTranslation();
  const {
    children = t('common.minimize'),
    color = 'darkGrey',
    size = 'large',
    startIcon = <MinimizeIcon />,
    ...rest
  } = props;
  return (
    <CatButton color={color} size={size} startIcon={startIcon} {...rest}>
      {children}
    </CatButton>
  );
}

export default MinimizeButton;
