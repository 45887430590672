export const mailIconId = 'teamwork-desk-mail-icon';

export default function MailIcon() {
  return (
    <svg
      className="hidden"
      fill="none"
      height="40"
      id={mailIconId}
      viewBox="0 0 40 40"
      width="40"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M36.9732 30.5839C38.8914 27.5142 40 23.8866 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40C24.5254 40 28.6997 38.497 32.0511 35.963L39.7798 39.3162L37.0856 30.4836L36.9732 30.5839Z"
        fill="#F0552C"
        fillRule="evenodd"
      />
      <path
        d="M11.875 12H28.125C28.6458 12.026 29.0885 12.2083 29.4531 12.5469C29.7917 12.9115 29.974 13.3542 30 13.875C29.974 14.5 29.7266 14.9948 29.2578 15.3594L20.7422 21.7656C20.2474 22.0781 19.7526 22.0781 19.2578 21.7656L10.7422 15.3594C10.2734 14.9948 10.026 14.5 10 13.875C10.026 13.3542 10.2083 12.9115 10.5469 12.5469C10.9115 12.2083 11.3542 12.026 11.875 12ZM10 16.375L18.5156 22.7422C18.9583 23.0807 19.4531 23.25 20 23.25C20.5469 23.25 21.0417 23.0807 21.4844 22.7422L30 16.375V24.5C29.974 25.2031 29.7266 25.7891 29.2578 26.2578C28.7891 26.7266 28.2031 26.974 27.5 27H12.5C11.7969 26.974 11.2109 26.7266 10.7422 26.2578C10.2734 25.7891 10.026 25.2031 10 24.5V16.375Z"
        fill="#F3F5F6"
      />
    </svg>
  );
}
