import { BranchIcon } from '@borda/cat-icons';
import {
  CatCheckbox,
  CatMenu,
  CatMenuDivider,
  CatMenuItem,
  CatToggleButton,
  CatToggleCardCheckbox,
  CatTypography
} from '@borda/cat-ui';
import { CatTrans, useCatTranslation } from 'utils/localization';
import { bindMenu, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import { compareSortedArray } from '@borda/cat-core';
import { dequal } from 'dequal';
import { selectAssetEnabledBranchIds, selectAssetEnabledBranches } from 'store/slices/branches';
import { useCallback, useEffect, useState } from 'react';
import { useTypedSelector } from 'hooks';
import clsx from 'clsx';

type Props = {
  defaultBranchIds: string[];
  filterName?: string;
  onChange?: (branchIds: string[]) => void;
  onClose?: (isChanged: boolean) => void;
  onOpen?: () => void;
  selectedBranchIds?: any;
};

function BranchFilter(props: Props) {
  const { defaultBranchIds, filterName, onChange, onClose, onOpen, selectedBranchIds = [] } = props;

  const { t } = useCatTranslation();
  const assetBranches = useTypedSelector(selectAssetEnabledBranches);
  const assetBranchIds = useTypedSelector(selectAssetEnabledBranchIds);
  const [localBranchIds, setLocalBranchIds] = useState<string[]>(selectedBranchIds);

  const handleChange = useCallback(
    (branchIds: string[]) => {
      onChange(branchIds);
    },
    [onChange]
  );

  const handleClose = () => {
    popupState.close();

    if (onClose) {
      const isChanged = !dequal(selectedBranchIds, localBranchIds);
      onClose(isChanged);
    }

    handleChange(localBranchIds);
  };

  const handleSelect = (id: string) => {
    setLocalBranchIds((prevState) => {
      const newState: string[] = [...prevState];
      const index = newState.findIndex((i) => i === id);

      if (index === -1) {
        newState.push(id);
      } else {
        newState.splice(index, 1);
      }

      return newState;
    });
  };

  const renderFilterButtonText = (value: string[]) => {
    switch (value.length) {
      case 0:
        if (assetBranches.length === 1) {
          return assetBranches[0].name;
        }
        return filterName ?? t('branches.all_branches');
      case 1:
        return assetBranches.find((i) => i.id === value[0])?.name ?? '';
      case assetBranches.length:
        return filterName ?? t('branches.all_branches');
      default:
        return t('branches.branches_with_count', { count: value.length });
    }
  };

  const renderAllSelectButtonText = (value: string[]) => {
    switch (value.length) {
      case assetBranches.length:
        return t('branches.all_branched_selected', { count: value.length });
      default:
        return t('branches.select_all');
    }
  };

  const allBranchSelected = localBranchIds.length === assetBranchIds.length;

  const handleSelectAll = () => {
    setLocalBranchIds(allBranchSelected ? [] : assetBranchIds);
  };

  useEffect(() => {
    setLocalBranchIds(selectedBranchIds);
  }, [selectedBranchIds]);

  const popupState = usePopupState({ popupId: 'branchFilter', variant: 'popover' });

  if (assetBranchIds.length === 1) {
    return null;
  }

  const isInitialFilter = compareSortedArray(defaultBranchIds, localBranchIds);

  const handleButtonClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    popupState.open(event);
    onOpen?.();
  };

  const selected = !isInitialFilter;

  return (
    <>
      <CatToggleButton
        {...bindTrigger(popupState)}
        color="darkGrey"
        icon={
          selected ? (
            <BranchIcon alwaysHovered color="darkGrey" contained={false} />
          ) : (
            <BranchIcon />
          )
        }
        keepIconColor
        onClick={handleButtonClick}
        reverse
        selected={!isInitialFilter}
        title={renderFilterButtonText(localBranchIds)}
        truncateTitle={false}
      />
      <CatMenu {...bindMenu(popupState)} addEmptyFirstItem onClose={handleClose} width="292px">
        <CatTypography className="mx-4" variant="body1">
          <CatTrans
            i18nKey={filterName ? 'branches.branch_filter_with_name' : 'branches.branch_filter'}
            t={t}
            values={{ filterName }}
          />
        </CatTypography>
        <div className="mx-2 mt-4">
          <CatToggleButton
            className="rounded-3xl"
            color="darkGrey"
            icon={<CatToggleCardCheckbox checked={allBranchSelected} />}
            keepIconColor
            onClick={handleSelectAll}
            reverse
            selected={allBranchSelected}
            size="large"
            title={renderAllSelectButtonText(localBranchIds)}
          />
        </div>
        <CatMenuDivider />
        <div className="overflow-y-auto" style={{ maxHeight: '170px' }}>
          {assetBranches.map((branch: any) => {
            const checked = localBranchIds.includes(branch.id);
            return (
              <CatMenuItem key={branch.id} onClick={() => handleSelect(branch.id)}>
                <CatCheckbox checked={checked} id={branch.id} paddingSize="none" />
                <CatTypography className={clsx(checked ? 'font-bold' : '')} variant="body2">
                  {branch.name}
                </CatTypography>
              </CatMenuItem>
            );
          })}
        </div>
      </CatMenu>
    </>
  );
}

export default BranchFilter;
