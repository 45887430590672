import { useConfigQuery } from 'utils/settings';
import { useEffect, useRef } from 'react';
import { useEventCallback } from '@borda/cat-core';

declare global {
  interface Window {
    Frill_Config: any;
  }
}

export function useFrill() {
  const ref = useRef<HTMLScriptElement>();
  const { data, isSuccess } = useConfigQuery();

  const initFrill = useEventCallback(() => {
    const frillUrl = data.common.frill.url;
    const frillToken = data.common.frill.token;

    ref.current = document.createElement('script');

    ref.current.src = frillUrl;
    ref.current.async = true;

    document.body.appendChild(ref.current);

    window.Frill_Config = window.Frill_Config || [];
    window.Frill_Config.push({ key: frillToken });
  });

  useEffect(() => {
    const readyToInitialize = isSuccess && data.common.frill.enabled;
    if (readyToInitialize) {
      initFrill();
    }

    return () => {
      if (readyToInitialize) {
        document.body.removeChild(ref.current);
      }
    };
  }, [data, initFrill, isSuccess]);
}
