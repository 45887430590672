import { RootState } from 'RootTypes';
import { User } from '../details/types';
import { createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit';

const userSelectorAdapter = createEntityAdapter<User, string>({ selectId: (user) => user.id });

type UserSelectorState = {};

const initialState: UserSelectorState = {};

const userSelectorSlice = createSlice({
  initialState: userSelectorAdapter.getInitialState(initialState),
  name: 'userSelector',
  reducers: {
    upsertUser: userSelectorAdapter.upsertOne,
    upsertUsers: userSelectorAdapter.upsertMany
  }
});

const { selectById: selectUserById, selectEntities } = userSelectorAdapter.getSelectors<RootState>(
  (state) => state.userManagement.users.userSelector
);

export { selectUserById };

export const selectUsersByIds = createSelector(
  selectEntities,
  (state: RootState, ids: string[]) => ids,
  (entities, ids) => ids?.map((id) => entities[id]).filter((i) => !!i) ?? []
);

export const { upsertUser, upsertUsers } = userSelectorSlice.actions;

export default userSelectorSlice.reducer;
