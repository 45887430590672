import { Navigate, Outlet, Route } from 'react-router-dom';
import { PATIENT, PATIENTS, PATIENT_LIST } from 'routes/constant-route';
import { RouteGuard } from 'components/RouteGuards/AuthGuard';
import { SuspenseOutlet, delayedLazy } from '../helpers';

const PatientList = delayedLazy(() => import('views/Patient/Patients/Patients'));
const PatientDetail = delayedLazy(
  () => import('views/Patient/Patients/PatientDetail/PatientDetail')
);

export default function renderPatientRoutes() {
  return (
    <Route
      element={
        <RouteGuard products={['patient']}>
          <SuspenseOutlet />
        </RouteGuard>
      }
      path={PATIENT}
    >
      <Route element={<Outlet />} path={PATIENTS}>
        <Route
          element={
            <RouteGuard authKey="patient">
              <PatientList />
            </RouteGuard>
          }
          index
          path={PATIENT_LIST}
        />
        <Route
          element={
            <RouteGuard authKey="patientDetail">
              <PatientDetail />
            </RouteGuard>
          }
          path=":id"
        />
        <Route element={<Navigate replace to={PATIENT_LIST} />} index />
      </Route>
      <Route element={<Navigate replace to={PATIENT_LIST} />} index />
    </Route>
  );
}
