import React from 'react';

type Props = {
  children: React.ReactNode;
};

function Auth({ children }: Props) {
  return (
    <div className="flex min-h-screen">
      <div className="max-w-full flex-grow overflow-x-hidden">{children}</div>
    </div>
  );
}

export default Auth;
