import { RootState } from 'RootTypes';
import { listAdapter } from './slice';

export const {
  selectItems: selectAllAssets,
  selectListState,
  selectTotal: selectTotalAssets
} = listAdapter.getSelectors<RootState>((state) => state.assets.assets.list);

export const selectAssetListSorts = (state: RootState) => state.assets.assets.list.sorts;
export const selectAssetListColumns = (state: RootState) => state.assets.assets.list.columns;
