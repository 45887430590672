import { AppThunk, RootState } from 'RootTypes';
import { MYREQUESTLIST, getColumnsKey, getFilterKey } from 'helpers/localStorage';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { StatusGroup, Task } from '../common/type';
import { createListAdapter, loadFromLocalStorage } from '@borda/cat-core';
import { initialColumnDynamicData } from './data';
import { selectAllBranchIds } from 'store/slices/branches';
import { selectSessionUserId } from '../../session';

export type MyRequestPageRequestType = {
  branchIds: string[];
  page: number;
  size: number;
  statusFilters: StatusGroup[];
};

export type MyRequestFilter = {
  branchIds: string[];
  localStorageInitialized: boolean;
  statusFilters: StatusGroup[];
};

export type MyRequestPageStateType = {
  filter: MyRequestFilter;
};

export const statusGroups: StatusGroup[] = [
  'workInProgress',
  'waitingForApproval',
  'finished',
  'denied'
];

export const defaultMyRequestFilter: MyRequestFilter = {
  branchIds: [],
  localStorageInitialized: false,
  statusFilters: ['workInProgress', 'waitingForApproval']
};

export const defaultState: MyRequestPageStateType = {
  filter: defaultMyRequestFilter
};

const listAdapter = createListAdapter<Task>({
  initialDynamicListDefinitions: initialColumnDynamicData,
  localStorageKey: getColumnsKey(MYREQUESTLIST)
});

export const myRequestsSlice = createSlice({
  initialState: listAdapter.getInitialState(defaultState),
  name: 'myRequests',
  reducers: {
    ...listAdapter.getReducers(),
    clearFilters: (draft) => {
      draft.filter.statusFilters = defaultState.filter.statusFilters;
      draft.filter.branchIds = defaultState.filter.branchIds;
    },
    initFromLocalStorage: (draft, action) => {
      draft.filter.branchIds = action.payload.branchIds;
      draft.filter.statusFilters = action.payload.statusFilters;
      draft.filter.localStorageInitialized = true;
    },
    resetLocalStorageStatus: (draft) => {
      draft.filter.localStorageInitialized = false;
    },
    updateBranchFilters: (draft, action: PayloadAction<string[]>) => {
      draft.filter.branchIds = action.payload;
    },
    updateStatusFilters: (draft, action: PayloadAction<StatusGroup[]>) => {
      draft.filter.statusFilters = action.payload;
    }
  }
});

export const loadMyRequestFilterFromLocalStorage = (): AppThunk<void> => (dispatch, getState) => {
  const state = getState();
  const branchList = selectAllBranchIds(state);
  const userId = selectSessionUserId(state);
  const statusList = statusGroups;
  const myRequestFilterStorage = loadFromLocalStorage(getFilterKey(MYREQUESTLIST), userId);

  const checkBranchIdsIsArray = Array.isArray(myRequestFilterStorage?.branchIds);
  const checkStatusFiltersIsArray = Array.isArray(myRequestFilterStorage?.statusFilters);

  let { statusFilters } = defaultState.filter;
  let { branchIds } = defaultState.filter;

  if (checkBranchIdsIsArray) {
    branchIds = myRequestFilterStorage?.branchIds.filter((branchId: string) =>
      branchList.includes(branchId)
    );
  }

  if (checkStatusFiltersIsArray) {
    statusFilters = myRequestFilterStorage?.statusFilters.filter((status: StatusGroup) =>
      statusList.includes(status)
    );
  }

  dispatch(initFromLocalStorage({ ...myRequestFilterStorage, branchIds, statusFilters }));
};

export const selectFilter = (state: RootState) => state.tasks.myRequestsList.filter;
export const selectStatusFilters = (state: RootState) =>
  state.tasks.myRequestsList.filter.statusFilters;
export const selectBranchFilters = (state: RootState) =>
  state.tasks.myRequestsList.filter.branchIds;
export const { selectListState } = listAdapter.getSelectors<RootState>(
  (state) => state.tasks.myRequestsList
);

export default myRequestsSlice.reducer;
export const {
  clearFilters,
  initFromLocalStorage,
  resetLocalStorageStatus,
  updateBranchFilters,
  updateStatusFilters
} = myRequestsSlice.actions;

export const myRequestsActions = myRequestsSlice.actions;
