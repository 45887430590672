import { Button, useMediaQuery, useTheme } from '@borda/cat-mui';
import { CatTypography } from '@borda/cat-ui';
import { useTrackPageLoad } from 'utils/mixpanel';
import Page from 'packages/cat-wrappers/Page';

function Error500() {
  useTrackPageLoad('500');

  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down('md'));

  const handleReloadClick = () => {
    window.location.reload();
  };

  return (
    <Page title="New Version Available">
      <div className="flex flex-col items-center p-6 pt-[10vh]">
        <CatTypography align="center" variant={mobileDevice ? 'h4' : 'h1'}>
          New Version Available!
        </CatTypography>
        <CatTypography align="center" variant="subtitle2">
          This application has been updated, please refresh your browser to see the latest content.
        </CatTypography>
        <div className="mt-12 flex justify-center">
          <img
            alt="Under development"
            className="h-auto max-h-[300px] w-[560px] max-w-full"
            src="/static/images/undraw_unboxing_pbmf.svg"
          />
        </div>
        <div className="mt-12 flex justify-center">
          <Button color="primary" onClick={handleReloadClick} size="large" variant="outlined">
            Refresh browser
          </Button>
        </div>
      </div>
    </Page>
  );
}

export default Error500;
