import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { PersonFilter, StatusKeys } from '../../common/type';
import { RootState } from 'RootTypes';

export interface ProtectionListFilter {
  personnelFilters: PersonFilter[];
  statuses: StatusKeys[];
}

export const defaultProtectionListFilter: ProtectionListFilter = {
  personnelFilters: [
    {
      type: 'me'
    },
    {
      type: 'allMyTeam'
    }
  ],
  statuses: ['open', 'inProgress', 'paused']
};

const protectionListFilterSlice = createSlice({
  initialState: defaultProtectionListFilter,
  name: 'protectionListFilter',
  reducers: {
    filtersCleared: (draft) => {
      draft.personnelFilters = defaultProtectionListFilter.personnelFilters;
      draft.statuses = defaultProtectionListFilter.statuses;
    },
    personnelUpdated: (draft, action: PayloadAction<PersonFilter[]>) => {
      draft.personnelFilters = action.payload;
    },
    protectionListFilterUpdated: (draft, action: PayloadAction<ProtectionListFilter>) => {
      const { personnelFilters: personnel, statuses } = action.payload;
      draft.personnelFilters = personnel;
      draft.statuses = statuses;
    },
    statusesUpdated: (draft, action: PayloadAction<StatusKeys[]>) => {
      draft.statuses = action.payload;
    }
  }
});

export const selectProtectionListFilters = (state: RootState) =>
  state.tasks.protections.protectionListFilter;
export const selectStatuses = (state: RootState) =>
  state.tasks.protections.protectionListFilter.statuses;
export const selectPersonnel = (state: RootState) =>
  state.tasks.protections.protectionListFilter.personnelFilters;

export default protectionListFilterSlice.reducer;

export const { filtersCleared, personnelUpdated, protectionListFilterUpdated, statusesUpdated } =
  protectionListFilterSlice.actions;
