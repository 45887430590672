const assetImport = {
  assetUploadFile: (branchId: string, mainCategoryId: string) =>
    `/api/importer/assets/upload/branch/${branchId}/maincategory/${mainCategoryId}`,
  cancelFileProgress: () => '/api/importer/assets/current-user/cancel',
  completeImportProcess: () => '/api/importer/assets/current-user/complete',
  getAssetImportTemplate: (branchId: string, mainCategoryId: string) =>
    `/api/importer/assets/download/template/branch/${branchId}/maincategory/${mainCategoryId}`,
  getImportSummary: () => '/api/importer/assets/current-user/summary',
  getImportedErrorFile: () => '/api/importer/assets/current-user/download/imported-errors',
  getUploadedFile: () => '/api/importer/assets/current-user/download/original',
  getValidationErrorFile: () => '/api/importer/assets/current-user/download/validation-errors',
  startImportAssets: () => '/api/importer/assets/current-user/import'
};

export default {
  ...assetImport
};
