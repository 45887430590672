import { AlertPanelFilter } from '../../types';
import { CatIconButton } from '@borda/cat-ui';
import { FilterCancelIcon } from '@borda/cat-icons';
import { clearableFieldsKeys, defaultAlertPanelFilter } from '../../data';
import { useFindObjectChangesCount } from '@borda/cat-core';
import { useFormContext } from 'react-hook-form';

function FilterClear() {
  const { setValue, watch } = useFormContext<AlertPanelFilter>();
  const selectedAlertPanelFilters = watch();

  const handleClearFilter = () => {
    clearableFieldsKeys.forEach((field) => {
      setValue(field, defaultAlertPanelFilter[field], {
        shouldDirty: true,
        shouldTouch: true,
        shouldValidate: true
      });
    });
  };

  const clearableFilterChangesCount = useFindObjectChangesCount(
    getClearableFilterFields(selectedAlertPanelFilters),
    getClearableFilterFields(defaultAlertPanelFilter)
  );

  const isAnyClearableFilterSelected = clearableFilterChangesCount > 0;

  return (
    <div className="flex items-center">
      <CatIconButton disabled={!isAnyClearableFilterSelected} onClick={handleClearFilter}>
        <FilterCancelIcon color="red" />
      </CatIconButton>
    </div>
  );
}

export default FilterClear;

export const getClearableFilterFields = (filter: AlertPanelFilter) =>
  Object.fromEntries(clearableFieldsKeys.map((key) => [key, filter[key]])) as Pick<
    AlertPanelFilter,
    keyof AlertPanelFilter
  >;
