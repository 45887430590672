const accessPointUrls = {
  addAccessPoint: () => `/api/integration/rtls/access-points`,
  deleteAccessPoints: () => `/api/integration/rtls/access-points`,
  getAccessPointStatusCounts: () => `/api/integration/rtls/access-points/status-counts`,
  updateAccessPoint: (id: string) => `/api/integration/rtls/access-points/${id}`,
  updateAccessPointLocations: () => `/api/integration/rtls/access-points/locations`
};

const accessPointImportUrls = {
  downloadAccessPointErrors: (fileId: string) =>
    `/api/integration/rtls/access-points/download/temporary/${fileId}`,
  downloadAccessPointTemplate: () => `/api/integration/rtls/access-points/download/template`,
  uploadAccessPointTemplate: (branchId: string) =>
    `/api/integration/rtls/access-points/branch/${branchId}/upload`
};

const cyleraUrls = {
  getCyleraDevices: () => '/api/integration/cylera/devices',
  getCyleraStatusCounts: () => '/api/integration/cylera/devices/status-counts',
  updateCyleraDeviceStatus: (macAddress: string) =>
    `/api/integration/cylera/devices/${macAddress}/status`
};

const apiKeyUrls = {
  addApiKey: () => '/api/integration/api-keys',
  getCurrentUserApiKeys: () => '/api/integration/api-keys/current-user',
  revokeApiKey: (id: string) => `/api/integration/api-keys/${id}/revoke`,
  updateApiKey: (id: string) => `/api/integration/api-keys/${id}`
};

export default { ...accessPointUrls, ...accessPointImportUrls, ...cyleraUrls, ...apiKeyUrls };
