import { Helmet } from 'react-helmet';
import { useCatTranslation } from 'utils/localization';
import { useLocation } from 'react-router-dom';
import React, { useEffect } from 'react';

const { NODE_ENV, REACT_APP_GA_MEASUREMENT_ID: GA_MEASUREMENT_ID } = import.meta.env;

type Props = {
  children: React.ReactNode;
  title: string;
};

function Page(props: Props) {
  const location = useLocation();
  const { children, title } = props;

  useEffect(() => {
    if (NODE_ENV !== 'production') {
      return;
    }

    if ((window as any).gtag) {
      (window as any).gtag('config', GA_MEASUREMENT_ID, {
        page_name: title,
        page_path: location.pathname
      });
    }

    // eslint-disable-next-line
  }, []);

  const { i18n } = useCatTranslation();

  return (
    <>
      <Helmet htmlAttributes={{ lang: i18n.language }}>
        <title>{title}</title>
      </Helmet>
      {children}
    </>
  );
}

export default Page;
