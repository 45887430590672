import { RootState } from 'RootTypes';
import { createSelector } from '@reduxjs/toolkit';
import { isArrayNullOrEmpty } from '@borda/cat-core';
import { listAdapter } from './slice';

export const { selectListState, selectSize: selectPageSize } = listAdapter.getSelectors<RootState>(
  (state) => state.notifications.list
);

export const selectIsNotificationChecked = createSelector(
  selectListState,
  (state: RootState, notificationId: string) => notificationId,
  (listState, notificationId) => {
    const { allChecked, excludedIds, includedIds } = listState;

    if (allChecked) {
      if (isArrayNullOrEmpty(excludedIds)) {
        return true;
      }
      return !excludedIds.includes(notificationId);
    }
    if (isArrayNullOrEmpty(includedIds)) {
      return false;
    }
    return includedIds.includes(notificationId);
  }
);

export const selectIsAnyChecked = createSelector(
  selectListState,
  (state: RootState, total: number) => total,
  (listState, total) => {
    const { allChecked, excludedIds, includedIds } = listState;

    if (!isArrayNullOrEmpty(includedIds) && !allChecked) {
      return true;
    }

    if (allChecked && excludedIds.length < total) {
      return true;
    }

    return false;
  }
);

export const selectIsCheckBoxIndeterminate = createSelector(
  selectListState,
  (state: RootState, total: number) => total,
  (listState, total) => {
    const { allChecked, excludedIds, includedIds } = listState;

    if (!isArrayNullOrEmpty(includedIds) && !allChecked) {
      if (includedIds.length === total) {
        return false;
      }
      return true;
    }

    if (allChecked && excludedIds.length < total) {
      if (excludedIds.length === 0) {
        return false;
      }
      return true;
    }

    return false;
  }
);

export const selectCheckedItemCount = createSelector(
  selectListState,
  (state: RootState, total: number) => total,
  (listState, total) => {
    const { allChecked, excludedIds, includedIds } = listState;

    if (allChecked) {
      if (excludedIds.length === 0) {
        return total;
      }
      return total - excludedIds.length;
    }

    return includedIds.length;
  }
);
