import { AccessPoint } from 'store/slices/locations/common/types';
import { AccessPointStateShape } from './types';
import { DynamicListDefinition } from '@borda/cat-core';

export const defaultState: AccessPointStateShape = {};

export const initialColumnDynamicData: DynamicListDefinition<AccessPoint> = {
  columns: [
    {
      column: 'macAddress',
      visible: true,
      width: 150
    },
    {
      column: 'name',
      visible: true,
      width: 150
    },
    {
      column: 'locationId',
      visible: true,
      width: 250
    },
    {
      column: 'lastSeen',
      visible: true,
      width: 150
    },
    {
      column: 'branchId',
      visible: false,
      width: 150
    },
    {
      column: 'lastSeenDevices',
      visible: true,
      width: 200
    }
  ],
  sorts: []
};
