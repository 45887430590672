const rtlsMapConfigurationUrls = {
  getRtlsMapConfiguration: (branchId: string) =>
    `/api/rtls/rtls-map-configuration/branch/${branchId}`,
  updateRtlsMapConfiguration: () => `/api/rtls/rtls-map-configuration`
};

const geofenceMatchingUrls = {
  matchGeofences: () => '/api/rtls/geofence-matching/match',
  searchGeofenceMatchingsByGeofenceIds: () => '/api/rtls/geofence-matching/search/by-geofence-ids',
  unmatchGeofences: () => '/api/rtls/geofence-matching/unmatch'
};

const locationHistoryUrls = {
  getLocationHistory: () => '/api/rtls/location-history/search'
};

const realtimeLocationUrls = {
  searchRealtimeLocation: () => '/api/integration/rtls/real-time-location/search'
};

const socketUrls = {
  connectTagLocationUpdateSocket: (macAddress: string) =>
    `/socket/rtls/hubs/tag-location?macAddress=${macAddress}`
};

export default {
  ...rtlsMapConfigurationUrls,
  ...geofenceMatchingUrls,
  ...locationHistoryUrls,
  ...socketUrls,
  ...realtimeLocationUrls
};
