const subscriptionUrls = {
  subscribe: () => '/api/notification/users/web/subscribe',
  unsubscribe: () => '/api/notification/users/web/unsubscribe'
};

const managementUrls = {
  deleteNotifications: () => '/api/notification/push-notifications',
  getUnreadNotificationCount: () => '/api/notification/push-notifications/unread/count',
  markNotificationsAsRead: () => '/api/notification/push-notifications/mark-as-read',
  searchNotifications: () => '/api/notification/push-notifications/search'
};

const utilityUrls = {
  getNotificationById: (notificationId: string) =>
    `/api/notification/notifications/${notificationId}`
};

const socketUrls = {
  connectNotificationSocket: () => '/socket/notification/hubs/notification'
};

const preferencesUrls = {
  getSystemNotificationPreferences: () => '/api/notification/preferences/system',
  getUserNotificationPreferences: () => '/api/notification/preferences/current-user',
  updateSystemNotificationPreferences: () => '/api/notification/preferences/system',
  updateUserAllPreferences: () => '/api/notification/preferences/current-user',
  updateUserNotificationPreferences: () => `/api/notification/preferences/current-user`
};

export default {
  ...subscriptionUrls,
  ...managementUrls,
  ...utilityUrls,
  ...socketUrls,
  ...preferencesUrls
};
