import { DynamicListDefinition } from '@borda/cat-core';
import { PlanItem, PlanListFilter, PlanListState } from './types';

export const defaultPlanListFilter: PlanListFilter = {
  branchId: null,
  planName: null,
  statuses: ['active']
};

export const defaultPlanListState: PlanListState = {
  filter: defaultPlanListFilter
};

export const initialColumnDynamicData: DynamicListDefinition<PlanItem> = {
  columns: [
    {
      column: 'status',
      visible: true,
      width: 50
    },
    {
      column: 'title',
      visible: true,
      width: 150
    },
    {
      column: 'type',
      visible: true,
      width: 150
    },
    {
      column: 'periodValue',
      visible: true,
      width: 150
    },
    {
      column: 'startDate',
      visible: true,
      width: 150
    },
    {
      column: 'endDate',
      visible: true,
      width: 150
    },
    {
      column: 'upcomingScheduledStatus',
      visible: true,
      width: 150
    },
    {
      column: 'upcomingDate',
      visible: true,
      width: 150
    },
    {
      column: 'isAssociatedAsset',
      visible: true,
      width: 150
    },
    {
      column: 'mainCategoryId',
      visible: true,
      width: 150
    },
    {
      column: 'branchIds',
      visible: true,
      width: 75
    }
  ],
  sorts: [{ column: 'endDate', direction: 'desc' }]
};
