import { Navigate, Route } from 'react-router-dom';
import { RouteGuard } from 'components/RouteGuards/AuthGuard';
import { SuspenseOutlet, delayedLazy } from '../helpers';
import { getConfigs } from 'utils/settings';
import { isObjectNullOrEmpty } from '@borda/cat-core';

const AssetDashboard = delayedLazy(() => import('views/Dashboard/Realtime/RealtimeDashboard'));
const UtilizationDashboard = delayedLazy(
  () => import('views/Dashboard/Utilization/UtilizationDashboard')
);
export default function renderAssetDashboardRoutes() {
  const configs = getConfigs();

  return (
    <Route
      element={
        <RouteGuard authKey="assetDashboard" products={['asset']}>
          <SuspenseOutlet />
        </RouteGuard>
      }
      path="asset-dashboard"
    >
      <Route element={<AssetDashboard />} path="realtime" />
      <Route
        element={
          <RouteGuard hidden={isObjectNullOrEmpty(configs?.reporting)}>
            <UtilizationDashboard />
          </RouteGuard>
        }
        path="utilization"
      />
      <Route element={<Navigate replace to="realtime" />} index />
    </Route>
  );
}
