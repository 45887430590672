import { IdentificationFieldInfo, SearchResultType } from 'store/slices/search/types';
import { apiUrls } from 'api';
import { assetSearchKeys } from './queryKeys';
import { trackMixpanelEvent } from 'utils/mixpanel';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

export interface SearchAssetsRequest {
  includePassiveAssets: boolean;
  searchText: string;
}

export const useSearchAssetsQuery = (searchText: string, includePassiveAssets: boolean) => {
  const queryInfo = useQuery({
    enabled: !!searchText,
    queryFn: async ({ signal }) => {
      const request: SearchAssetsRequest = { includePassiveAssets, searchText };
      const response = await axios.post<SearchResultType[]>(
        apiUrls.registration.getAssetSearchResults(),
        request,
        { signal }
      );

      const matchedFields = response.data.map((i) => i.matchedFieldTitle);
      trackMixpanelEvent('Asset_Searched', { matchedFields, searchText });

      return response.data;
    },
    queryKey: assetSearchKeys.modal.byFilter(searchText, includePassiveAssets),
    refetchOnWindowFocus: false
  });

  return queryInfo;
};

export const useGetSearchIdentificationFields = () => {
  const queryInfo = useQuery({
    enabled: false,
    queryFn: async ({ signal }) => {
      const response = await axios.get<IdentificationFieldInfo[]>(
        apiUrls.registration.getFormBuilderIdentificationFields(),
        { signal }
      );

      return response.data;
    },
    queryKey: assetSearchKeys.modal.identificationFields()
  });

  return queryInfo;
};
