import { Branch } from 'store/slices/branches';
import { Navigate, Route } from 'react-router-dom';
import { RouteGuard } from 'components/RouteGuards/AuthGuard';
import { SuspenseOutlet, delayedLazy } from '../helpers';
import { isArrayNullOrEmpty } from '@borda/cat-core';

const RealtimeTrackingPage = delayedLazy(
  () => import('views/RealtimeTracking/RealtimeMap/RealtimeTrackingPage')
);

export default function renderRealtimeTrackingRoutes(rtlsEnabledBranches: Branch[]) {
  return (
    <Route
      element={
        <RouteGuard hidden={isArrayNullOrEmpty(rtlsEnabledBranches)}>
          <SuspenseOutlet />
        </RouteGuard>
      }
      path="realtime-tracking"
    >
      <Route element={<RealtimeTrackingPage />} path="realtime-map" />
      <Route element={<Navigate to="realtime-map" />} index />
    </Route>
  );
}
