import { CancelButton, ConfirmButton } from 'packages/cat-wrappers';
import { CatTrans, useCatTranslation } from 'utils/localization';
import { CatTypography } from '@borda/cat-ui';
import { Control, useController } from 'react-hook-form';
import { FileIcon, InfoCautionIcon } from '@borda/cat-icons';
import {
  fileInfoUpdated,
  fileUploadSchemaErrorsUpdated
} from 'store/slices/asset/asset/import/slice';
import { selectSchemaErrors } from 'store/slices/asset/asset/import/selectors';
import { useTypedDispatch, useTypedSelector } from 'hooks';
import { useUniqueIds } from '@borda/cat-core';
import React from 'react';
import UploadedFileCard from './UploadedFileCard';

interface Props {
  control: Control<any, any>;
}

function UploadError(props: Props) {
  const { control } = props;
  const { field } = useController({ control, name: 'excelFile' });
  const { onChange } = field;

  const dispatch = useTypedDispatch();
  const { t } = useCatTranslation();

  const schemeErrors = useTypedSelector(selectSchemaErrors);

  const uniqueIds = useUniqueIds(schemeErrors?.length);

  const handleCancel = async () => {
    dispatch(fileUploadSchemaErrorsUpdated([]));
    dispatch(fileInfoUpdated(null));
    onChange(null);
  };

  return (
    <>
      <UploadedFileCard control={control} />
      <div className="bg-darkGrey/10 flex h-4 w-fit items-center gap-2 rounded-lg py-0.5 pr-4">
        <FileIcon color="darkGrey" contained={false} fontSize="small" hoverable={false} />
        <CatTypography className="text-darkGrey" variant="body2">
          <CatTrans i18nKey="assets.asset_import.step2.error_desc" t={t} />
        </CatTypography>
      </div>
      <ul className="grid gap-2">
        {schemeErrors.map((e, index) => (
          <div
            className="bg-red/10 flex h-4 w-fit items-center gap-2 rounded-lg py-0.5 pr-4"
            key={uniqueIds[index]}
          >
            <InfoCautionIcon color="red" contained={false} fontSize="small" hoverable={false} />
            <CatTypography className="text-red" variant="body2">
              {e}
            </CatTypography>
          </div>
        ))}
      </ul>
      <div className="mt-4 flex items-center justify-start">
        <CancelButton onClick={handleCancel} />
        <div className="divider-vertical" />
        <ConfirmButton disabled onClick={undefined} />
      </div>
    </>
  );
}

export default UploadError;
