import { AlertPanelFilter } from 'views/Alert/AlertPanel/types';
import {
  CatCheckbox,
  CatMenu,
  CatMenuDivider,
  CatMenuItem,
  CatToggleButton,
  CatTypography
} from '@borda/cat-ui';
import { CatTrans, useCatTranslation } from 'utils/localization';
import { ExclamationIcon } from '@borda/cat-icons';
import { Priority, priorities } from 'views/Alert/types';
import { bindMenu, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import { trackMixpanelEvent } from 'utils/mixpanel';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import clsx from 'clsx';

function PriorityFilter() {
  const { t: tAlertPanel } = useCatTranslation('translation', {
    keyPrefix: 'alert_panel.header'
  });

  const { t: tAlert } = useCatTranslation('translation', {
    keyPrefix: 'alert'
  });

  const { setValue, watch } = useFormContext<AlertPanelFilter>();
  const { priorities: selectedPriorities } = watch();

  const [localFilteredPriorities, setLocalFilteredPriorities] =
    useState<Priority[]>(selectedPriorities);

  useEffect(() => {
    setLocalFilteredPriorities(selectedPriorities);
  }, [selectedPriorities]);

  const handlePriorityClick = (priority: Priority) => {
    setLocalFilteredPriorities((prevState: Priority[]) => {
      const newState: Priority[] = [...prevState];
      const index = newState.findIndex((i) => i === priority);

      if (index === -1) {
        newState.push(priority);
      } else {
        newState.splice(index, 1);
      }

      return newState;
    });
  };

  const isInitialFilter = selectedPriorities.length === 0;
  const isAnyPrioritySelected = !isInitialFilter;

  const popupState = usePopupState({ popupId: 'priorityFilter', variant: 'popover' });

  const handleButtonClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    popupState.open(event);
    trackMixpanelEvent('AlertPanel_PriorityTriggered');
  };

  const handleClose = () => {
    popupState.close();

    const isChanged = localFilteredPriorities.length !== selectedPriorities.length;
    trackMixpanelEvent(isChanged ? 'AlertPanel_PriorityIsFiltered' : 'AlertPanel_PriorityIsClosed');

    setValue('priorities', localFilteredPriorities, {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true
    });
  };

  const renderFilterButtonText = () => {
    if (selectedPriorities.length === 1) {
      return tAlert(`priorities.${selectedPriorities[0]}`);
    }

    if (selectedPriorities.length === priorities.length) {
      return tAlertPanel('filters.priority.filter_all');
    }

    if (isInitialFilter || selectedPriorities.length === 0) {
      return tAlertPanel('filters.priority.title');
    }

    return tAlertPanel('filters.priority.filtered_count', { count: selectedPriorities.length });
  };

  return (
    <>
      <CatToggleButton
        {...bindTrigger(popupState)}
        color="darkGrey"
        icon={
          isAnyPrioritySelected ? (
            <ExclamationIcon alwaysHovered color="darkGrey" contained={false} />
          ) : (
            <ExclamationIcon />
          )
        }
        keepIconColor
        onClick={handleButtonClick}
        reverse
        selected={isAnyPrioritySelected}
        title={renderFilterButtonText()}
      />
      <CatMenu {...bindMenu(popupState)} addEmptyFirstItem onClose={handleClose} width="292px">
        <CatTypography className="mx-4 opacity-80" variant="body1">
          <CatTrans i18nKey="filters.priority.filter_title" t={tAlertPanel} />
        </CatTypography>
        <CatMenuDivider />
        {priorities.map((priority) => {
          const checked = localFilteredPriorities.includes(priority);
          return (
            <CatMenuItem key={priority} onClick={() => handlePriorityClick(priority)}>
              <CatCheckbox checked={checked} id={priority} paddingSize="none" />
              <CatTypography
                className={clsx(checked ? 'font-bold' : '', 'truncate')}
                variant="body2"
              >
                {tAlert(`priorities.${priority}`)}
              </CatTypography>
            </CatMenuItem>
          );
        })}
      </CatMenu>
    </>
  );
}

export default PriorityFilter;
