/* eslint-disable func-names */
import { StringSchema } from 'yup';
import {
  SupportedLocales,
  formatDateWithLocale,
  formatMonthWithLocale,
  formatYearWithLocale
} from '@borda/cat-ui';
import { TransKeys } from 'utils/localization/types/trans-keys';
import {
  formatISO,
  isAfter,
  isBefore,
  isThisMonth,
  isThisYear,
  isToday,
  isValid,
  startOfDay,
  startOfMonth,
  startOfYear
} from 'date-fns';
import { tCat } from 'utils/localization';

export type DateParameters = {
  allowEmpty?: boolean;
  compareType?: CompareTypes;
  fieldName?: TransKeys;
  fieldNameProps?: any;
  locale?: SupportedLocales;
  maxDate?: string;
  maxDateErrorMessage?: string;
  minDate?: string;
  minDateErrorMessage?: string;
};

export type CompareTypes = 'day' | 'month' | 'year';

export const defaultMinDate = formatISO(new Date(1911, 1, 1));
export const defaultMaxDate = formatISO(new Date(2099, 12, 31));

export function isBeforeThanMin(date: Date, minDate: Date, compareType: CompareTypes) {
  switch (compareType) {
    case 'day':
      return isBefore(startOfDay(date), startOfDay(minDate));
    case 'month':
      return isBefore(startOfMonth(date), startOfMonth(minDate));
    case 'year':
      return isBefore(startOfYear(date), startOfYear(minDate));
    default:
      return false;
  }
}

function isSameWithToday(date: Date, compareType: CompareTypes) {
  switch (compareType) {
    case 'day':
      return isToday(date);
    case 'month':
      return isThisMonth(date);
    case 'year':
      return isThisYear(date);
    default:
      return false;
  }
}

function formatDateWithCompareType(
  date: string,
  compareType: CompareTypes,
  locale: SupportedLocales
) {
  switch (compareType) {
    case 'day':
      return formatDateWithLocale(date, locale);
    case 'month':
      return formatMonthWithLocale(date, locale);
    case 'year':
      return formatYearWithLocale(date, locale);
    default:
      return '';
  }
}

export function dateComparer(this: StringSchema, dateParameters: DateParameters) {
  return this.test('dateValidator', 'why', function (value) {
    const {
      allowEmpty = false,
      compareType = 'day',
      fieldName,
      fieldNameProps = {},
      locale = 'tr-TR',
      maxDate = defaultMaxDate,
      maxDateErrorMessage,
      minDate = defaultMinDate,
      minDateErrorMessage
    } = dateParameters;

    const { createError, path } = this;

    const field = fieldName ? tCat(fieldName, fieldNameProps) : tCat('common.generic_field_name');

    if (value === undefined) {
      if (!allowEmpty) {
        return createError({
          message: tCat('errors.field_required', { field }),
          path
        });
      }

      return true;
    }

    const date = new Date(value);
    if (value === undefined || !isValid(date)) {
      return createError({
        message: tCat('errors.date.not_valid'),
        path
      });
    }

    const minDateParsed = new Date(minDate);

    if (minDate && isBeforeThanMin(date, minDateParsed, compareType)) {
      if (minDateErrorMessage) {
        return createError({
          message: minDateErrorMessage,
          path
        });
      }

      if (isSameWithToday(minDateParsed, compareType)) {
        return createError({
          message: tCat('errors.date.should_greater_than_now'),
          path
        });
      }

      return createError({
        message: tCat('errors.date.min_date', {
          date:
            minDate === defaultMinDate
              ? tCat('errors.date.minimum')
              : formatDateWithCompareType(minDate, compareType, locale)
        }),
        path
      });
    }

    const maxDateParsed = new Date(maxDate);
    if (maxDate && isAfter(date, maxDateParsed)) {
      if (maxDateErrorMessage) {
        return createError({
          message: maxDateErrorMessage,
          path
        });
      }

      return createError({
        message: tCat('errors.date.max_date', {
          date:
            maxDate === defaultMaxDate
              ? tCat('errors.date.maximum')
              : formatDateWithCompareType(maxDate, compareType, locale)
        }),
        path
      });
    }

    return true;
  });
}
