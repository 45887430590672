import { CategoryStateShape } from './types';

export const maxMainCategoryNumber = 9;
export const maxCategoryDepth = 6;
export const moveMaxDepth = 5;
export const parentCategoryIdOfRoot: string = null;

export const initialCategoryState: CategoryStateShape = {
  expandedCategoryIds: [parentCategoryIdOfRoot]
};
