/* eslint-disable react/prop-types */
import { CatButton, CatTypography } from '@borda/cat-ui';
import { useCatNavigate } from '@borda/cat-core';
import { useMediaQuery, useTheme } from '@borda/cat-mui';
import { useTrackPageLoad } from 'utils/mixpanel';
import Page from 'packages/cat-wrappers/Page';
import React from 'react';

function Error500(props) {
  useTrackPageLoad('500');
  const { errorDescription, onRedirect, reloadOnNavigate } = props;

  const theme = useTheme();
  const navigate = useCatNavigate();
  const mobileDevice = useMediaQuery(theme.breakpoints.down('md'));

  const handleHomeClick = () => {
    navigate('/');
    onRedirect();
  };

  const handleReloadClick = () => {
    window.location.reload();
  };

  return (
    <Page title="Error 500">
      <div className="flex flex-col items-center p-6 pt-[10vh]">
        <CatTypography align="center" variant={mobileDevice ? 'h4' : 'h1'}>
          500: Ooops, something went terribly wrong!
        </CatTypography>
        <CatTypography align="center" variant="subtitle2">
          {errorDescription ??
            'You either tried some shady route or you came here by mistake. Whichever it is, try using the navigation'}
        </CatTypography>
        <div className="mt-12 flex justify-center">
          <img
            alt="Under development"
            className="h-auto max-h-[300px] w-[560px] max-w-full"
            src="/static/images/undraw_server_down_s4lk.svg"
          />
        </div>
        <div className="mt-12 flex justify-center">
          <CatButton
            color="primary"
            onClick={reloadOnNavigate ? handleReloadClick : handleHomeClick}
            size="large"
            variant="outlined"
          >
            {reloadOnNavigate ? 'Refresh browser' : 'Back to home'}
          </CatButton>
        </div>
      </div>
    </Page>
  );
}

export default Error500;
