import { RootState } from 'RootTypes';
import { createSelectorCreator, lruMemoize } from 'reselect';
import { dequal } from 'dequal';

export function booleanCompare(x: boolean, y: boolean) {
  // eslint-disable-next-line no-nested-ternary
  return x === y ? 0 : x ? -1 : 1;
}

export function dateCompare(x: Date, y: Date) {
  // eslint-disable-next-line no-nested-ternary
  return x === y ? 0 : x > y ? -1 : 1;
}

export function numberCompare(x: number, y: number) {
  // eslint-disable-next-line no-nested-ternary
  return x === y ? 0 : x > y ? 1 : -1;
}

export const composeRootState = <T extends keyof RootState>(draft: RootState[T], prop: T): any => {
  const composedState: Partial<RootState> = {
    [prop]: draft
  };
  return composedState;
};

export const createDequalSelector = createSelectorCreator(lruMemoize, dequal);

export type PagedRequestOptions = {
  page: number;
  size: number;
};

export type PagedResult<T> = {
  currentPage: number;
  items: T[];
  size: number;
  total: number;
};

export type File = {
  extension?: string;
  id?: string;
  name?: string;
};

export type Day =
  | 'Monday'
  | 'Tuesday'
  | 'Wednesday'
  | 'Thursday'
  | 'Friday'
  | 'Saturday'
  | 'Sunday';

export const daysOfWeek: Day[] = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday'
];

export type CheckboxState = boolean | 'indeterminate';

export const temporaryCategoryId = '-1';

export type IdResponse = { id: string };
