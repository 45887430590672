import { DateFilter } from './types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { compareSortedArray } from '@borda/cat-core';
import { defaultCategoryTaskFilter } from './data';

const categoryTaskFilterSlice = createSlice({
  initialState: defaultCategoryTaskFilter,
  name: 'categoryTaskFilter',
  reducers: {
    branchIdsUpdated: (draft, action: PayloadAction<string[]>) => {
      const branchIds = action.payload;
      if (compareSortedArray(branchIds, draft.createdByUserBranches)) {
        return;
      }

      draft.createdByUserBranches = action.payload;
      draft.createdByUserIds = defaultCategoryTaskFilter.createdByUserIds;
    },
    categoryIdsUpdated: (draft, action: PayloadAction<string[]>) => {
      draft.categoryIds = action.payload;
    },
    clearFilters: (draft) => {
      draft.createdByUserBranches = defaultCategoryTaskFilter.createdByUserBranches;
      draft.categoryIds = defaultCategoryTaskFilter.categoryIds;
      draft.dateFilter = defaultCategoryTaskFilter.dateFilter;
      draft.createdByUserIds = defaultCategoryTaskFilter.createdByUserIds;
    },
    createdByUserIdsUpdated: (draft, action: PayloadAction<string[]>) => {
      draft.createdByUserIds = action.payload;
      draft.createdByUserBranches = defaultCategoryTaskFilter.createdByUserBranches;
    },
    dateFilterUpdated: (draft, action: PayloadAction<DateFilter>) => {
      draft.dateFilter = action.payload;
    },
    showCompletedUpdated: (draft, action: PayloadAction<boolean>) => {
      draft.showCompleted = action.payload;
    }
  }
});

export default categoryTaskFilterSlice.reducer;

export const {
  branchIdsUpdated,
  categoryIdsUpdated,
  clearFilters,
  createdByUserIdsUpdated,
  dateFilterUpdated,
  showCompletedUpdated
} = categoryTaskFilterSlice.actions;
