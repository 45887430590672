import { CatButton, CatButtonProps } from '@borda/cat-ui';
import { CheckIcon } from '@borda/cat-icons';
import { useCatTranslation } from 'utils/localization';
import React from 'react';

function ConfirmButton(props: CatButtonProps) {
  const { t } = useCatTranslation();
  const {
    children = t('common.confirm'),
    color = 'green',
    endIcon = <CheckIcon />,
    size = 'large',
    ...rest
  } = props;
  return (
    <CatButton color={color} endIcon={endIcon} size={size} {...rest}>
      {children}
    </CatButton>
  );
}

export default ConfirmButton;
