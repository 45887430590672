import { CatCheckbox } from '@borda/cat-ui';
import { NotificationListItem } from 'store/slices/notifications/list/types';
import { itemChecked } from 'store/slices/notifications/list/slice';
import { useTypedDispatch } from 'hooks';
import clsx from 'clsx';

type Props = {
  isChecked: boolean;
  notification: NotificationListItem;
};

function LeftPanel(props: Props) {
  const { isChecked, notification } = props;
  const dispatch = useTypedDispatch();

  const handleCheck = () => {
    dispatch(itemChecked(notification.id));
  };

  return (
    <div
      className={clsx({
        'bg-blue/20': isChecked,
        'bg-darkGrey/10 ': !isChecked,
        'grid cursor-pointer grid-rows-3 place-items-center transition-colors duration-300 ltr:rounded-l-2xl rtl:rounded-r-2xl':
          true
      })}
      onClick={handleCheck}
    >
      <div
        className={clsx(
          !notification.isRead &&
            'bg-red h-[10px] w-[10px] rounded-lg border border-solid border-white'
        )}
      />
      <CatCheckbox checked={isChecked} />
    </div>
  );
}

export default LeftPanel;
