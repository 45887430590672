import { PayloadAction, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { UserDetail } from './types';
import { initialState } from './data';

export const detailsAdapter = createEntityAdapter<UserDetail, string>({
  selectId: (userDetail) => userDetail.personalInformation.id
});

const detailsSlice = createSlice({
  initialState: detailsAdapter.getInitialState(initialState),
  name: 'details',
  reducers: {
    clearAddedUserIds: (draft) => {
      draft.addedUserIds = [];
    },
    insertAddedUserId: (draft, action: PayloadAction<string>) => {
      draft.addedUserIds.push(action.payload);
    },
    upsertUser: detailsAdapter.upsertOne
  }
});

export default detailsSlice.reducer;

export const { clearAddedUserIds, insertAddedUserId, upsertUser } = detailsSlice.actions;
