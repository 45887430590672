import { CatIconButton, CatPaper, CatTypography } from '@borda/cat-ui';
import { CatTrans, useCatTranslation } from 'utils/localization';
import {
  ChevronDownIcon,
  ExpandIcon,
  FlashIcon,
  LighthouseIcon,
  SearchIcon
} from '@borda/cat-icons';
import { Collapse, Modal, Slide } from '@borda/cat-mui';
import { createRef, forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import { useDebounce } from 'react-use';
import { useDialogState } from '@borda/cat-core';
import { useGetSearchIdentificationFields, useSearchAssetsQuery } from './queries';
import IdentificationFieldsDialog from './IdentificationFieldsDialog';
import InputField from './InputField';
import SearchResult from './SearchResult';

type Props = {
  includePassiveAssets?: boolean;
  onAssetSelect: (assetId: string) => Promise<void>;
  onClose?: () => void;
  open?: boolean;
};

function AssetSearchModal(props: Props, ref: React.Ref<any>) {
  const { includePassiveAssets = true, onAssetSelect, onClose, open } = props;
  const { t } = useCatTranslation();

  const [searchText, setSearchText] = useState('');
  const [debouncedSearchText, setDebouncedSearchText] = useState('');

  const [cursor, setCursor] = useState(-1);
  const [expanded, setExpanded] = useState(true);
  const { isOpen, togglePopup } = useDialogState();

  const searchResultsContainerRef = useRef(null);

  const { data: searchResult = [], isFetching: searchLoading } = useSearchAssetsQuery(
    debouncedSearchText,
    includePassiveAssets
  );

  const {
    data: identificationFields = [],
    isFetching: identificationFieldsLoading,
    refetch: fetchIdentificationFields
  } = useGetSearchIdentificationFields();

  useDebounce(
    () => {
      searchResultsContainerRef.current?.scrollTo({
        behavior: 'smooth',
        left: 0,
        top: 0
      });

      setDebouncedSearchText(searchText);

      if (searchResult.length > 0) {
        setExpanded(false);
      }

      setCursor(-1);
    },
    300,
    [searchText]
  );

  useEffect(() => {}, [searchResult.length]);

  const handleChange = (inputText: string) => {
    setSearchText(inputText);
  };

  const itemFound = searchResult.length > 0;

  const optionRefs = useMemo(() => searchResult.map((_) => createRef()), [searchResult]);

  const handleKeyDown = (e: any) => {
    if (!itemFound) {
      return;
    }

    const isUpArrow = e.keyCode === 38;
    const isDownArrow = e.keyCode === 40;
    const isEnter = e.keyCode === 13;

    if (isUpArrow || isDownArrow || isEnter) {
      e.preventDefault();
    }

    if (isEnter && cursor > -1) {
      onAssetSelect(searchResult[cursor].id);
      return;
    }

    let calculatedCursor = cursor;

    if (isUpArrow && cursor > 0) {
      calculatedCursor -= 1;
    } else if (isDownArrow && cursor < searchResult.length - 1) {
      calculatedCursor += 1;
    }

    setCursor(calculatedCursor);
    (optionRefs[calculatedCursor]?.current as any)?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest'
    });
  };

  const handleExpand = () => {
    togglePopup();
    fetchIdentificationFields();
  };

  return (
    <>
      <Modal
        closeAfterTransition
        onClose={onClose}
        open={open}
        ref={ref}
        sx={{ alignContent: 'start', display: 'grid', height: '620px', justifyContent: 'center' }}
      >
        <Slide direction="up" in={open}>
          <div
            className="bg-lightGrey grid content-start gap-6 rounded-2xl p-8"
            style={{ marginTop: '10vh', minHeight: '176px', width: '424px' }}
          >
            <div className="grid gap-4">
              <div className="flex items-center gap-2">
                <FlashIcon color="yellow" contained={false} hoverable={false} />
                <CatTypography variant="h2">
                  {t('layout.content_layout.search.title')}
                </CatTypography>
              </div>
              <CatPaper className="grid p-2">
                <div className="flex w-full items-center justify-between">
                  <CatTypography className="opacity-80" variant="body1">
                    <CatTrans
                      components={{ italic: <i /> }}
                      i18nKey="layout.content_layout.search.searchable_fields_exact_match"
                      t={t}
                    />
                  </CatTypography>
                  <CatIconButton onClick={() => setExpanded(!expanded)}>
                    <ChevronDownIcon
                      className={expanded ? 'rotate-180' : undefined}
                      color="darkGrey"
                    />
                  </CatIconButton>
                </div>
                <Collapse in={expanded}>
                  <div className="bg-lightGrey mt-2 grid gap-2 rounded-lg p-2">
                    <div className="flex items-center gap-2">
                      <LighthouseIcon fontSize="small" />
                      <CatTypography className="opacity-80" variant="body2">
                        {t('layout.content_layout.search.auto_generated_code')}
                      </CatTypography>
                    </div>
                    <div className="divider-horizontal pl-6" />
                    <div className="flex items-center justify-between">
                      <div className="flex items-center gap-2">
                        <SearchIcon fontSize="small" />
                        <CatTypography className="opacity-80" variant="body2">
                          <CatTrans i18nKey="layout.content_layout.search.rest_of_fields" t={t} />
                        </CatTypography>
                      </div>
                      <CatIconButton onClick={handleExpand}>
                        <ExpandIcon color="darkGrey" contained fontSize="small" hoverable={false} />
                      </CatIconButton>
                    </div>
                  </div>
                </Collapse>
              </CatPaper>
              <InputField
                found={itemFound}
                loading={searchLoading}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                searchText={searchText}
              />
            </div>
            <SearchResult
              cursor={cursor}
              onAssetSelect={onAssetSelect}
              optionRefs={optionRefs}
              searchResult={searchResult}
              searchResultsContainerRef={searchResultsContainerRef}
            />
          </div>
        </Slide>
      </Modal>
      <IdentificationFieldsDialog
        identificationFields={identificationFields}
        loading={identificationFieldsLoading}
        onToggle={() => togglePopup()}
        open={isOpen}
      />
    </>
  );
}

export default forwardRef(AssetSearchModal);
