const assetUrls = {
  getAssetTaskHistory: (assetId: string) => `/api/taskmanagement/tasks/by-asset/${assetId}`
};

const taskUrls = {
  getProtectionList: (protectionId: string) => `/api/taskmanagement/tasks/${protectionId}/group`,
  getTaskList: () => '/api/taskmanagement/tasks'
};

export default {
  ...assetUrls,
  ...taskUrls
};
