import { CatIconButton, CatTypography } from '@borda/cat-ui';
import { Control, useController } from 'react-hook-form';
import { DownloadIcon, FileIcon, TrashIcon } from '@borda/cat-icons';
import { cancelFileProgress, getUploadedFile } from 'store/slices/asset/asset/import/actions';
import { fileUploadSchemaErrorsUpdated } from 'store/slices/asset/asset/import/slice';
import { selectFileInfo, selectImportStatus } from 'store/slices/asset/asset/import/selectors';
import { selectUserLanguage } from 'store/slices/session';
import { useCatTranslation } from 'utils/localization';
import { useLoading, useTypedDispatch, useTypedSelector } from 'hooks';
import React, { useMemo } from 'react';
import saveAs from 'file-saver';

interface Props {
  control: Control<any, any>;
}

function UploadedFileCard(props: Props) {
  const { control } = props;
  const { field } = useController({ control, name: 'excelFile' });
  const { onChange, value: excelFile } = field;

  const fileInfo = useTypedSelector(selectFileInfo);
  const importStatus = useTypedSelector(selectImportStatus);
  const userLanguage = useTypedSelector(selectUserLanguage);

  const dispatch = useTypedDispatch();
  const { t } = useCatTranslation();
  const [fileCancelLoading, fileCancelLoadingDispatch] = useLoading();
  const [fileDownloading, fileDownloadingDispatch] = useLoading<string>();

  const handleCancel = async () => {
    if (!importStatus) {
      dispatch(fileUploadSchemaErrorsUpdated([]));
    } else {
      await fileCancelLoadingDispatch(cancelFileProgress());
    }
    onChange(null);
  };

  const handleDownload = async () => {
    if (!importStatus) {
      await saveAs(excelFile, `asset_template_${userLanguage}.xlsx`);
    } else {
      const url = await fileDownloadingDispatch(getUploadedFile());
      await saveAs(url, `asset_template_${userLanguage}.xlsx`);
    }
  };

  const fileSize = useMemo(() => {
    if (fileInfo) {
      return `${Math.round(fileInfo.size / 1000)}KB`;
    }
    if (excelFile) {
      return `${Math.round(excelFile.size / 1000)}KB`;
    }
    return '';
  }, [excelFile, fileInfo]);

  return (
    <div className="border-darkGrey/10 bg-darkGrey/10 group flex h-[72px] max-w-xs items-center justify-between rounded-2xl border border-solid p-4">
      <div className="mr-2 flex items-center gap-2 opacity-80">
        <FileIcon className="opacity-80" />
        <div className="grid gap-0.5">
          <CatTypography
            className="overflow-hidden text-ellipsis whitespace-nowrap"
            variant="body1"
          >
            {fileInfo ? fileInfo.name : excelFile?.name}
          </CatTypography>
          <CatTypography
            className="overflow-hidden text-ellipsis whitespace-nowrap opacity-80"
            variant="caption"
          >
            {t('assets.asset_import.step2.ms_excel_document')}
          </CatTypography>
        </div>
      </div>
      <div className="flex items-center opacity-0 transition-opacity duration-300 ease-in group-hover:opacity-100">
        <CatTypography
          className="mr-2 overflow-hidden text-ellipsis whitespace-nowrap opacity-80"
          variant="body2"
        >
          {fileSize}
        </CatTypography>
        <CatIconButton
          disabled={fileCancelLoading}
          loading={fileDownloading}
          onClick={handleDownload}
        >
          <DownloadIcon color="blue" />
        </CatIconButton>
        <div className="divider-vertical-dark" />
        <CatIconButton loading={fileCancelLoading} onClick={handleCancel}>
          <TrashIcon color="red" />
        </CatIconButton>
      </div>
    </div>
  );
}

export default UploadedFileCard;
