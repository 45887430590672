import { Button } from '@borda/cat-mui';
import { CatChip, CatTypography } from '@borda/cat-ui';
import { SearchIcon } from '@borda/cat-icons';
import { isMacOs } from 'react-device-detect';
import { useCatTranslation } from 'utils/localization';

interface Props {
  onClick: () => void;
}

function SearchButton(props: Props) {
  const { onClick } = props;
  const { t } = useCatTranslation();

  return (
    <Button
      className="bg-lightGrey/60 flex w-44 items-center justify-between gap-2 rounded-2xl p-2 normal-case"
      onClick={onClick}
    >
      <div className="flex items-center gap-2">
        <SearchIcon color="darkGrey" contained hoverable={false} />
        <CatTypography variant="body1">{t('layout.content_layout.search.search')}</CatTypography>
      </div>
      <CatChip color="darkGrey" label={isMacOs ? 'opt + F' : 'Alt + F'} size="small" />
    </Button>
  );
}

export default SearchButton;
