import { Attribute } from 'views/AttributeConfigurations/types';
import { ColumnDynamicData, DynamicListDefinition } from '@borda/cat-core';
import { GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid-pro';
import { ProductKeys } from 'utils/settings';
import { UsersListUser } from 'store/slices/userManagement/users/list/types';
import { useMemo } from 'react';

export const initialColumnDynamicData: DynamicListDefinition<UsersListUser> = {
  columns: [
    {
      column: 'assetRole',
      visible: true,
      width: 200
    },
    {
      column: 'patientRole',
      visible: true,
      width: 200
    },
    {
      column: 'additionalPermissions',
      visible: true,
      width: 100
    },
    {
      column: 'isTagAssigned',
      visible: true,
      width: 100
    },
    {
      column: 'userId',
      visible: false,
      width: 150
    },
    {
      column: 'firstName',
      visible: true,
      width: 150
    },
    {
      column: 'lastName',
      visible: true,
      width: 150
    },
    {
      column: 'email',
      visible: false,
      width: 150
    },
    {
      column: 'branches',
      visible: true,
      width: 150
    },
    {
      column: 'dateAdded',
      visible: true,
      width: 150
    },
    {
      column: 'lastUpdate',
      visible: false,
      width: 150
    },
    {
      column: 'lastSeenTime',
      visible: true,
      width: 100
    },
    {
      column: 'activeTag',
      visible: true,
      width: 100
    },
    {
      column: 'userStatus',
      visible: true,
      width: 200
    }
  ],
  sorts: [{ column: 'firstName', direction: 'asc' }]
};

export function useGetInitialDynamicData<T>(
  enabledProducts: ProductKeys[],
  attributes: Attribute[]
) {
  const { columns, sorts } = initialColumnDynamicData;

  const patientEnabled = enabledProducts.includes('patient');
  const assetEnabled = enabledProducts.includes('asset');

  const commonColumns = (): ColumnDynamicData<UsersListUser>[] => {
    const tempDynamicListDefinitionColumns = [...columns];

    if (!patientEnabled) {
      const patientRoleColumnIndex = tempDynamicListDefinitionColumns.findIndex(
        (i) => i.column === 'patientRole'
      );

      if (patientRoleColumnIndex !== -1) {
        tempDynamicListDefinitionColumns.splice(patientRoleColumnIndex, 1);
      }
    }

    if (!assetEnabled) {
      const assetRoleColumnIndex = tempDynamicListDefinitionColumns.findIndex(
        (i) => i.column === 'assetRole'
      );

      if (assetRoleColumnIndex !== -1) {
        tempDynamicListDefinitionColumns.splice(assetRoleColumnIndex, 1);
      }
    }

    return tempDynamicListDefinitionColumns;
  };

  const customColumns: ColumnDynamicData<T>[] = useMemo(
    () =>
      (attributes ?? []).map((attribute) => ({
        column: attribute.id,
        type: 'attribute',
        visible: false,
        width: 150
      })),
    [attributes]
  );

  return {
    columns: [...commonColumns(), ...customColumns],
    sorts: [...sorts]
  };
}

export const getLeftPinnedColumns = (enabledProducts: ProductKeys[]) => {
  const patientEnabled = enabledProducts.includes('patient');
  const assetEnabled = enabledProducts.includes('asset');

  if (assetEnabled && patientEnabled) {
    return [
      GRID_CHECKBOX_SELECTION_COL_DEF.field,
      'assetRole',
      'patientRole',
      'additionalPermissions',
      'isTagAssigned'
    ];
  }

  if (assetEnabled) {
    return [
      GRID_CHECKBOX_SELECTION_COL_DEF.field,
      'assetRole',
      'additionalPermissions',
      'isTagAssigned'
    ];
  }

  return [
    GRID_CHECKBOX_SELECTION_COL_DEF.field,
    'patientRole',
    'additionalPermissions',
    'isTagAssigned'
  ];
};
