import { CYLERALIST, getColumnsKey } from 'helpers/localStorage';
import { MacItem, MacStatus } from './types';
import { PagedRequestOptions } from 'store/common';
import { RootState } from 'RootTypes';
import { createListAdapter } from '@borda/cat-core';
import { createSlice } from '@reduxjs/toolkit';
import { initialColumnDynamicData } from './data';

export type StatusCounts = Partial<Record<MacStatus, number>>;
export type PaginatedCyleraRequest = PagedRequestOptions & {
  macAddress: string;
  statuses: MacStatus[];
};
export type UpdateCyleraStatusRequest = {
  status: MacStatus;
};
export type AssignMacAddressRequest = {
  deviceMacAddress: string;
};

const listAdapter = createListAdapter<MacItem>({
  initialDynamicListDefinitions: initialColumnDynamicData,
  localStorageKey: getColumnsKey(CYLERALIST)
});

export const initialState = listAdapter.getInitialState({});

const listSlice = createSlice({
  initialState,
  name: 'cyleraList',
  reducers: {
    ...listAdapter.getReducers()
  }
});

export default listSlice.reducer;
export const cyleraListActions = listSlice.actions;

export const { selectListState } = listAdapter.getSelectors<RootState>(
  (state) => state.integrations.cylera.list
);
