import { AuthKeys, selectIsUserAuthorized, selectUserLanguage } from 'store/slices/session';
import { CatTab, CatTabProps, CatTabs } from '@borda/cat-ui';
import { ProductKeys } from 'utils/settings';
import { TransKeys } from 'utils/localization/types/trans-keys';
import { useCatNavigate } from '@borda/cat-core';
import { useCatTranslation } from 'utils/localization';
import { useLocation } from 'react-router-dom';
import { useTypedSelector } from 'hooks';
import React, { useMemo } from 'react';
import clsx from 'clsx';

export type Route = {
  authKey?: AuthKeys;
  development?: boolean;
  disabled?: boolean;
  hidden?: boolean;
  link: string;
  products?: ProductKeys[];
  text: TransKeys;
};

type RoutingButtonsProps = CatTabProps & {
  routes: Route[];
};

function RoutingButtons({ routes = [], ...rest }: RoutingButtonsProps) {
  const location = useLocation();

  const value = useMemo(() => {
    const index = routes.findIndex((r) =>
      location.pathname.match(new RegExp(`${r.link || '#'}`, 'g'))
    );
    return index;
  }, [location.pathname, routes]);

  return (
    <CatTabs menu transparentBackground value={value}>
      {routes.length > 0 &&
        routes.map((route) => (
          <RoutingButton
            key={route.link !== '#' ? route.link : route.text}
            route={route}
            {...rest}
          />
        ))}
    </CatTabs>
  );
}

type RoutingButtonProps = CatTabProps & {
  route: Route;
};

function RoutingButton(props: RoutingButtonProps) {
  const navigate = useCatNavigate();
  const { route, ...rest } = props;
  const { t } = useCatTranslation();
  const language = useTypedSelector(selectUserLanguage);

  const isUserAuthorized = useTypedSelector((state) =>
    selectIsUserAuthorized(state, route.authKey)
  );

  if (!isUserAuthorized) {
    return null;
  }

  const label = t(route.text);

  return (
    <CatTab
      className={clsx({ disabled: route.disabled })}
      disabled={route.disabled}
      label={label.toLocaleUpperCase(language)}
      onClick={() => {
        if (route.link !== '.*') {
          navigate(route.link);
        }
      }}
      {...rest}
    />
  );
}

export default RoutingButtons;
