import { ACCESS_POINTS, CAMERA, CAMERA_LIST, DEVICES, TAGS } from 'routes/constant-route';
import { AuthKeys, User, isUserAuthorized } from 'store/slices/session';
import { Branch } from 'store/slices/branches';
import { Navigate, Outlet, Route } from 'react-router-dom';
import { RouteGuard } from 'components/RouteGuards/AuthGuard';
import { SuspenseOutlet, delayedLazy } from '../helpers';
import { isArrayNullOrEmpty } from '@borda/cat-core';

const CameraAdd = delayedLazy(
  () => import('views/Locations/Camera/CameraDetail/CameraAdd/CameraAdd')
);
const CameraDetail = delayedLazy(
  () => import('views/Locations/Camera/CameraDetail/CameraDetail/CameraDetail')
);
const Cameras = delayedLazy(() => import('views/Locations/Camera/Cameras/Cameras'));
const AccessPoints = delayedLazy(() => import('views/Locations/AccessPoints/AccessPoints'));
const TrackableDevices = delayedLazy(
  () => import('views/TagConfigurations/TrackableDevices/TrackableDevices')
);

export default function renderDevicesRoutes(rtlsEnabledBranches: Branch[], sessionUser: User) {
  return (
    <Route
      element={
        <RouteGuard authKey="devices">
          <SuspenseOutlet />
        </RouteGuard>
      }
      path={DEVICES}
    >
      <Route
        element={
          <RouteGuard authKey="tagConfiguration" hidden={isArrayNullOrEmpty(rtlsEnabledBranches)}>
            <TrackableDevices />
          </RouteGuard>
        }
        path={TAGS}
      />
      <Route
        element={
          <RouteGuard authKey="locationManagement" hidden={isArrayNullOrEmpty(rtlsEnabledBranches)}>
            <AccessPoints />
          </RouteGuard>
        }
        path={ACCESS_POINTS}
      />

      <Route
        element={
          <RouteGuard authKey="camera">
            <Outlet />
          </RouteGuard>
        }
        path={CAMERA}
      >
        <Route
          element={
            <RouteGuard>
              <Cameras />
            </RouteGuard>
          }
          index
          path={CAMERA_LIST}
        />
        <Route element={<CameraDetail />} path="camera/:id" />
        <Route element={<CameraAdd />} path="camera_add" />
        <Route element={<Navigate replace to={CAMERA_LIST} />} index />
      </Route>
      <Route
        element={
          <Navigate replace to={calculateDefaultNavigation(sessionUser, rtlsEnabledBranches)} />
        }
        index
      />
    </Route>
  );
}

interface DevicesNavigationConfig {
  authKey: AuthKeys;
  navigation: string;
  requiresRtlsBranches: boolean;
}

const devicesNavigationConfigs: DevicesNavigationConfig[] = [
  { authKey: 'locationManagement', navigation: ACCESS_POINTS, requiresRtlsBranches: true },
  { authKey: 'camera', navigation: CAMERA, requiresRtlsBranches: false },
  { authKey: 'tagConfiguration', navigation: TAGS, requiresRtlsBranches: true }
];

const calculateDefaultNavigation = (sessionUser: User, rtlsEnabledBranches: Branch[]) => {
  const matchedDevicePage = devicesNavigationConfigs.find(
    (config: DevicesNavigationConfig) =>
      isUserAuthorized(sessionUser, config.authKey) &&
      (!config.requiresRtlsBranches || !isArrayNullOrEmpty(rtlsEnabledBranches))
  );

  return matchedDevicePage ? matchedDevicePage.navigation : '';
};
