import { ArrowRightIcon, CancelIcon, FileIcon } from '@borda/cat-icons';
import { CancelButton, ConfirmButton } from 'packages/cat-wrappers';
import { CatIconButton, CatTypography } from '@borda/cat-ui';
import { LinearProgress } from '@borda/cat-mui';
import { cancelFileProgress } from 'store/slices/asset/asset/import/actions';
import {
  selectFileInfo,
  selectFileUploadPercentage,
  selectImportStatus
} from 'store/slices/asset/asset/import/selectors';
import { useCatTranslation } from 'utils/localization';
import { useLoading, useTypedSelector } from 'hooks';
import React from 'react';

function Processing() {
  const { t } = useCatTranslation();
  const [fileCancelLoading, fileCancelLoadingDispatch] = useLoading();

  const fileUploadPercentage = useTypedSelector(selectFileUploadPercentage);
  const importStatus = useTypedSelector(selectImportStatus);
  const fileInfo = useTypedSelector(selectFileInfo);

  const handleCancel = async () => {
    await fileCancelLoadingDispatch(cancelFileProgress());
  };

  return (
    <>
      <div className="border-darkGrey flex h-[72px] max-w-xs flex-col content-center justify-center rounded-2xl border border-solid border-opacity-10 p-4">
        <div className="flex items-center justify-between">
          <div
            className="mr-8 grid w-full grid-flow-col items-center gap-2"
            style={{ gridTemplateColumns: 'auto 1fr' }}
          >
            <FileIcon className="opacity-60" />
            <div className="grid gap-2">
              <div className="grid grid-flow-col items-center opacity-80">
                {importStatus === 'validating' ? (
                  <>
                    <CatTypography className="whitespace-nowrap" variant="caption">
                      {t('assets.asset_import.step2.uploading_info')}
                    </CatTypography>
                    <ArrowRightIcon className="opacity-20" fontSize="small" />
                    <CatTypography className="truncate opacity-80" variant="caption">
                      {fileInfo?.name}
                    </CatTypography>
                  </>
                ) : (
                  <CatTypography variant="caption">
                    {t('assets.asset_import.common.cancelling')}
                  </CatTypography>
                )}
              </div>
              <LinearProgress
                className="w-full"
                value={fileUploadPercentage}
                variant="determinate"
              />
              <CatTypography
                className="opacity-80"
                variant="caption"
              >{`${fileUploadPercentage} %`}</CatTypography>
            </div>
          </div>
          <CatIconButton loading={fileCancelLoading} onClick={handleCancel}>
            <CancelIcon color="red" />
          </CatIconButton>
        </div>
      </div>
      <div className="mt-4 flex items-center justify-start">
        <CancelButton loading={fileCancelLoading} onClick={handleCancel} />
        <div className="divider-vertical" />
        <ConfirmButton
          disabled={fileUploadPercentage < 100 || fileCancelLoading}
          onClick={undefined}
        />
      </div>
    </>
  );
}

export default Processing;
