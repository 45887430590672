export const priorities = ['low', 'medium', 'high'] as const;
export type Priority = (typeof priorities)[number];

export const responseStatuses = ['open', 'inProgress', 'closed'] as const;
export type ResponseStatuses = (typeof responseStatuses)[number];

export type ResponseSubstatuses = 'cantHandle' | 'acknowledge' | 'onIt' | 'resolve' | 'reject';

export const productTypes = ['asset', 'patient', 'staff'] as const;
export type ProductType = (typeof productTypes)[number];
