import { selectAssetUploadPercentage } from 'store/slices/asset/asset/import/selectors';
import { useTypedSelector } from 'hooks';
import Progress from './Progress/Progress';
import React from 'react';
import Result from './Result/Result';

export default function Step4() {
  const progress = useTypedSelector(selectAssetUploadPercentage);

  return progress < 100 ? <Progress /> : <Result />;
}
