import { AppThunk } from 'RootTypes';
import { getErrorMessageAsync } from 'utils/react-query';
import { showSnackbarMessage } from 'utils/snackbar';
import axios, { AxiosResponse } from 'axios';

type ErrorStatusCodes = 400 | 401 | 404 | 500;

type CallProps = {
  autoFetchAfterCreateOrUpdate?: boolean;
  autoHandleExceptions?: ErrorStatusCodes[];
};

type PromiseBuilder<T> = () => Promise<AxiosResponse<T>>;

// TODO: Legacy api calls. Remove it in future
export const apiCaller =
  <T>(promiseBuilder: PromiseBuilder<T>, props: CallProps = {}): AppThunk<Promise<T>> =>
  async () => {
    const { autoFetchAfterCreateOrUpdate = true, autoHandleExceptions = [] } = props;
    try {
      // Make call
      return await makeRequest(promiseBuilder, autoFetchAfterCreateOrUpdate);
    } catch (error: any) {
      handleError(error, autoHandleExceptions);

      throw error;
    }
  };

const handleError = async (error: any, autoHandleExceptions: ErrorStatusCodes[]) => {
  if (autoHandleExceptions.includes(error?.response?.status)) {
    return;
  }

  const message = await getErrorMessageAsync(error);
  showSnackbarMessage(message, 'error');
};

const makeRequest = async <T>(
  promiseBuilder: () => Promise<AxiosResponse<T>>,
  autoFetch: boolean
): Promise<T> => {
  let promiseBuilderInternal = promiseBuilder;

  const response = await promiseBuilderInternal();
  if (
    (response.status !== 201 && response.status !== 204) ||
    !response?.headers?.location ||
    !autoFetch
  ) {
    return response.data;
  }

  // Fetch data from location header
  const requestUrl = response.headers.location;
  promiseBuilderInternal = () => axios.get<T>(`/api${requestUrl}`);

  const responseFromLocation = await promiseBuilderInternal();
  return responseFromLocation.data;
};
