import { DynamicListDefinition } from '@borda/cat-core';
import { Task } from 'store/slices/tasks/common/type';

export const initialColumnDynamicData: DynamicListDefinition<Task> = {
  columns: [
    {
      column: 'taskType',
      visible: true,
      width: 150
    },
    {
      column: 'taskId',
      visible: true,
      width: 100
    },
    {
      column: 'categoryName',
      visible: true,
      width: 150
    },
    {
      column: 'brandName',
      visible: true,
      width: 150
    },
    {
      column: 'modelName',
      visible: true,
      width: 150
    },
    {
      column: 'code',
      visible: true,
      width: 200
    },
    {
      column: 'requestedDate',
      visible: true,
      width: 150
    },
    {
      column: 'priority',
      visible: false,
      width: 150
    },
    {
      column: 'requestedPersonnel',
      visible: false,
      width: 150
    },
    {
      column: 'responsiblePersonnel',
      visible: false,
      width: 150
    },
    {
      column: 'usability',
      visible: false,
      width: 150
    },
    {
      column: 'lastSeenTime',
      visible: false,
      width: 150
    },
    {
      column: 'activeTag',
      visible: false,
      width: 150
    },
    {
      column: 'status',
      visible: true,
      width: 150
    }
  ],
  sorts: [{ column: 'taskType', direction: 'asc' }]
};
