import { AlertPanelFilter } from 'views/Alert/AlertPanel/types';
import {
  CatCheckbox,
  CatMenu,
  CatMenuDivider,
  CatMenuItem,
  CatToggleButton,
  CatTypography
} from '@borda/cat-ui';
import { CatTrans, useCatTranslation } from 'utils/localization';
import { ProductType, productTypes } from 'views/Alert/types';
import { ProductTypeIcon } from '@borda/cat-icons';
import { bindMenu, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import { trackMixpanelEvent } from 'utils/mixpanel';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import clsx from 'clsx';

function ProductTypeFilter() {
  const { t: tAlertPanel } = useCatTranslation('translation', {
    keyPrefix: 'alert_panel.header'
  });

  const { t: tAlert } = useCatTranslation('translation', {
    keyPrefix: 'alert'
  });

  const { setValue, watch } = useFormContext<AlertPanelFilter>();
  const { productTypes: selectedProductTypes } = watch();

  const [localFilteredProductTypes, setLocalFilteredProductTypes] =
    useState<ProductType[]>(selectedProductTypes);

  useEffect(() => {
    setLocalFilteredProductTypes(selectedProductTypes);
  }, [selectedProductTypes]);

  const handleProductTypeyClick = (productType: ProductType) => {
    setLocalFilteredProductTypes((prevState: ProductType[]) => {
      const newState: ProductType[] = [...prevState];
      const index = newState.findIndex((i) => i === productType);

      if (index === -1) {
        newState.push(productType);
      } else {
        newState.splice(index, 1);
      }

      return newState;
    });
  };

  const isInitialFilter = selectedProductTypes.length === 0;
  const isAnyProductTypeSelected = !isInitialFilter;

  const popupState = usePopupState({ popupId: 'productTypeFilter', variant: 'popover' });

  const handleButtonClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    popupState.open(event);
    trackMixpanelEvent('AlertPanel_ProductTypeTriggered');
  };

  const handleClose = () => {
    popupState.close();

    const isChanged = localFilteredProductTypes.length !== selectedProductTypes.length;
    trackMixpanelEvent(
      isChanged ? 'AlertPanel_ProductTypeIsFiltered' : 'AlertPanel_ProductTypeIsClosed'
    );

    setValue('productTypes', localFilteredProductTypes, {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true
    });
  };

  const renderFilterButtonText = () => {
    if (selectedProductTypes.length === 1) {
      return tAlert(`productTypes.${selectedProductTypes[0]}`);
    }

    if (selectedProductTypes.length === productTypes.length) {
      return tAlertPanel('filters.product_types.filter_all');
    }

    if (isInitialFilter || selectedProductTypes.length === 0) {
      return tAlertPanel('filters.product_types.title');
    }

    return tAlertPanel('filters.product_types.filtered_count', {
      count: selectedProductTypes.length
    });
  };

  return (
    <>
      <CatToggleButton
        {...bindTrigger(popupState)}
        color="darkGrey"
        icon={
          isAnyProductTypeSelected ? (
            <ProductTypeIcon alwaysHovered color="darkGrey" contained={false} />
          ) : (
            <ProductTypeIcon />
          )
        }
        keepIconColor
        onClick={handleButtonClick}
        reverse
        selected={isAnyProductTypeSelected}
        title={renderFilterButtonText()}
      />
      <CatMenu {...bindMenu(popupState)} addEmptyFirstItem onClose={handleClose} width="292px">
        <CatTypography className="mx-4 opacity-80" variant="body1">
          <CatTrans i18nKey="filters.product_types.filter_title" t={tAlertPanel} />
        </CatTypography>
        <CatMenuDivider />
        {productTypes.map((productType) => {
          const checked = localFilteredProductTypes.includes(productType);
          return (
            <CatMenuItem key={productType} onClick={() => handleProductTypeyClick(productType)}>
              <CatCheckbox checked={checked} id={productType} paddingSize="none" />
              <CatTypography
                className={clsx(checked ? 'font-bold' : '', 'truncate')}
                variant="body2"
              >
                {tAlert(`productTypes.${productType}`)}
              </CatTypography>
            </CatMenuItem>
          );
        })}
      </CatMenu>
    </>
  );
}

export default ProductTypeFilter;
