import { AssetFilter, AttributeFilterKeys, DefinitionPlanTypes } from './types';
import { AssetStatus } from 'store/slices/common/types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { assetFilterInitialState, emptyFilter, emptyPlanDefinitionsFilter } from './data';
import { dequal } from 'dequal';
import { isArrayNullOrEmpty } from '@borda/cat-core';

export const assetFilterSlice = createSlice({
  initialState: assetFilterInitialState,
  name: 'assetFilter',
  reducers: {
    clearActiveFilter: (draft) => {
      draft.activeFilter = emptyFilter;
    },
    extraFilterAdded: (draft, action: PayloadAction<AssetStatus>) => {
      const status = action.payload;
      if (action.payload) {
        const index = draft.extraFilters.findIndex((item) => item === status);
        if (index > -1) {
          draft.extraFilters.splice(index, 1);
        } else {
          draft.extraFilters.push(status);
        }
      }
    },
    extraFiltersUpdated: (draft, action: PayloadAction<AssetStatus[]>) => {
      draft.extraFilters = action.payload;
    },
    removeAttribute: (
      draft,
      action: PayloadAction<{ activeTab: AttributeFilterKeys; attributeId: string }>
    ) => {
      const { activeTab, attributeId } = action.payload;
      const index = draft.activeFilter.attribute[activeTab].findIndex((i) => i.id === attributeId);
      draft.activeFilter.attribute[activeTab].splice(index, 1);
    },
    removeAttributeOption: (
      draft,
      action: PayloadAction<{
        activeTab: AttributeFilterKeys;
        attributeId: string;
        optionId: string;
      }>
    ) => {
      const { activeTab, attributeId, optionId } = action.payload;

      const attribute = draft.activeFilter.attribute[activeTab].find((i) => i.id === attributeId);
      const optionIndex = attribute.optionIds.indexOf(optionId);
      attribute.optionIds.splice(optionIndex, 1);
    },
    removeBranch: (draft, action: PayloadAction<string>) => {
      const branch = action.payload;
      const hasBranch = draft.activeFilter.information.branches.includes(branch);
      if (hasBranch) {
        const index = draft.activeFilter.information.branches.findIndex((item) => item === branch);
        draft.activeFilter.information.branches.splice(index, 1);
      }
    },
    removeContractEndDate: (draft) => {
      draft.activeFilter.contract.endDate = emptyFilter.contract.endDate;
    },
    removeContractFirm: (draft, action: PayloadAction<string>) => {
      const firm = action.payload;
      const firmIndex = draft.activeFilter.contract.firm.contractFirms.findIndex(
        (firmItem) => firmItem === firm
      );
      draft.activeFilter.contract.firm.contractFirms.splice(firmIndex, 1);
    },
    removeContractFirmNotAssigned: (draft) => {
      const noFirmValue = draft.activeFilter.contract.firm.noContractFirm;
      draft.activeFilter.contract.firm.noContractFirm = !noFirmValue;
    },
    removeContractPerson: (draft, action: PayloadAction<string>) => {
      const person = action.payload;
      const personIndex = draft.activeFilter.contract.contactPerson.contactPersons.findIndex(
        (personItem) => personItem === person
      );
      draft.activeFilter.contract.contactPerson.contactPersons.splice(personIndex, 1);
    },
    removeContractPersonNotAssigned: (draft) => {
      const noPersonValue = draft.activeFilter.contract.contactPerson.noContactPerson;
      draft.activeFilter.contract.contactPerson.noContactPerson = !noPersonValue;
    },
    removeContractType: (draft, action: PayloadAction<string>) => {
      const contractType = action.payload;
      const contractTypeIndex = draft.activeFilter.contract.types.findIndex(
        (type) => type === contractType
      );
      draft.activeFilter.contract.types.splice(contractTypeIndex, 1);
    },
    removeDefinitionDoesNotRequirePlan: (draft, action: PayloadAction<DefinitionPlanTypes>) => {
      const planType = action.payload;
      draft.activeFilter.definition[planType].doesNotRequirePlan =
        emptyPlanDefinitionsFilter.doesNotRequirePlan;
    },
    removeDefinitionEndDate: (draft, action: PayloadAction<DefinitionPlanTypes>) => {
      const planType = action.payload;
      draft.activeFilter.definition[planType].endDate = emptyPlanDefinitionsFilter.endDate;
    },
    removeDefinitionNoPlanButRequired: (draft, action: PayloadAction<DefinitionPlanTypes>) => {
      const planType = action.payload;
      draft.activeFilter.definition[planType].noPlanButRequired =
        emptyPlanDefinitionsFilter.noPlanButRequired;
    },
    removeDefinitionPlanId: (
      draft,
      action: PayloadAction<{ planId: string; planType: DefinitionPlanTypes }>
    ) => {
      const { planId, planType } = action.payload;
      const planIndex = draft.activeFilter.definition[planType].planIds.findIndex(
        (plan) => plan === planId
      );
      if (planIndex !== -1) {
        draft.activeFilter.definition[planType].planIds.splice(planIndex, 1);
      }
    },

    removeDefinitionRequirePlan: (draft, action: PayloadAction<DefinitionPlanTypes>) => {
      const planType = action.payload;
      draft.activeFilter.definition[planType].requirePlan = emptyPlanDefinitionsFilter.requirePlan;
    },
    removeInformationActiveLocation: (draft, action: PayloadAction<string>) => {
      const locationId = action.payload;
      const locationIndex = draft.activeFilter.information.location.activeLocations.findIndex(
        (locationItem) => locationItem === locationId
      );
      draft.activeFilter.information.location.activeLocations.splice(locationIndex, 1);
    },
    removeInformationAssignedLocation: (draft, action: PayloadAction<string>) => {
      const locationId = action.payload;
      const locationIndex = draft.activeFilter.information.location.locations.findIndex(
        (locationItem) => locationItem === locationId
      );
      draft.activeFilter.information.location.locations.splice(locationIndex, 1);
    },
    removeInformationAssignedLocationNotAssigned: (draft) => {
      const noLocationValue = draft.activeFilter.information.location.noLocation;
      draft.activeFilter.information.location.noLocation = !noLocationValue;
    },
    removeInformationBrand: (draft, action: PayloadAction<string>) => {
      const brandId = action.payload;
      const brandModelIndex = draft.activeFilter.information.brandModels.findIndex(
        (brandModel) => brandModel.brand === brandId
      );
      draft.activeFilter.information.brandModels.splice(brandModelIndex, 1);
    },
    removeInformationCategory: (draft, action: PayloadAction<string>) => {
      const categoryId = action.payload;
      const categoryIndex = draft.activeFilter.information.categories.findIndex(
        (category) => category === categoryId
      );
      draft.activeFilter.information.categories.splice(categoryIndex, 1);
    },
    removeInformationCode: (draft, action: PayloadAction<string>) => {
      const code = action.payload;
      const codeIndex = draft.activeFilter.information.codes.findIndex(
        (codeItem) => codeItem === code
      );
      draft.activeFilter.information.codes.splice(codeIndex, 1);
    },
    removeInformationCreatedBy: (draft, action: PayloadAction<string>) => {
      const createdById = action.payload;
      const createdByIndex = draft.activeFilter.information.createdByUsers.findIndex(
        (createdByItem) => createdByItem === createdById
      );
      draft.activeFilter.information.createdByUsers.splice(createdByIndex, 1);
    },
    removeInformationCreatedDate: (draft) => {
      draft.activeFilter.information.createdDate = emptyFilter.information.createdDate;
    },
    removeInformationCustody: (draft, action: PayloadAction<string>) => {
      const custodyId = action.payload;
      const custodianIndex = draft.activeFilter.information.custody.custodies.findIndex(
        (custodianIndex) => custodianIndex === custodyId
      );
      draft.activeFilter.information.custody.custodies.splice(custodianIndex, 1);
    },
    removeInformationCustodyNotAssigned: (draft) => {
      const noCustodyValue = draft.activeFilter.information.custody.noCustody;
      draft.activeFilter.information.custody.noCustody = !noCustodyValue;
    },
    removeInformationCustodyWaitingForApproval: (draft) => {
      const noCustodyValue = draft.activeFilter.information.custody.haveWaitingForApprovalCustody;
      draft.activeFilter.information.custody.haveWaitingForApprovalCustody = !noCustodyValue;
    },
    removeInformationModel: (
      draft,
      action: PayloadAction<{ brandId: string; modelId: string }>
    ) => {
      const { brandId, modelId } = action.payload;
      const brandModelIndex = draft.activeFilter.information.brandModels.findIndex(
        (brandModel) => brandModel.brand === brandId
      );
      const brandModel = draft.activeFilter.information.brandModels[brandModelIndex];
      const modelIndex = brandModel.models.findIndex((model) => model === modelId);
      if (modelIndex !== -1) {
        brandModel.models.splice(modelIndex, 1);
      }
    },
    removeInformationProductionDate: (draft) => {
      draft.activeFilter.information.productionDate = emptyFilter.information.productionDate;
    },
    removeInformationPurchasedDate: (draft) => {
      draft.activeFilter.information.purchasedDate = emptyFilter.information.purchasedDate;
    },
    removeInformationPurchasedFirm: (draft, action: PayloadAction<string>) => {
      const firm = action.payload;
      const firmIndex = draft.activeFilter.information.purchasedFirm.purchasedFirms.findIndex(
        (firmItem) => firmItem === firm
      );
      draft.activeFilter.information.purchasedFirm.purchasedFirms.splice(firmIndex, 1);
    },
    removeInformationPurchasedFirmNotAssigned: (draft) => {
      const noFirmValue = draft.activeFilter.information.purchasedFirm.noPurchasedFirm;
      draft.activeFilter.information.purchasedFirm.noPurchasedFirm = !noFirmValue;
    },
    removeInformationStatus: (draft, action: PayloadAction<AssetStatus>) => {
      const statusType = action.payload;
      const statusTypeIndex = draft.activeFilter.information.assetStatuses.findIndex(
        (type) => type === statusType
      );
      if (statusTypeIndex === -1) {
        draft.activeFilter.information.assetStatuses.push(statusType);
      } else {
        draft.activeFilter.information.assetStatuses.splice(statusTypeIndex, 1);
      }

      const sortedTypes = [...draft.activeFilter.information.assetStatuses].sort();
      const sortedDefaultTypes = [...emptyFilter.information.assetStatuses].sort();
      if (dequal(sortedTypes, sortedDefaultTypes) || isArrayNullOrEmpty(sortedTypes)) {
        draft.activeFilter.information.assetStatuses = emptyFilter.information.assetStatuses;
      }
    },
    removeNoContractTypes: (draft) => {
      const emptyContractTypes = emptyFilter.contract.noContractAssigned.contractTypes;
      const emptyContractNotAssigned = emptyFilter.contract.noContractAssigned.noContract;

      draft.activeFilter.contract.noContractAssigned.contractTypes = emptyContractTypes;

      draft.activeFilter.contract.noContractAssigned.noContract = emptyContractNotAssigned;
    },
    removePartsPolicy: (draft, action: PayloadAction<string>) => {
      const partsPolicy = action.payload;
      const policyIndex = draft.activeFilter.contract.partPolicyTypes.findIndex(
        (policy) => policy === partsPolicy
      );
      draft.activeFilter.contract.partPolicyTypes.splice(policyIndex, 1);
    },
    updateActiveFilter: (draft, action: PayloadAction<AssetFilter>) => {
      draft.activeFilter = { ...action.payload };
    }
  }
});

export const {
  clearActiveFilter,
  extraFilterAdded,
  extraFiltersUpdated,
  removeAttribute,
  removeAttributeOption,
  removeBranch,
  removeContractEndDate,
  removeContractFirm,
  removeContractFirmNotAssigned,
  removeContractPerson,
  removeContractPersonNotAssigned,
  removeContractType,
  removeDefinitionDoesNotRequirePlan,
  removeDefinitionEndDate,
  removeDefinitionNoPlanButRequired,
  removeDefinitionPlanId,
  removeDefinitionRequirePlan,
  removeInformationActiveLocation,
  removeInformationAssignedLocation,
  removeInformationAssignedLocationNotAssigned,
  removeInformationBrand,
  removeInformationCategory,
  removeInformationCode,
  removeInformationCreatedBy,
  removeInformationCreatedDate,
  removeInformationCustody,
  removeInformationCustodyNotAssigned,
  removeInformationCustodyWaitingForApproval,
  removeInformationModel,
  removeInformationProductionDate,
  removeInformationPurchasedDate,
  removeInformationPurchasedFirm,
  removeInformationPurchasedFirmNotAssigned,
  removeInformationStatus,
  removeNoContractTypes,
  removePartsPolicy,
  updateActiveFilter
} = assetFilterSlice.actions;

export default assetFilterSlice.reducer;
