/* eslint-disable func-names */
// yup-extended.ts
import * as yup from 'yup';
import { AnyObject, Maybe, Optionals } from 'yup/lib/types';
import { DateParameters, dateComparer } from './yupDateValidator';
import {
  endOfDay,
  endOfMonth,
  endOfYear,
  formatISO,
  isAfter,
  isValid,
  startOfDay,
  startOfMonth,
  startOfYear
} from 'date-fns';
import { formatDateWithLocale } from '@borda/cat-ui';
import { tCat } from 'utils/localization';
import Lazy from 'yup/lib/Lazy';

yup.addMethod<yup.StringSchema>(
  yup.string,
  'maxDate',
  function (maxDate: string, message: string = 'Must be smaller') {
    return this.test('maxDate', message, function (value) {
      const { createError, path } = this;

      const maxDateParsed = new Date(maxDate);
      const date = new Date(value);

      if (isAfter(date, maxDateParsed)) {
        return createError({
          message,
          path
        });
      }

      return true;
    });
  }
);

// https://stackoverflow.com/a/201378
export const emailRegex =
  // eslint-disable-next-line no-control-regex, no-useless-escape
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

yup.addMethod<yup.StringSchema>(
  yup.string,
  'emailExtended',
  function (message: string = tCat('errors.yup.string.email')) {
    return this.matches(emailRegex, message);
  }
);

yup.addMethod<yup.StringSchema>(
  yup.string,
  'json',
  function (message: string = tCat('errors.yup.string.json')) {
    return this.test('json', message, (value) => {
      if (value === null) {
        return true;
      }

      try {
        JSON.parse(value);
        return true;
      } catch {
        return false;
      }
    });
  }
);

const parseDate = (dateISO: string, fallbackDate: string) => {
  const date = new Date(dateISO);

  // TODO: Use constants
  if (dateISO === '' || dateISO === null || !isValid(date)) {
    return new Date(fallbackDate);
  }

  return date;
};

yup.addMethod<yup.DateSchema>(yup.date, 'maxDay', function (maxDate: string, message?: string) {
  const maxBoundary = endOfDay(parseDate(maxDate, defaultMaxDate));

  return this.max(maxBoundary, message);
});

yup.addMethod<yup.DateSchema>(yup.date, 'minDay', function (minDate: string, message?: string) {
  const minBoundary = startOfDay(parseDate(minDate, defaultMinDate));

  return this.min(minBoundary, message);
});

yup.addMethod<yup.DateSchema>(yup.date, 'maxMonth', function (maxDate: string, message?: string) {
  const maxBoundary = endOfMonth(parseDate(maxDate, defaultMaxDate));

  return this.max(maxBoundary, message);
});

yup.addMethod<yup.DateSchema>(yup.date, 'minMonth', function (minDate: string, message?: string) {
  const minBoundary = startOfMonth(parseDate(minDate, defaultMinDate));

  return this.min(minBoundary, message);
});

yup.addMethod<yup.DateSchema>(yup.date, 'maxYear', function (maxDate: string, message?: string) {
  const maxBoundary = endOfYear(parseDate(maxDate, defaultMaxDate));

  return this.max(maxBoundary, message);
});

yup.addMethod<yup.DateSchema>(yup.date, 'minYear', function (minDate: string, message?: string) {
  const minBoundary = startOfYear(parseDate(minDate, defaultMinDate));

  return this.min(minBoundary, message);
});

yup.addMethod<yup.StringSchema>(yup.string, 'dateValidator', dateComparer);

yup.addMethod<yup.ArraySchema<any>>(
  yup.array,
  'noDuplicate',
  function (message: string = 'Values need te be unique') {
    return this.test('noDuplicate', message, function (value) {
      const { createError, path } = this;

      const isValid = new Set(value).size === value?.length;

      if (!isValid) {
        return createError({
          message,
          path
        });
      }

      return true;
    });
  }
);

yup.setLocale({
  array: {
    min: ({ label, min }) =>
      tCat('errors.yup.array.min', { context: label ? 'with_label' : null, label, min })
  },
  date: {
    max: ({ label, max }) =>
      tCat('errors.yup.date.max', {
        context: label ? 'with_label' : null,
        label,
        max: formatDateWithLocale(max as string, 'tr-TR')
      }),
    min: ({ label, min }) =>
      tCat('errors.yup.date.min', {
        context: label ? 'with_label' : null,
        label,
        min: formatDateWithLocale(min as string, 'tr-TR')
      })
  },
  mixed: {
    notType: (params) => {
      const { label, type } = params;

      if (type === 'date') {
        return tCat('errors.yup.date.type_error', {
          context: label ? 'with_label' : null,
          label
        });
      }
      return tCat('errors.yup.mixed.type_error', { context: label ? 'with_label' : null, label });
    },
    required: ({ label }) =>
      tCat('errors.yup.mixed.required', { context: label ? 'with_label' : null, label })
  },
  number: {
    integer: ({ label }) =>
      tCat('errors.yup.number.integer', { context: label ? 'with_label' : null, label }),
    max: ({ label, max }) =>
      tCat('errors.yup.number.max', { context: label ? 'with_label' : null, label, max }),
    min: ({ label, min }) =>
      tCat('errors.yup.number.min', { context: label ? 'with_label' : null, label, min }),
    positive: ({ label }) =>
      tCat('errors.yup.number.positive', { context: label ? 'with_label' : null, label })
  },
  string: {
    email: ({ label }) =>
      tCat('errors.yup.string.email', { context: label ? 'with_label' : null, label }),
    max: ({ label, max }) =>
      tCat('errors.yup.string.max', { context: label ? 'with_label' : null, label, max }),
    min: ({ label, min }) =>
      tCat('errors.yup.string.min', { context: label ? 'with_label' : null, label, min }),
    url: ({ label }) =>
      tCat('errors.yup.string.url', { context: label ? 'with_label' : null, label })
  }
});

export const defaultMinDate = formatISO(startOfDay(new Date(1911, 1, 1)));
export const defaultMaxDate = formatISO(endOfDay(new Date(2099, 12, 31)));
export const defaultMaxNumber = 99999999;

export function getGreaterDate(date1ISO: string, date2ISO: string) {
  const date1 = new Date(date1ISO);
  const date2 = new Date(date2ISO);
  if (!isValid(date1)) {
    return date2;
  }

  if (!isValid(date2)) {
    return date1;
  }

  const givenDateIsGreater = isAfter(startOfDay(new Date(date1)), startOfDay(new Date(date2)));

  return givenDateIsGreater ? date1 : date2;
}

export const getErrorMessages = (error: yup.ValidationError) => {
  const { inner, message, path } = error;
  if (inner && inner.length) {
    const result: any = {};
    inner.forEach((i) => {
      result[i.path] = i.message;
    });

    return result;
  }

  return { [path]: message };
};

declare module 'yup' {
  interface DateSchema<
    TType extends Maybe<Date>,
    TContext extends AnyObject = AnyObject,
    TOut extends TType = TType
  > extends yup.BaseSchema<TType, TContext, TOut> {
    maxDay(maxDate: string, message?: string): DateSchema<TType, TContext, TOut>;
    maxMonth(maxDate: string, message?: string): DateSchema<TType, TContext, TOut>;
    maxYear(maxDate: string, message?: string): DateSchema<TType, TContext, TOut>;
    minDay(minDate: string, message?: string): DateSchema<TType, TContext, TOut>;
    minMonth(minDate: string, message?: string): DateSchema<TType, TContext, TOut>;
    minYear(minDate: string, message?: string): DateSchema<TType, TContext, TOut>;
  }
  interface StringSchema<
    TType extends Maybe<string> = string | undefined,
    TContext extends AnyObject = AnyObject,
    TOut extends TType = TType
  > extends yup.BaseSchema<TType, TContext, TOut> {
    dateValidator(params: DateParameters): StringSchema<TType, TContext>;
    emailExtended(message?: string): StringSchema<TType, TContext>;
    json(message?: string): StringSchema<TType, TContext>;
    maxDate(maxDate: string, message?: string): StringSchema<TType, TContext>;
  }
  interface ArraySchema<
    T extends yup.AnySchema | Lazy<any, any>,
    C extends AnyObject = AnyObject,
    TIn extends Maybe<yup.TypeOf<T>[]> = yup.TypeOf<T>[] | undefined,
    TOut extends Maybe<yup.Asserts<T>[]> = yup.Asserts<T>[] | Optionals<TIn>
  > extends yup.BaseSchema<TIn, C, TOut> {
    noDuplicate(message?: string): ArraySchema<T, C>;
  }
}

export default yup;
