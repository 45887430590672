import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { PlannerStatus } from './types';
import { initialState } from './data';

const monthlySlice = createSlice({
  initialState,
  name: 'monthly',
  reducers: {
    activeDragIdUpdated: (draft, action: PayloadAction<string>) => {
      draft.activeDragId = action.payload;
    },
    activePlanIdUpdated: (draft, action: PayloadAction<string>) => {
      draft.activePlanId = action.payload;
    },
    activeTaskIdUpdated: (draft, action: PayloadAction<string>) => {
      draft.activeTaskId = action.payload;
    },
    animationCompletedUpdated: (draft, action: PayloadAction<boolean>) => {
      draft.animationCompleted = action.payload;
    },
    plannerStatusUpdated: (draft, action: PayloadAction<PlannerStatus>) => {
      draft.plannerStatus = action.payload;
    }
  }
});

export default monthlySlice.reducer;
export const {
  activeDragIdUpdated,
  activePlanIdUpdated,
  activeTaskIdUpdated,
  animationCompletedUpdated,
  plannerStatusUpdated
} = monthlySlice.actions;
