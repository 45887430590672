import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'RootTypes';
import { defaultFilter } from './types';

const cameraListFilterSlice = createSlice({
  initialState: defaultFilter,
  name: 'cameraListFilter',
  reducers: {
    branchFilterChanged: (draft, action: PayloadAction<string[]>) => {
      draft.branchIds = action.payload;
    },
    connectionStatusChanged: (draft, action: PayloadAction<boolean>) => {
      draft.isConnected = action.payload;
    },
    searchTextFilterChanged: (draft, action: PayloadAction<string>) => {
      draft.searchText = action.payload;
    },
    searchTextFilterCleared: (draft) => {
      draft.searchText = defaultFilter.searchText;
    }
  }
});

export const selectCameraListFilter = (state: RootState) => state.locations.cameras.filter;

export const {
  branchFilterChanged,
  connectionStatusChanged,
  searchTextFilterChanged,
  searchTextFilterCleared
} = cameraListFilterSlice.actions;
export default cameraListFilterSlice.reducer;
