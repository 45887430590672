import { CatIconButton } from '@borda/cat-ui';
import { MutableRefObject } from 'react';
import { ReminderIcon } from '@borda/cat-icons';
import { usePopupState } from 'material-ui-popup-state/hooks';
import NotificationPopover from './NotificationPopover';

interface Props {
  popoverAnchorRef: MutableRefObject<HTMLDivElement>;
}

function NotificationButton(props: Props) {
  const { popoverAnchorRef } = props;

  const popupState = usePopupState({ popupId: 'notification-panel', variant: 'popover' });

  return (
    <>
      <CatIconButton
        onClick={() => {
          popupState.open(popoverAnchorRef.current);
        }}
      >
        <ReminderIcon alwaysHovered={popupState.isOpen} className="h-10 w-10" color="blue" />
      </CatIconButton>
      <NotificationPopover open={popupState.isOpen} popupState={popupState} />
    </>
  );
}

export default NotificationButton;
