import { DynamicListDefinition } from '@borda/cat-core';
import { MacItem } from './types';

export const initialColumnDynamicData: DynamicListDefinition<MacItem> = {
  columns: [
    {
      column: 'macAddress',
      visible: true,
      width: 150
    },
    {
      column: 'assetId',
      visible: false,
      width: 150
    },
    {
      column: 'status',
      visible: true,
      width: 100
    },
    {
      column: 'aeTitle',
      visible: false,
      width: 100
    },
    {
      column: 'assetCode',
      visible: false,
      width: 100
    },
    {
      column: 'class',
      visible: true,
      width: 150
    },
    {
      column: 'type',
      visible: true,
      width: 150
    },
    {
      column: 'model',
      visible: true,
      width: 150
    },
    {
      column: 'vendor',
      visible: true,
      width: 150
    },
    {
      column: 'lastSeen',
      visible: true,
      width: 150
    },
    {
      column: 'ipAddress',
      visible: true,
      width: 100
    },
    {
      column: 'registerDisregardAction',
      visible: true,
      width: 150
    }
  ],
  sorts: [{ column: 'lastSeen', direction: 'desc' }]
};
