import { Navigate, Outlet, Route } from 'react-router-dom';
import { RouteGuard } from 'components/RouteGuards/AuthGuard';
import { SuspenseOutlet, delayedLazy } from '../helpers';

const MyRequestList = delayedLazy(() => import('views/Tasks/MyRequests/MyRequests'));
const BreakdownDetail = delayedLazy(
  () => import('views/Tasks/Breakdowns/MyRequestDetail/MyRequestDetailPage')
);
const OpenBreakdownPage = delayedLazy(
  () => import('views/Tasks/Breakdowns/Open/OpenBreakdownPage')
);
const ReopenBreakdownPage = delayedLazy(
  () => import('views/Tasks/Breakdowns/Open/ReopenBreakdownPage')
);

export default function renderRequestRoutes() {
  return (
    <Route
      element={
        <RouteGuard products={['asset']}>
          <SuspenseOutlet />
        </RouteGuard>
      }
      path="request"
    >
      <Route element={<Outlet />} path="my-requests">
        <Route element={<MyRequestList />} index />
        <Route element={<OpenBreakdownPage />} path="open" />
        <Route element={<ReopenBreakdownPage />} path="reopen" />
        <Route element={<BreakdownDetail />} path="breakdown/:breakdownId" />
      </Route>
      <Route element={<Navigate replace to="my-requests" />} index />
    </Route>
  );
}
