import { Navigate, Route } from 'react-router-dom';
import { RouteGuard } from 'components/RouteGuards/AuthGuard';
import { SuspenseOutlet, delayedLazy } from '../helpers';
import { USER_CREATE, USER_EDIT, USER_INFORMATION } from 'routes/constant-route';

const Departments = delayedLazy(() => import('views/UserManagement/Departments/Departments'));
const Users = delayedLazy(() => import('views/UserManagement/Users/Users'));
/* TODO: file & folder structure must be something like: InformationModal => InformationPage  */
const UserInformation = delayedLazy(
  () => import('views/UserManagement/Users/UserModal/EditModal/EditPage')
);
const UserCreate = delayedLazy(() => import('views/UserManagement/Users/UserCreate/UserCreate'));
const UserEditV2 = delayedLazy(() => import('views/UserManagement/Users/UserEdit/UserEdit'));

export default function renderUserRoutes() {
  return (
    <Route
      element={
        <RouteGuard authKey="userManagement">
          <SuspenseOutlet />
        </RouteGuard>
      }
      path="user"
    >
      <Route
        element={
          <RouteGuard products={['asset']}>
            <Departments />
          </RouteGuard>
        }
        path="departments"
      />
      <Route element={<Users />} path="users">
        <Route element={<UserInformation />} path={USER_INFORMATION} />
      </Route>
      <Route
        element={
          <RouteGuard>
            <UserCreate />
          </RouteGuard>
        }
        path={USER_CREATE}
      />
      <Route
        element={
          <RouteGuard>
            <UserEditV2 />
          </RouteGuard>
        }
        path={USER_EDIT}
      />
      <Route element={<Navigate replace to="users" />} index />
    </Route>
  );
}
