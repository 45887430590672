import { CatIconButton, CatMenu, CatMenuItem, CatRadio, CatTypography } from '@borda/cat-ui';
import { CatTrans, useCatTranslation } from 'utils/localization';
import { FilterIcon } from '@borda/cat-icons';
import { bindMenu, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import { clearChecked } from 'store/slices/notifications/list/slice';
import { getNotificationDisplayCount } from '../queries';
import {
  isReadUpdated,
  selectNotificationFilterIsRead
} from 'store/slices/notifications/filter/notificationFilter';
import { useTypedDispatch, useTypedSelector } from 'hooks';

type Props = {
  total: number;
};

function Filter(props: Props) {
  const { total } = props;

  const { t } = useCatTranslation();
  const dispatch = useTypedDispatch();

  const popupState = usePopupState({ popupId: 'filterMenu', variant: 'popover' });
  const isFilterRead = useTypedSelector(selectNotificationFilterIsRead);

  const handleShowReadChecked = (isRead: boolean) => {
    dispatch(clearChecked());
    dispatch(isReadUpdated(isRead));
    popupState.close();
  };

  const getFilterValueText = () => {
    if (isFilterRead === null) {
      return t('notifications.show_all_menu_item');
    }
    if (isFilterRead === true) {
      return t('notifications.show_read_menu_item');
    }
    return t('notifications.show_unread_menu_item');
  };

  const notificatioNDisplayCount = getNotificationDisplayCount(total);

  return (
    <>
      <div className="flex items-center gap-2">
        <CatIconButton {...bindTrigger(popupState)}>
          <FilterIcon color="blue" />
        </CatIconButton>
        <CatTypography variant="body1">
          <CatTrans
            i18nKey="notifications.filter_label"
            t={t}
            values={{
              filterValueText: getFilterValueText(),
              notificationCount: notificatioNDisplayCount
            }}
          />
        </CatTypography>
      </div>
      <CatMenu
        {...bindMenu(popupState)}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        className=" w-[250px] py-2"
        transformOrigin={{
          horizontal: 'left',
          vertical: 'top'
        }}
      >
        <CatMenuItem onClick={() => handleShowReadChecked(null)} selected={isFilterRead === null}>
          <CatRadio checked={isFilterRead === null} zeroPadding />
          <CatTypography variant="body2">{t('notifications.show_all_menu_item')}</CatTypography>
        </CatMenuItem>
        <CatMenuItem onClick={() => handleShowReadChecked(true)} selected={isFilterRead === true}>
          <CatRadio checked={isFilterRead === true} zeroPadding />
          <CatTypography variant="body2">{t('notifications.show_read_menu_item')}</CatTypography>
        </CatMenuItem>
        <CatMenuItem onClick={() => handleShowReadChecked(false)} selected={isFilterRead === false}>
          <CatRadio checked={isFilterRead === false} zeroPadding />
          <CatTypography variant="body2">{t('notifications.show_unread_menu_item')}</CatTypography>
        </CatMenuItem>
      </CatMenu>
    </>
  );
}

export default Filter;
