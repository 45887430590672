import { Navigate, Route } from 'react-router-dom';
import { RouteGuard } from 'components/RouteGuards/AuthGuard';
import { SuspenseOutlet, delayedLazy } from '../helpers';

const Showcase = delayedLazy(() => import('views/DesignSystem/DesignSystem'));
const TurboardReportTester = delayedLazy(() => import('views/DesignSystem/TurboardReportTester'));

export default function renderDesignSystemRoutes() {
  return (
    <Route
      element={
        <RouteGuard development>
          <SuspenseOutlet />
        </RouteGuard>
      }
      path="design-system"
    >
      <Route element={<Showcase />} path="showcase" />
      <Route element={<TurboardReportTester />} path="turboard" />
      <Route element={<Navigate replace to="showcase" />} index />
    </Route>
  );
}
