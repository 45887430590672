import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'RootTypes';

export interface TrackableDeviceFilter {
  branchIds?: string[];
  macAddress?: string;
}

export const defaultFilter: TrackableDeviceFilter = {
  branchIds: [],
  macAddress: null
};

const trackableDeviceFilterSlice = createSlice({
  initialState: defaultFilter,
  name: 'trackableDevicesFilter',
  reducers: {
    filterCleared: (draft) => {
      draft.macAddress = defaultFilter.macAddress;
    },
    macAddressFilterChanged: (draft, action: PayloadAction<string>) => {
      draft.macAddress = action.payload;
    }
  }
});

export const selectTrackableDevicesFilter = (state: RootState) => state.tagConfiguration.filter;

export const { filterCleared, macAddressFilterChanged } = trackableDeviceFilterSlice.actions;
export default trackableDeviceFilterSlice.reducer;
