/* eslint-disable no-console */
import { captureException } from '@sentry/react';
import { useErrorBoundary } from 'react-error-boundary';
import ErrorPage from 'views/Errors/Error500';
import NewVersionPage from 'views/Errors/NewVersionAvailable';
import React, { useCallback } from 'react';

type MyProps = { children: React.ReactNode };
type MyState = {
  error?: any;
  hasError: boolean;
};

const isNewDeploymentError = (error: Error) => {
  if (
    error?.name === 'TypeError' &&
    error?.message?.startsWith('Failed to fetch dynamically imported module')
  ) {
    return true;
  }

  return false;
};

class ErrorBoundary extends React.Component<MyProps, MyState> {
  constructor(props: MyProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    return { error, hasError: true };
  }

  componentDidCatch(error: Error, info: any) {
    logError(error, info);
  }

  render() {
    const { error, hasError } = this.state;
    const { children } = this.props;

    if (hasError && isNewDeploymentError(error)) {
      return <NewVersionPage />;
    }

    if (hasError) {
      return (
        <ErrorPage
          onRedirect={() => {
            this.setState({ hasError: false });
          }}
        />
      );
    }

    return children;
  }
}

export const logError = (error: Error, info: any) => {
  console.group('Error');
  console.log(error);
  console.log(info);
  console.groupEnd();
  if (!isNewDeploymentError(error)) {
    captureException(error, (scope) => {
      scope.setContext('errorInfo', info);
      scope.setTag('source', 'boundary');

      return scope;
    });
  }
};

export const useShowErrorBoundary = () => {
  const { showBoundary } = useErrorBoundary();
  const errorBoundaryWrapper = useCallback(
    <T,>(caller: () => T) => {
      try {
        const result = caller();
        return result;
      } catch (error) {
        showBoundary(error);
        throw error;
      }
    },
    [showBoundary]
  );

  return { errorBoundaryWrapper };
};

export default ErrorBoundary;
