import { CAMERA_LIST, getColumnsKey } from 'helpers/localStorage/storageConstant';
import { Camera } from './types';
import { RootState } from 'RootTypes';
import { createListAdapter } from '@borda/cat-core';
import { createSlice } from '@reduxjs/toolkit';
import { initialColumnDynamicData } from './data';

const listAdapter = createListAdapter<Camera>({
  initialDynamicListDefinitions: initialColumnDynamicData,
  localStorageKey: getColumnsKey(CAMERA_LIST)
});

export const cameraListSlice = createSlice({
  initialState: listAdapter.getInitialState({}),
  name: 'cameraList',
  reducers: {
    ...listAdapter.getReducers()
  }
});

export const { selectListState } = listAdapter.getSelectors<RootState>(
  (state) => state.locations.cameras.list
);
export const selectCameraListSorts = (state: RootState) => state.locations.cameras.list.sorts;

export default cameraListSlice.reducer;
export const cameraListActions = cameraListSlice.actions;
