import { ColumnDynamicData, ColumnDynamicDataType, ColumnStaticData } from '@borda/cat-core';
import { useMemo } from 'react';

export const useRequestColumns = <T>(
  columns: ColumnDynamicData<T>[],
  staticColumns: ColumnStaticData<T>[]
) =>
  useMemo(() => {
    const commonColumnNames: string[] = [];
    const customColumnNames: Record<ColumnDynamicDataType, string[]> = {
      attribute: [],
      formField: []
    };

    const commonKeys = staticColumns.map((i) => i.column as string);

    columns.forEach((col) => {
      const column = col.column as string;

      if (!col.visible) {
        return;
      }

      if (commonKeys.includes(column)) {
        commonColumnNames.push(column);
      } else {
        customColumnNames[col.type].push(column);
      }
    });

    return { commonColumnNames, customColumnNames };
  }, [columns, staticColumns]);
