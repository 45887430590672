import { CatTrans, useCatTranslation } from 'utils/localization';
import { CatTypography } from '@borda/cat-ui';
import { CheckIcon } from '@borda/cat-icons';
import { selectValidAssetCount } from 'store/slices/asset/asset/import/selectors';
import { useTypedSelector } from 'hooks';
import React from 'react';

export default function ValidAsset() {
  const { t } = useCatTranslation('translation', {
    keyPrefix: 'assets.asset_import.step3.report.success'
  });
  const validAssetCount = useTypedSelector(selectValidAssetCount);

  return (
    <div className="grid justify-items-start gap-1">
      <CatTypography className="opacity-60" variant="body1">
        <CatTrans count={validAssetCount} i18nKey="title" t={t} />
      </CatTypography>
      <div className="bg-green/10 border-green/20 mt-4 rounded-2xl border border-solid px-4 py-3">
        <div className="flex items-center gap-1">
          <CheckIcon color="green" contained={false} hoverable={false} />
          <CatTypography className="opacity-80" variant="subtitle1">
            <CatTrans count={validAssetCount} i18nKey="uploaded_ready" t={t} />
          </CatTypography>
        </div>
        <CatTypography className="opacity-60" variant="body1">
          <CatTrans count={validAssetCount} i18nKey="valid_asset" t={t} />
        </CatTypography>
      </div>
    </div>
  );
}
