import { combineReducers } from '@reduxjs/toolkit';
import brandsSlice from './brands/slice';
import categorySlice from './categories/slice';
import modelsSlice from './models/slice';

const categoriesReducer = combineReducers({
  brands: brandsSlice,
  categories: categorySlice,
  models: modelsSlice
});

export default categoriesReducer;
