const substatusUrls = {
  addBreakdownSubstatus: () => '/api/breakdown/breakdowntasksubstatuses',
  deleteBreakdownSubstatus: (id: string) => `/api/breakdown/breakdowntasksubstatuses/${id}`,
  getConfigurableBreakdownSubstatuses: () => '/api/breakdown/breakdowntasksubstatuses/configurable',
  getSelectableBreakdownSubstatuses: () => '/api/breakdown/breakdowntasksubstatuses/selectable',
  updateBreakdownSubstatus: (id: string) => `/api/breakdown/breakdowntasksubstatuses/${id}`
};

const typesUrls = {
  addBreakdownType: () => '/api/breakdown/breakdowntypes',
  deleteBreakdownType: (id: string) => `/api/breakdown/breakdowntypes/${id}`,
  getBreakdownTypes: () => '/api/breakdown/breakdowntypes',
  updateBreakdownType: (id: string) => `/api/breakdown/breakdowntypes/${id}`
};

const costUrls = {
  addBreakdownCost: () => '/api/breakdown/breakdowncosttypes',
  deleteBreakdownCostType: (id: string) => `/api/breakdown/breakdowncosttypes/${id}`,
  getBreakdownCostsTypes: () => '/api/breakdown/breakdowncosttypes',
  updateBreakdownCostType: (id: string) => `/api/breakdown/breakdowncosttypes/${id}`
};

const detailFilesUrls = {
  deleteBreakdownDocument: (breakdownId: string, fileId: string) =>
    `/api/breakdown/breakdowns/${breakdownId}/detail-files/${fileId}`,
  updateBreakdownDocuments: (breakdownId: string) =>
    `/api/breakdown/breakdowns/${breakdownId}/detail-files`
};

const noteUrls = {
  addBreakdownNote: (breakdownId: string) => `/api/breakdown/breakdowns/${breakdownId}/notes`,
  deleteBreakdownNote: (breakdownId: string, noteId: string) =>
    `/api/breakdown/breakdowns/${breakdownId}/notes/${noteId}`
};

const aiUrls = {
  acceptAiAnswer: (breakdownId: string, answerId: string) =>
    `/api/breakdown/breakdowns/${breakdownId}/ai-answer/${answerId}`,
  addAiAnswer: (breakdownId: string) => `/api/breakdown/breakdowns/${breakdownId}/ai-answer`,
  addAiAnswerRating: (breakdownId: string, answerId: string) =>
    `/api/breakdown/breakdowns/${breakdownId}/ai-answer/${answerId}/rate`,
  rejectAiAnswer: (breakdownId: string, answerId: string) =>
    `/api/breakdown/breakdowns/${breakdownId}/ai-answer/${answerId}`
};

const myRequestUrls = {
  deleteMyRequest: (breakdownId: string) => `/api/breakdown/breakdowns/my-request/${breakdownId}`,
  resolveMyRequest: (breakdownId: string) => `/api/breakdown/breakdowns/${breakdownId}/resolve`
};

const openUrls = {
  addAuthorizedBreakdown: () => '/api/breakdown/breakdowns/authorized',
  addUnauthorizedBreakdown: () => '/api/breakdown/breakdowns/unauthorized',
  getBreakdownUserAuthorized: (assetId: string) =>
    `/api/breakdown/breakdowns/is-user-authorized/asset/${assetId}`,
  reopenBreakdown: (taskId: string) => `/api/breakdown/breakdowns/${taskId}/reopen`
};

const fileImageUrls = {
  downloadFile: (downloadPath: string) => `/api/breakdown/breakdowns/${downloadPath}`,
  downloadImageThumbnail: (downloadPath: string) =>
    `/api/breakdown/breakdowns/${downloadPath}/thumbs`,
  uploadTemporaryFiles: () => `/api/breakdown/breakdowns/temporary-files`,
  uploadTemporaryImages: () => `/api/breakdown/breakdowns/temporary-images`
};

const userSearchUrls = {
  authorizedUsersByAssetIdBreakdown: () => `/api/breakdown/breakdowns/assignable-persons/search`,
  searchUsersByAuthorizations: () => `/api/breakdown/breakdowns/users/search/by-authorizations`
};

const detail = {
  commitBreakdown: (breakdownId: string) => `/api/breakdown/breakdowns/${breakdownId}/commit`,
  confirmBreakdown: (breakdownId: string) => `/api/breakdown/breakdowns/${breakdownId}/confirm`,
  denyBreakdown: (breakdownId: string) => `/api/breakdown/breakdowns/${breakdownId}/deny`,
  updateAssignmentByBreakdownId: (breakdownId: string) =>
    `/api/breakdown/breakdowns/${breakdownId}/assignment`,
  updateBreakdown: (breakdownId: string) => `/api/breakdown/breakdowns/${breakdownId}/type`,
  updateStatus: (breakdownId: string) => `/api/breakdown/breakdowns/${breakdownId}/status`,
  updateUsability: (breakdownId: string) => `/api/breakdown/breakdowns/${breakdownId}/usability`
};

const detailCostUrls = {
  createBreakdownCosts: (id: string) => `/api/breakdown/breakdowns/${id}/costs`,
  deleteBreakdownCost: (id: string, costId: string) =>
    `/api/breakdown/breakdowns/${id}/costs/${costId}`,
  updateBreakdownCost: (id: string, costId: string) =>
    `/api/breakdown/breakdowns/${id}/costs/${costId}`
};

export default {
  ...substatusUrls,
  ...typesUrls,
  ...noteUrls,
  ...detailFilesUrls,
  ...costUrls,
  ...aiUrls,
  ...myRequestUrls,
  ...openUrls,
  ...fileImageUrls,
  ...userSearchUrls,
  ...detail,
  ...detailCostUrls
};
