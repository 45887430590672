export const assetSearchKeys = {
  all: () => ['assetSearch'],
  modal: {
    all: () => [...assetSearchKeys.all(), 'modal'],
    byFilter: (searchText: string, includePassiveAssets: boolean) => [
      ...assetSearchKeys.modal.all(),
      searchText,
      includePassiveAssets
    ],
    identificationFields: () => [...assetSearchKeys.modal.all(), 'identificationFields']
  }
};
