import { AccessPointMatchStateShape } from './type';

export const defaultState: AccessPointMatchStateShape = {
  draft: {
    accessPoints: []
  },
  initial: {
    accessPoints: []
  },
  selectedAccessPointIds: []
};
