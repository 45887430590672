import { CatIconButton, CatTypography } from '@borda/cat-ui';
import { ExpandIcon } from '@borda/cat-icons';
import { LinearProgress } from '@borda/cat-mui';
import { dialogToggled } from 'store/slices/asset/asset/import/slice';
import {
  selectFileConfirmed,
  selectFileUploadPercentage,
  selectImportStatus
} from 'store/slices/asset/asset/import/selectors';
import { useCatTranslation } from 'utils/localization';
import { useTypedDispatch, useTypedSelector } from 'hooks';
import React from 'react';

export default function Step2MinimizeView() {
  const { t } = useCatTranslation();
  const dispatch = useTypedDispatch();
  const percentage = useTypedSelector(selectFileUploadPercentage);
  const fileConfirmed = useTypedSelector(selectFileConfirmed);
  const status = useTypedSelector(selectImportStatus);

  const minimizeTitle = React.useMemo(() => {
    if (status === 'validatedFail' && fileConfirmed) {
      return t('assets.asset_import.minimize.process_error');
    }
    if (status === 'validatedSuccess') {
      return t('assets.asset_import.minimize.process_done');
    }
    if (status === 'validatingCanceling') {
      return t('assets.asset_import.common.cancelling');
    }
    return t('assets.asset_import.minimize.process_asset');
  }, [fileConfirmed, status, t]);

  const loadingColor = React.useMemo(() => {
    if (status === 'validatedFail' && fileConfirmed) {
      return 'error';
    }
    if (status === 'validatedSuccess') {
      return 'success';
    }
    return 'primary';
  }, [fileConfirmed, status]);

  const minimizeStatus = React.useMemo(() => {
    if (status === 'validatedFail' && fileConfirmed) {
      return (
        <CatTypography className="text-red opacity-80" variant="caption">
          {t('assets.asset_import.minimize.error')}
        </CatTypography>
      );
    }
    if (status === 'validatedSuccess') {
      return (
        <CatTypography className="text-green opacity-80" variant="caption">
          {t('assets.asset_import.minimize.completed')}
        </CatTypography>
      );
    }
    return (
      <CatTypography className="opacity-80" variant="caption">
        {`% ${percentage}`}
      </CatTypography>
    );
  }, [fileConfirmed, percentage, status, t]);

  const handleOpenDialog = () => {
    dispatch(dialogToggled(true));
  };

  return (
    <div className="grid">
      <CatTypography className="text-center opacity-80" variant="caption">
        {minimizeTitle}
      </CatTypography>
      <LinearProgress
        className="my-2 h-0.5 rounded-md"
        color={loadingColor}
        value={percentage}
        variant="determinate"
      />
      <div className="relative grid grid-flow-col items-center justify-center">
        {minimizeStatus}
        <CatIconButton className="absolute right-0 opacity-80" onClick={handleOpenDialog}>
          <ExpandIcon alwaysHovered contained fontSize="small" />
        </CatIconButton>
      </div>
    </div>
  );
}
