import { combineReducers } from '@reduxjs/toolkit';
import brandsReducer from './common/brands';
import detailSlice from './detail/slice';
import filterSlice from './filter/slice';
import importSlice from './import/slice';
import listSlice from './list/slice';
import locationSlice from './locations/slice';
import modelsReducer from './common/models';

const assetsReducer = combineReducers({
  brands: brandsReducer,
  detail: detailSlice,
  filter: filterSlice,
  import: importSlice,
  list: listSlice,
  locations: locationSlice,
  models: modelsReducer
});

export default assetsReducer;
