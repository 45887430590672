import { CatPaper, CatTypography } from '@borda/cat-ui';
import { SearchResultType } from 'store/slices/search/types';
import { isObjectNullOrEmpty } from '@borda/cat-core';
import { useGetImage } from 'hooks/useGetImage';
import StatusIcon from 'views/Common/AssetStatus/StatusIcon';
import clsx from 'clsx';

type Props = {
  className?: string;
  onAssetSelect: (assetId: string) => Promise<void>;
  searchItem: SearchResultType;
};

function AssetCard(props: Props) {
  const {
    className,
    onAssetSelect,
    searchItem: {
      assetStatus,
      brandName,
      categoryName,
      id: assetId,
      matchedFieldTitle,
      matchedFieldValue,
      modelName,
      photoPath
    }
  } = props;

  const { imageLoading, imageUrl } = useGetImage(photoPath);

  const handleOnClick = async () => {
    await onAssetSelect(assetId);
  };

  return (
    <CatPaper className={clsx('w-full', className)}>
      <div
        className="grid cursor-pointer grid-flow-col gap-2"
        onClick={handleOnClick}
        style={{ gridTemplateColumns: 'auto 1fr auto', height: '88px' }}
      >
        <div className="grid justify-center pb-2 pl-2 pt-2">
          <div>
            <StatusIcon fontSize="small" statusType={assetStatus} />
          </div>
        </div>
        <div className="grid content-between gap-2 py-2">
          <div className="grid gap-[2px]">
            <CatTypography className="truncate font-bold" variant="caption">
              {matchedFieldTitle}
            </CatTypography>
            <CatTypography className="truncate" variant="caption">
              {matchedFieldValue}
            </CatTypography>
          </div>
          <CatTypography className="truncate" variant="body2">
            {categoryName}
          </CatTypography>
          <div className="grid">
            <CatTypography className="truncate opacity-80" variant="caption">
              {brandName}
            </CatTypography>
            <CatTypography className="truncate opacity-80" variant="caption">
              {modelName}
            </CatTypography>
          </div>
        </div>
        <div
          className="grid items-center rounded-2xl opacity-70"
          style={{ height: '88px', overflow: 'hidden', width: '88px' }}
        >
          {!isObjectNullOrEmpty(imageUrl) && !imageLoading && <img alt={imageUrl} src={imageUrl} />}
        </div>
      </div>
    </CatPaper>
  );
}

export default AssetCard;
