import {
  AuthKeys,
  selectAllMainCategories,
  selectIsUserAuthorized,
  selectSessionStatus,
  selectUserSettings
} from 'store/slices/session';
import { ERROR401, ERROR404 } from 'routes/constant-route';
import { LocalizationProvider, SupportedLocales } from '@borda/cat-ui';
import { Navigate } from 'react-router-dom';
import { ProductKeys, getDevelopmentFeaturesEnabled, useConfigQuery } from 'utils/settings';
import { WorkType } from 'store/slices/common/types';
import { isArrayNullOrEmpty, useCatNavigate } from '@borda/cat-core';
import { selectAllBranchIds, selectIsUserAuthorizedOnProducts } from 'store/slices/branches';
import { updateCategories } from 'store/slices/categories/slice';
import { useAuthInit } from 'hooks/app/useAuthInit';
import { useFirebaseBackgroundHelper } from 'utils/firebase';
import { useTypedDispatch, useTypedSelector } from 'hooks';
import React, { useEffect } from 'react';
import Redirecting from 'views/Auth/Redirecting';

export type AuthGuardProps = {
  authKey?: AuthKeys;
  children: React.ReactNode;
};

function AuthenticationGuard(props: AuthGuardProps) {
  const sessionStatus = useTypedSelector(selectSessionStatus);
  const { isSuccess } = useConfigQuery();

  useAuthInit(isSuccess);
  useFirebaseBackgroundHelper();

  const userSettings = useTypedSelector(selectUserSettings);

  if (!isSuccess || sessionStatus !== 'loggedIn') {
    return <Redirecting />;
  }

  return (
    <LocalizationProvider
      value={{
        language: userSettings.languageCode,
        locale: userSettings.locale as SupportedLocales,
        timeZone: userSettings.timeZone
      }}
    >
      <BranchAndMainCategoryGuard {...props} />
    </LocalizationProvider>
  );
}

export function AuthorizationGuard(props: AuthGuardProps) {
  const { authKey, children } = props;
  const isUserAuthorized = useTypedSelector((state) => selectIsUserAuthorized(state, authKey));

  const navigate = useCatNavigate();

  useEffect(() => {
    if (!isUserAuthorized) {
      navigate(ERROR401, { replace: true });
    }
  }, [navigate, isUserAuthorized]);

  if (!isUserAuthorized) {
    return null;
  }

  return <>{children}</>;
}

type RouteGuardProps = {
  authKey?: AuthKeys;
  children: React.ReactNode;
  development?: boolean;
  hidden?: boolean;
  products?: ProductKeys[];
  workTypes?: WorkType[];
};

export function RouteGuard(props: RouteGuardProps) {
  const { authKey, children, development, hidden, products, workTypes = [] } = props;

  const isUserAuthorized = useTypedSelector((state) => selectIsUserAuthorized(state, authKey));
  const developmentFeaturesEnabled = getDevelopmentFeaturesEnabled();
  const mainCategories = useTypedSelector(selectAllMainCategories);

  const hasUserProducts = useTypedSelector((state) =>
    selectIsUserAuthorizedOnProducts(state, products)
  );

  if (hidden) {
    return <Navigate replace to={ERROR404} />;
  }

  if (!developmentFeaturesEnabled && development) {
    return <Navigate replace to={ERROR404} />;
  }

  if (!hasUserProducts) {
    return <Navigate replace to={ERROR401} />;
  }

  if (!isUserAuthorized) {
    return <Navigate replace to={ERROR401} />;
  }

  if (
    !isArrayNullOrEmpty(workTypes) &&
    !mainCategories.some((c) => workTypes.filter((wt) => c.workTypes.includes(wt)).length > 0)
  ) {
    return <Navigate replace to={ERROR401} />;
  }

  return <>{children}</>;
}

const useInitMainCategoriesAndBranches = () => {
  const dispatch = useTypedDispatch();
  const mainCategories = useTypedSelector(selectAllMainCategories);

  // Init categories slice from session main categories
  // CategoryCheckerDialog (possibly many others) needs it
  useEffect(() => {
    dispatch(updateCategories(mainCategories));
  }, [dispatch, mainCategories]);
};

type BranchAndMainCategoryGuardProps = Omit<AuthGuardProps, 'authKey'>;
function BranchAndMainCategoryGuard(props: BranchAndMainCategoryGuardProps) {
  const { children } = props;
  const branchIds = useTypedSelector(selectAllBranchIds);

  useInitMainCategoriesAndBranches();

  return branchIds.length === 0 ? null : <>{children}</>;
}

export default AuthenticationGuard;
