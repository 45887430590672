import { Navigate, Route } from 'react-router-dom';
import { SuspenseOutlet } from 'routes/helpers';
import { lazy } from 'react';
import PublicGuard from 'components/RouteGuards/PublicGuard';
import PublicLayout from 'layouts/Public/PublicLayout';

const LinkExpired = lazy(() => import('views/Public/Invitation/LinkExpired'));
const RegisterFirstPage = lazy(() => import('views/Public/Invitation/RegisterFirstPage'));
const RegisterSecondPage = lazy(() => import('views/Public/Invitation/RegisterSecondPage'));

export default function renderPublicRoutes() {
  return (
    <Route
      element={
        <PublicGuard>
          <SuspenseOutlet />
        </PublicGuard>
      }
      path="public"
    >
      <Route
        element={
          <PublicLayout>
            <LinkExpired />
          </PublicLayout>
        }
        path="invitation-link-expired"
      />
      <Route
        element={
          <PublicLayout>
            <RegisterFirstPage />
          </PublicLayout>
        }
        path="user-register-landing"
      />
      <Route element={<RegisterSecondPage />} path="user-register" />
      <Route element={<Navigate to="/errors/error-404" />} index />
    </Route>
  );
}
