import { AlertPanelFilter } from './types';
import { CatPopover } from '@borda/cat-ui';
import { FormProvider, useForm } from 'react-hook-form';
import { PopupState, bindPopover } from 'material-ui-popup-state/hooks';
import { alertPanelFilterSchema } from './schema';
import { defaultAlertPanelFilter } from './data';
import { yupResolver } from '@hookform/resolvers/yup';
import PanelContent from './PanelContent/PanelContent';
import PanelHeader from './PanelHeader/PanelHeader';

type Props = {
  open: boolean;
  popupState: PopupState;
};

function AlertPanel(props: Props) {
  const { open, popupState } = props;

  const handleClose = () => {
    popupState.close();
  };

  const formMethods = useForm<AlertPanelFilter>({
    defaultValues: defaultAlertPanelFilter,
    mode: 'all',
    resolver: yupResolver(alertPanelFilterSchema)
  });

  return (
    <CatPopover
      {...bindPopover(popupState)}
      anchorOrigin={{
        horizontal: 'right',
        vertical: 'bottom'
      }}
      className="mt-2"
      marginThreshold={8}
      open={open}
      transformOrigin={{
        horizontal: 'right',
        vertical: 'top'
      }}
      width="512px"
    >
      <div
        className="flex flex-col"
        style={{ height: 'calc(100vh - var(--page-space-notification-panel))' }}
      >
        <FormProvider {...formMethods}>
          <PanelHeader onClose={handleClose} />
          <PanelContent />
        </FormProvider>
      </div>
    </CatPopover>
  );
}

export default AlertPanel;
