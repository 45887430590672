import { ACCESSPOINTLIST, getFilterKey } from 'helpers/localStorage';
import { AccessPointStatus } from '../../common/types';
import { AppThunk, RootState } from 'RootTypes';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { loadFromLocalStorage } from '@borda/cat-core';
import { selectAllBranchIds } from 'store/slices/branches';
import { selectSessionUserId } from 'store/slices/session';
import yup from 'utils/yup/yupExtended';

export type AccessPointFilter = {
  branchId?: string;
  isConnectionLost?: boolean;
  isLocationAssigned?: boolean;
  localStorageInitialized?: boolean;
  macAddress?: string;
};

export const defaultAccessPointFilter: AccessPointFilter = {
  branchId: null,
  isConnectionLost: null,
  isLocationAssigned: null,
  localStorageInitialized: false,
  macAddress: null
};

export type AccessPointFilterState = {
  filter: AccessPointFilter;
};

const defaultState: AccessPointFilterState = {
  filter: defaultAccessPointFilter
};

export const accessPointStatuses: AccessPointStatus[] = ['assigned', 'unassigned'];

export const accessPointFilterSlice = createSlice({
  initialState: defaultState,
  name: 'accessPointFilter',
  reducers: {
    accessPointBranchIdChanged: (draft, action: PayloadAction<string>) => {
      draft.filter.branchId = action.payload;
    },
    accessPointFilterToggled: (draft, action: PayloadAction<boolean>) => {
      const isLocationAssigned = action.payload;

      if (draft.filter.isLocationAssigned === isLocationAssigned) {
        draft.filter.isLocationAssigned = null;
      } else {
        draft.filter.isLocationAssigned = isLocationAssigned;
      }
    },
    localStorageInitialized: (draft, action: PayloadAction<AccessPointFilter>) => {
      const accessPointFilter = action.payload;
      const { branchId, isLocationAssigned } = accessPointFilter;

      draft.filter.branchId = branchId;
      draft.filter.isLocationAssigned = isLocationAssigned;
      draft.filter.localStorageInitialized = true;
    },
    localStorageStatusReset: (draft) => {
      draft.filter.localStorageInitialized = false;
    }
  }
});

export const loadAccessPointFilterFromLocalStorage =
  (): AppThunk<void> => async (dispatch, getState) => {
    const state = getState();
    const branchList = selectAllBranchIds(state) as string[];
    const userId = selectSessionUserId(state);

    const accessPointFilterStorage = loadFromLocalStorage(getFilterKey(ACCESSPOINTLIST), userId);

    const isLocationAssignedSchema = yup.boolean().nullable();
    const branchIdSchema = yup.string().oneOf(branchList);

    let { branchId, isLocationAssigned } = defaultAccessPointFilter;

    const branchIdValid = branchIdSchema.isValidSync(accessPointFilterStorage?.branchId);
    if (branchIdValid) {
      branchId = accessPointFilterStorage?.branchId;
    }

    const isLocationAssignedValid = isLocationAssignedSchema.isValidSync(
      accessPointFilterStorage?.isLocationAssigned
    );
    if (isLocationAssignedValid) {
      isLocationAssigned = accessPointFilterStorage?.isLocationAssigned;
    }

    dispatch(
      localStorageInitialized({
        ...accessPointFilterStorage,
        branchId,
        isLocationAssigned
      })
    );
  };

export const selectAccessPointFilter = (state: RootState) =>
  state.locations.accessPoints.filter.filter;

export const selectAccessPointFilterBranchId = (state: RootState) =>
  state.locations.accessPoints.filter.filter?.branchId;

export const {
  accessPointBranchIdChanged,
  accessPointFilterToggled,
  localStorageInitialized,
  localStorageStatusReset
} = accessPointFilterSlice.actions;

export default accessPointFilterSlice.reducer;
