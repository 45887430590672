import { ExportType, MyExportsFilter, exportTypes } from './types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'RootTypes';

export const defaultFilter: MyExportsFilter = {
  exportTypes: [...exportTypes]
};

const myExportsFilterSlice = createSlice({
  initialState: defaultFilter,
  name: 'myExportsFilter',
  reducers: {
    filterCleared: (draft) => {
      draft.exportTypes = defaultFilter.exportTypes;
    },
    typeFilterChanged: (draft, action: PayloadAction<ExportType[]>) => {
      draft.exportTypes = action.payload;
    }
  }
});

export const selectMyExportsFilter = (state: RootState) => state.profileSettings.myExports.filter;

export const { filterCleared, typeFilterChanged } = myExportsFilterSlice.actions;
export default myExportsFilterSlice.reducer;
