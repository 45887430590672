import { ImportSteps, Step2Stages } from './types';
import { RootState } from 'RootTypes';

export const selectDialogOpen = (state: RootState) => state.assets.assets.import.dialogOpen;

export const selectActiveStep = (state: RootState): ImportSteps => {
  const importState = state.assets.assets.import;
  if (!importState.step1.templateDownloaded && !importState.status) {
    return 'step1';
  }
  if (
    !importState.step2.fileConfirmed &&
    importState.status !== 'imported' &&
    importState.status !== 'importedCanceled'
  ) {
    return 'step2';
  }
  if (
    !importState.step3.uploadStarted &&
    importState.status !== 'imported' &&
    importState.status !== 'importedCanceled'
  ) {
    return 'step3';
  }
  return 'step4';
};

export const selectStep2ActiveStage = (state: RootState): Step2Stages => {
  const importState = state.assets.assets.import;
  if (importState.step2.schemaErrors?.length > 0) {
    return 'fileUploadedError';
  }
  if (importState.status === 'validating' || importState.status === 'validatingCanceling') {
    return 'processing';
  }
  if (importState.status === 'validatedSuccess' || importState.status === 'validatedFail') {
    return 'fileUploadedSuccess';
  }
  return 'beforeFileUpload';
};

// STEP 1
export const selectBranchId = (state: RootState) =>
  state.assets.assets.import.step1.selectedBranchId;

export const selectMainCategoryId = (state: RootState) =>
  state.assets.assets.import.step1.selectedMainCategoryId;

// STEP 2
export const selectFileInfo = (state: RootState) => state.assets.assets.import.step2.fileInfo;

export const selectSchemaErrors = (state: RootState) =>
  state.assets.assets.import.step2.schemaErrors;

export const selectFileConfirmed = (state: RootState) =>
  state.assets.assets.import.step2.fileConfirmed;

export const selectFileUploadPercentage = (state: RootState) =>
  state.assets.assets.import.step2.percentage;

// STEP 3
export const selectInvalidAssetCount = (state: RootState) =>
  state.assets.assets.import.step3.errorCount;

export const selectValidAssetCount = (state: RootState) =>
  state.assets.assets.import.step3.successCount;

export const selectAssetUploadStarted = (state: RootState) =>
  state.assets.assets.import.step3.uploadStarted;

// STEP 4
export const selectErrorAssetCount = (state: RootState) =>
  state.assets.assets.import.step4.errorCount;

export const selectSuccessAssetCount = (state: RootState) =>
  state.assets.assets.import.step4.successCount;

export const selectAssetUploadPercentage = (state: RootState) =>
  state.assets.assets.import.step4.percentage;

// REST
export const selectImportStatus = (state: RootState) => state.assets.assets.import.status;
