import { CatIconButton, CatTypography } from '@borda/cat-ui';
import { CatTrans, useCatTranslation } from 'utils/localization';
import { DownloadIcon, InfoCautionIcon } from '@borda/cat-icons';
import { getImportedErrorFile } from 'store/slices/asset/asset/import/actions';
import { selectErrorAssetCount } from 'store/slices/asset/asset/import/selectors';
import { selectUserLanguage } from 'store/slices/session';
import { useLoading, useTypedSelector } from 'hooks';
import React from 'react';
import saveAs from 'file-saver';

export default function ErrorCard() {
  const { t } = useCatTranslation('translation', {
    keyPrefix: 'assets.asset_import.step4.result.error'
  });
  const [loading, dispatch] = useLoading<string>();
  const errorAssetCount = useTypedSelector(selectErrorAssetCount);
  const userLanguage = useTypedSelector(selectUserLanguage);

  const handleDownloadFile = async () => {
    const url = await dispatch(getImportedErrorFile());
    await saveAs(url, `asset_template_import_errors_${userLanguage}.xlsx`);
  };

  return (
    <div
      className="border-red/30 mt-4 grid justify-self-start overflow-hidden rounded-2xl border border-solid"
      style={{ minWidth: '300px' }}
    >
      <div className="bg-red/10 flex items-center justify-items-center gap-2 px-4 py-2">
        <InfoCautionIcon className="opacity-40" color="red" contained={false} hoverable={false} />
        <CatTypography className="text-red/80 text-[15px] font-bold" variant="body1">
          <CatTrans count={errorAssetCount} i18nKey="card_title" t={t} />
        </CatTypography>
      </div>
      <div
        className="bg-red/20 grid cursor-pointer grid-flow-col items-center justify-end gap-1 p-4"
        onClick={handleDownloadFile}
      >
        <CatTypography className="opacity-60" variant="body2">
          {t('download')}
        </CatTypography>
        <CatIconButton loading={loading}>
          <DownloadIcon />
        </CatIconButton>
      </div>
    </div>
  );
}
