import { AppThunk, RootState } from 'RootTypes';
import { CostType } from '../common/types';
import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';

type ProtectionCostStateShape = {
  expandedProtectionCostIds: string[];
};
export const initialState: ProtectionCostStateShape = {
  expandedProtectionCostIds: [null]
};

const slice = createSlice({
  initialState,
  name: 'protectionCosts',
  reducers: {
    clearExpandedCostIds: (draft) => {
      draft.expandedProtectionCostIds = [null];
    },
    collapseCostType: (draft) => {
      draft.expandedProtectionCostIds.pop();
    },
    expandCostType: (draft, action: PayloadAction<CostType>) => {
      if (draft.expandedProtectionCostIds.length > 1) {
        draft.expandedProtectionCostIds.pop();
      }
      draft.expandedProtectionCostIds.push(action.payload.id);
    }
  }
});

export const expandCostType =
  (costType: CostType, expand: boolean): AppThunk =>
  async (dispatch) => {
    if (expand) {
      dispatch(slice.actions.expandCostType(costType));
    } else {
      dispatch(slice.actions.collapseCostType());
    }
  };

export const selectExpandedCostTypeIds = (state: RootState) =>
  state.taskConfiguration.protection.protectionCosts.expandedProtectionCostIds;

export const selectCostTypeIsExpanded = createSelector(
  selectExpandedCostTypeIds,
  (state: RootState, id: string) => id,
  (expandedIds, id) => expandedIds.includes(id)
);

export const { clearExpandedCostIds } = slice.actions;

export default slice.reducer;
