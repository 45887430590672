import { ColumnSort } from '@borda/cat-core';
import { PagedRequestOptions } from 'store/common';

export interface Export {
  endDate: string;
  filePath?: string;
  id: string;
  progress: number;
  requestDate: string;
  requesterId: string;
  startDate: string;
  status: ExportProcessStatus;
  type: ExportType;
}

export interface MyExportsFilter {
  exportTypes: ExportType[];
}

export const exportTypes = ['plan', 'contract', 'asset'] as const;
export type ExportType = (typeof exportTypes)[number];

export type ExportProcessStatus = 'scheduled' | 'inProgress' | 'succeeded' | 'failed';

export type ExportRequest = PagedRequestOptions & {
  filter: MyExportsFilter;
  sorts: ColumnSort<Export>[];
};
