import { apiUrls } from 'api';
import { selectUseSocketForNotification } from 'store/slices/session';
import { showSnackbar } from 'utils/snackbar';
import { useCatNavigate } from '@borda/cat-core';
import { useMarkNotificationAsReadByIdMutation } from 'views/Notifications/queries';
import { useSignalR } from 'utils/signalR';
import { useTypedSelector } from 'hooks/useTypedSelector';

type SocketNotificationPayload = {
  body: string;
  data: Record<string, string>;
  title: string;
  webLink: string;
};

export const useInitializeNotificationSocket = () => {
  const useSocketForNotification = useTypedSelector(selectUseSocketForNotification);

  const navigate = useCatNavigate();

  const { mutateAsync: markNotificationAsRead } = useMarkNotificationAsReadByIdMutation();

  const handleDataReceived = async (payload: SocketNotificationPayload) => {
    const onClick = () => {
      const { pathname, search } = new URL(`${payload?.webLink}`);

      const targetUrl = pathname + search;

      const { notificationId } = payload.data ?? {};

      if (notificationId) {
        markNotificationAsRead(notificationId);
      }

      navigate(targetUrl);
    };

    showSnackbar({
      message: payload?.body,
      onClick: payload?.webLink ? onClick : undefined,
      title: payload.title,
      type: 'success'
    });

    if (payload.data.AutoClickAction === 'True') {
      onClick();
    }
  };

  useSignalR<SocketNotificationPayload>(
    {
      connectionUrl: apiUrls.notification.connectNotificationSocket(),
      disabled: !useSocketForNotification,
      eventName: 'NotificationReceived'
    },
    {
      onDataReceived: handleDataReceived
    }
  );
};
