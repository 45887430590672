import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'RootTypes';

export interface NotificationFilter {
  isRead: boolean | null;
}

export const defaultNotificationFilter: NotificationFilter = {
  isRead: false
};

export const notificationFilterSlice = createSlice({
  initialState: defaultNotificationFilter,
  name: 'notificationFilter',
  reducers: {
    filterCleared: (draft) => {
      draft.isRead = defaultNotificationFilter.isRead;
    },
    isReadUpdated: (draft, action: PayloadAction<boolean>) => {
      const isRead = action.payload;
      draft.isRead = isRead;
    }
  }
});

export const selectNotificationFilter = (state: RootState) => state.notifications.filter;
export const selectNotificationFilterIsRead = (state: RootState) =>
  state.notifications.filter.isRead;

export const { filterCleared, isReadUpdated } = notificationFilterSlice.actions;

export default notificationFilterSlice.reducer;
