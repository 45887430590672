import { Navigate, Outlet, Route } from 'react-router-dom';
import { PLAN_LIST } from 'routes/constant-route';
import { RouteGuard } from 'components/RouteGuards/AuthGuard';
import { SuspenseOutlet, delayedLazy } from '../helpers';

const ProtectionCalendar = delayedLazy(
  () => import('views/Plans/ProtectionCalendar/ProtectionCalendar/ProtectionCalendar')
);

const PlanEdit = delayedLazy(() => import('views/Plans/Plans/PlanEditDialog'));
const PlanList = delayedLazy(() => import('views/Plans/Plans/List/Plans'));
const CreateGeneralInformation = delayedLazy(
  () => import('views/Plans/Plans/Modify/GeneralInformation/CreateGeneralInformation')
);
const UpdateGeneralInformation = delayedLazy(
  () => import('views/Plans/Plans/Modify/GeneralInformation/UpdateGeneralInformation')
);
const ResponsibleNotification = delayedLazy(
  () => import('views/Plans/Plans/Modify/ResponsibleNotification/ResponsibleNotification')
);
const Summary = delayedLazy(() => import('views/Plans/Plans/Modify/Summary/Summary'));

export default function renderPlansRoutes() {
  return (
    <Route
      element={
        <RouteGuard authKey="plan" products={['asset']}>
          <SuspenseOutlet />
        </RouteGuard>
      }
      path="plan"
    >
      <Route element={<SuspenseOutlet />} path="plans">
        <Route element={<PlanList />} path={PLAN_LIST}>
          <Route element={<PlanEdit />} path=":id" />
        </Route>
        <Route element={<Outlet />} path="add">
          <Route element={<CreateGeneralInformation />} path="generalInformation" />
          <Route
            element={<UpdateGeneralInformation mode="add" />}
            path=":planId/generalInformation"
          />
          <Route
            element={<ResponsibleNotification mode="add" />}
            path=":planId/responsibleNotification"
          />
          <Route element={<Summary mode="add" />} path=":planId/summary" />
        </Route>
        <Route element={<Outlet />} path="edit">
          <Route
            element={<UpdateGeneralInformation mode="edit" />}
            path=":planId/generalInformation"
          />
          <Route
            element={<ResponsibleNotification mode="edit" />}
            path=":planId/responsibleNotification"
          />
          <Route element={<Summary mode="edit" />} path=":planId/summary" />
        </Route>
        <Route element={<Navigate to="list" />} index />
      </Route>
      <Route element={<ProtectionCalendar />} path="protection-calendar" />
      <Route element={<Navigate replace to="protection-calendar" />} index />
    </Route>
  );
}
