import { CatDialog, CatDialogTitle } from '@borda/cat-ui';
import { Fade } from '@borda/cat-mui';
import { PlusIcon } from '@borda/cat-icons';
import { SwitchTransition } from 'react-transition-group';
import { selectActiveStep, selectDialogOpen } from 'store/slices/asset/asset/import/selectors';
import { useCatTranslation } from 'utils/localization';
import { useTypedSelector } from 'hooks';
import React from 'react';
import Step1 from './Step1';
import Step2 from './Step2/Step2';
import Step3 from './Step3/Step3';
import Step4 from './Step4/Step4';

function AssetImportDialog() {
  const { t } = useCatTranslation();

  const isOpen = useTypedSelector(selectDialogOpen);
  const activeStep = useTypedSelector(selectActiveStep);

  return (
    <CatDialog open={isOpen} size="large">
      <CatDialogTitle iconComponent={PlusIcon} title={t('assets.asset_import.title')} />
      <div className="px-6">
        <SwitchTransition>
          <Fade key={activeStep} timeout={300}>
            <span>
              {activeStep === 'step1' && <Step1 />}
              {activeStep === 'step2' && <Step2 />}
              {activeStep === 'step3' && <Step3 />}
              {activeStep === 'step4' && <Step4 />}
            </span>
          </Fade>
        </SwitchTransition>
      </div>
    </CatDialog>
  );
}

export default AssetImportDialog;
