import { CatTypography } from '@borda/cat-ui';
import { DownloadIcon } from '@borda/cat-icons';
import { selectBranchId, selectMainCategoryId } from 'store/slices/asset/asset/import/selectors';
import { selectBranchNameById } from 'store/slices/branches';
import { selectMainCategoryById } from 'store/slices/session';
import { useCatTranslation } from 'utils/localization';
import { useTypedSelector } from 'hooks';
import React from 'react';

function Summary() {
  const { t } = useCatTranslation();

  const branchId = useTypedSelector(selectBranchId);
  const mainCategoryId = useTypedSelector(selectMainCategoryId);

  const branchName = useTypedSelector((state) => selectBranchNameById(state, branchId));
  const mainCategoryName = useTypedSelector((state) =>
    selectMainCategoryById(state, mainCategoryId)
  )?.name;

  return (
    <div className="flex gap-4 opacity-20">
      <DownloadIcon className="opacity-80" />
      <div className="grid w-full gap-6">
        <CatTypography className="opacity-80" variant="body1">
          {t('assets.asset_import.step2.summary.title')}
        </CatTypography>
        <div className="flex gap-4">
          <div className="flex gap-2">
            <CatTypography variant="body1">
              {t('assets.asset_import.step2.summary.branch_field')}
            </CatTypography>
            <CatTypography variant="subtitle1">{branchName}</CatTypography>
          </div>
          <div className="divider-vertical" />
          <div className="flex gap-2">
            <CatTypography variant="body1">
              {t('assets.asset_import.step2.summary.main_category_field')}
            </CatTypography>
            <CatTypography variant="subtitle1">{mainCategoryName}</CatTypography>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Summary;
