import { Key, TFunction } from './common';
import { KeyPrefixesWithKeys } from './types/trans-keys-with-prefixes';
import { Trans as TransOriginal, TransProps } from 'react-i18next';
import { memo } from 'react';
import { unescape } from 'lodash';

export type TransXProps<KPrefix extends keyof KeyPrefixesWithKeys = undefined> = Omit<
  TransProps<string>,
  't' | 'i18nKey' | 'context'
> & { context?: string, i18nKey?: Key<KPrefix>; t?: TFunction<KPrefix>; };

const getUnescapeChildrenRef: React.LegacyRef<HTMLSpanElement> = (ref) => {
  if (!ref) {
    return undefined;
  }

  return Array.from(ref.childNodes).forEach((node: ChildNode) => {
    if (node.textContent) {
      // eslint-disable-next-line no-param-reassign
      node.textContent = unescape(node.textContent);
    }
  });
};

function CatTransComponent<KPrefix extends keyof KeyPrefixesWithKeys = undefined>(
  props: TransXProps<KPrefix> & { unescape?: boolean }
) {
  const { t, unescape = false, ...rest } = props;
  let shouldUnescape = false;
  if (unescape) {
    const { values } = props;
    const hasValue = Object.values(values).some(
      (value: any) => typeof value === 'string' && value?.includes('<')
    );

    shouldUnescape = hasValue;
  }

  if (shouldUnescape) {
    return (
      <span ref={getUnescapeChildrenRef}>
        <TransOriginal {...rest} t={t as any} tOptions={{ interpolation: { escapeValue: true } }} />
      </span>
    );
  }

  return <TransOriginal {...rest} t={t as any} />;
}

export const CatTrans = memo(CatTransComponent) as typeof CatTransComponent;
