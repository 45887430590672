import { Attribute } from 'views/AttributeConfigurations/types';
import { ColumnDynamicData, DynamicListDefinition } from '@borda/cat-core';
import { PatientListItem } from './types';
import { useMemo } from 'react';

export const initialColumnDynamicData: DynamicListDefinition<PatientListItem> = {
  columns: [
    {
      column: 'taggingStatus',
      visible: true,
      width: 150
    },
    {
      column: 'type',
      visible: true,
      width: 150
    },
    {
      column: 'protocolNo',
      visible: true,
      width: 150
    },
    {
      column: 'firstName',
      visible: true,
      width: 150
    },
    {
      column: 'lastName',
      visible: true,
      width: 150
    },
    {
      column: 'roomNumber',
      visible: true,
      width: 150
    },
    {
      column: 'safeLocations',
      visible: true,
      width: 150
    },
    {
      column: 'branchId',
      visible: false,
      width: 150
    },
    {
      column: 'unitName',
      visible: false,
      width: 150
    },
    {
      column: 'activeTag',
      visible: true,
      width: 200
    },
    {
      column: 'lastSeenTime',
      visible: true,
      width: 200
    },
    {
      column: 'specialCases',
      visible: true,
      width: 150
    }
  ],
  sorts: [{ column: 'roomNumber', direction: 'asc' }]
};

export function useGetInitialDynamicData<T>(attributes: Attribute[]) {
  const { columns, sorts } = initialColumnDynamicData;

  const customColumns: ColumnDynamicData<T>[] = useMemo(
    () =>
      (attributes ?? []).map((attribute) => ({
        column: attribute.id,
        type: 'attribute',
        visible: false,
        width: 150
      })),
    [attributes]
  );

  return {
    columns: [...columns, ...customColumns],
    sorts: [...sorts]
  };
}
