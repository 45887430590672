import { DateFields, PatientFilter } from './types';
import { MedicalUnit } from 'views/MedicalUnits/MedicalUnitItem/helpers';
import { PatientType, TaggingStatuses } from '../types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { emptyFilter, patientFilterInitialState } from './data';

const patientFilterSlice = createSlice({
  initialState: patientFilterInitialState,
  name: 'patientFilter',
  reducers: {
    addExtraFilter: (draft, action: PayloadAction<TaggingStatuses>) => {
      const status = action.payload;
      const { extraFilters } = draft;
      if (action.payload) {
        const index = extraFilters.findIndex((i) => i === status);
        if (index > -1) {
          extraFilters.splice(index, 1);
        } else {
          extraFilters.push(status);
        }
      }
    },
    filterCleared: (draft) => {
      draft.activeFilter = { ...emptyFilter };
    },
    removeBranch: (
      draft,
      action: PayloadAction<{ branch: string; medicalUnits: MedicalUnit[] }>
    ) => {
      const { branch, medicalUnits } = action.payload;
      const hasBranch = draft.activeFilter.branches.includes(branch);

      if (hasBranch) {
        const index = draft.activeFilter.branches.findIndex((item) => item === branch);

        medicalUnits.forEach((medicalUnit) => {
          if (medicalUnit.branchId === branch) {
            const unitIndex = draft.activeFilter.unit.items.findIndex(
              (unit) => unit === medicalUnit.id
            );
            draft.activeFilter.unit.items.splice(unitIndex, 1);
          }
        });

        draft.activeFilter.branches.splice(index, 1);
      }
    },
    removeDateFilter: (draft, action: PayloadAction<DateFields>) => {
      const filterDateField = action.payload;
      switch (filterDateField) {
        case 'checkInDate':
          draft.activeFilter.checkInDate = emptyFilter.checkInDate;
          break;
        case 'createdDate':
          draft.activeFilter.createdDate = emptyFilter.createdDate;
          break;
        case 'tagAssignDate':
          draft.activeFilter.tagAssignDate = emptyFilter.tagAssignDate;
          break;
        case 'tagUnassignDate':
          draft.activeFilter.tagUnassignDate = emptyFilter.tagUnassignDate;
          break;
        default:
          break;
      }
    },
    removeFirstName: (draft) => {
      draft.activeFilter.firstName = emptyFilter.firstName;
    },
    removeGender: (draft) => {
      draft.activeFilter.gender = emptyFilter.gender;
    },
    removeLastName: (draft) => {
      draft.activeFilter.lastName = emptyFilter.lastName;
    },
    removePatientType: (draft, action: PayloadAction<PatientType>) => {
      const patientType = action.payload;
      const patientTypeIndex = draft.activeFilter.types.findIndex((type) => type === patientType);
      draft.activeFilter.types.splice(patientTypeIndex, 1);
    },
    removeStaff: (draft, action: PayloadAction<{ isStaffAssignor: boolean; staffId: string }>) => {
      const { isStaffAssignor, staffId } = action.payload;

      const draftDecide = isStaffAssignor
        ? draft.activeFilter.assignedByUserIds
        : draft.activeFilter.unassignedByUserIds;

      const staffIndex = draftDecide.findIndex((staffIndex) => staffIndex === staffId);
      draftDecide.splice(staffIndex, 1);
    },
    removeUnit: (draft, action: PayloadAction<string>) => {
      const unitId = action.payload;
      const unitIndex = draft.activeFilter.unit.items.findIndex((unit) => unit === unitId);
      draft.activeFilter.unit.items.splice(unitIndex, 1);
    },
    updateActiveFilter: (draft, action: PayloadAction<PatientFilter>) => {
      draft.activeFilter = { ...action.payload };
    },
    updateExtraFilter: (draft, action: PayloadAction<TaggingStatuses[]>) => {
      draft.extraFilters = action.payload;
    }
  }
});

export default patientFilterSlice.reducer;

export const {
  addExtraFilter,
  filterCleared,
  removeBranch,
  removeDateFilter,
  removeFirstName,
  removeGender,
  removeLastName,
  removePatientType,
  removeStaff,
  removeUnit,
  updateActiveFilter,
  updateExtraFilter
} = patientFilterSlice.actions;
