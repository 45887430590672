import {
  AssetListItem,
  AssetListResponse,
  CustomFieldsWithMainCategory
} from 'store/slices/asset/asset/list/types';
import { Attribute, AttributeSearchByIdsRequest } from 'views/AttributeConfigurations/types';
import { ColumnStaticData } from '@borda/cat-core';
import {
  UseInfiniteQueryOptions,
  useInfiniteQuery,
  useQuery,
  useQueryClient
} from '@tanstack/react-query';
import { apiUrls } from 'api';
import { assetManagementKeys } from 'views/Asset/queryKeys';
import { getNextPageParam } from 'utils/react-query';
import { selectActiveFilter, selectExtraFilters } from 'store/slices/asset/asset/filter/selectors';
import {
  selectAssetListColumns,
  selectAssetListSorts
} from 'store/slices/asset/asset/list/selectors';
import { useCallback } from 'react';
import { useListResponseConverter } from './queryHelpers';
import { useRequestColumns } from 'hooks/useRequestColumns';
import { useTypedSelector } from 'hooks';
import axios from 'axios';

const { assets } = assetManagementKeys;

export const useInfiniteAssetListQuery = (
  staticColumns: ColumnStaticData<AssetListItem>[],
  options: Partial<UseInfiniteQueryOptions<AssetListResponse>> = {}
) => {
  const activeFilter = useTypedSelector(selectActiveFilter);
  const extraFilters = useTypedSelector(selectExtraFilters);
  const sorts = useTypedSelector(selectAssetListSorts);
  const columns = useTypedSelector(selectAssetListColumns);

  const selectFunction = useListResponseConverter();

  const { commonColumnNames, customColumnNames } = useRequestColumns(columns, staticColumns);
  const { attribute: attributeColumnNames, formField: formFieldColumnNames } = customColumnNames;

  const queryInfo = useInfiniteQuery({
    initialPageParam: 1,
    queryFn: async ({ pageParam, signal }) => {
      const apiBody = {
        ...activeFilter,
        attributeIds: attributeColumnNames,
        columns: commonColumnNames,
        extraFilters,
        formFields: formFieldColumnNames,
        page: pageParam,
        size: 30,
        sorts
      };

      const response = await axios.post<AssetListResponse>(
        apiUrls.listing.getAssetList(),
        apiBody,
        { signal }
      );

      return response.data;
    },
    queryKey: assets.list.byFilter(
      activeFilter,
      extraFilters,
      sorts,
      [...formFieldColumnNames, ...attributeColumnNames],
      commonColumnNames
    ),
    ...options,
    getNextPageParam,
    select: selectFunction
  });

  return queryInfo;
};

export const useDynamicColumnsQuery = () => {
  const queryInfo = useQuery({
    gcTime: Infinity,
    queryFn: async ({ signal }) => {
      const response = await axios.get<CustomFieldsWithMainCategory[]>(
        apiUrls.gateway.getAssetListCustomFields(),
        { signal }
      );
      return response.data;
    },
    queryKey: assets.dynamicColumns.all(),
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    staleTime: Infinity
  });

  return queryInfo;
};

export const useGetDynamicColumnAssetAttributesQuery = () => {
  const queryInfo = useQuery({
    queryFn: async ({ signal }) => {
      const requestBody: AttributeSearchByIdsRequest = {
        ids: [],
        objectType: 'asset'
      };
      const response = await axios.post<Attribute[]>(
        apiUrls.shared.searchAttributesByIds(),
        requestBody,
        { signal }
      );

      return response.data;
    },
    queryKey: assets.attributes.columns(),
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    staleTime: Infinity
  });

  return queryInfo;
};

export const useInvalidateAssetListQuery = () => {
  const queryClient = useQueryClient();

  const invalidate = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: assets.list.all()
    });
  }, [queryClient]);

  return invalidate;
};
