import { Navigate, Outlet, Route } from 'react-router-dom';
import { RouteGuard } from 'components/RouteGuards/AuthGuard';
import { SuspenseOutlet, delayedLazy } from '../helpers';

const MetricList = delayedLazy(
  () => import('views/MetricsConfiguration/List/MetricsConfiguration')
);
const MetricDetail = delayedLazy(() => import('views/MetricsConfiguration/Detail/Detail'));

export default function renderMetricsConfigurationRoutes() {
  return (
    <Route
      element={
        <RouteGuard authKey="metricsConfiguration" products={['asset']}>
          <SuspenseOutlet />
        </RouteGuard>
      }
      path="metrics-configuration"
    >
      <Route element={<Outlet />} path="metrics">
        <Route element={<MetricList />} index />
        <Route element={<MetricDetail />} path=":metricId" />
      </Route>
      <Route element={<Navigate replace to="metrics" />} index />
    </Route>
  );
}
