import { Asset, AssetState } from './types';
import { ContractType } from 'store/slices/contracts/common/types';

export const initialAssetForm: Asset = {
  assetFormFields: [],
  assetStatus: 'down',
  code: '',
  currencyType: 'euro',
  isCategoryAutoAssigned: false,
  isRequireCalibrationPlan: false,
  isRequireMaintenancePlan: false,
  isUserAuthorizedForEdit: true,
  notes: null,
  passiveTag: null,
  productionDate: null,
  purchasedCost: null,
  purchasedCostCurrency: 'USD',
  purchasedDate: null,
  purchasedFirm: null
};

export const initialState: AssetState = {
  assetForm: initialAssetForm,
  assetHistoryFilterTypes: ['information'],
  initialAssetForm,
  taskHistoryFilterTypes: []
};

export const historyItemsPerPage = 5;
export const maxAssetFileCount = 20;
export const maxAssociatedPlanCount = 6;
export const maxAssociatedContractCount = 8;

export const contractLimits: Record<ContractType, number> = {
  calibration: 3,
  lease: 1,
  maintenance: 3,
  warranty: 1
};
