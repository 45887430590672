import { combineReducers } from 'redux';
import protectionList from './protectionList/slice';
import protectionListFilterSlice from './protectionListFilter/protectionListFilter';

const protectionReducer = combineReducers({
  protectionList,
  protectionListFilter: protectionListFilterSlice
});

export default protectionReducer;
