import { combineReducers } from '@reduxjs/toolkit';
import breakdownReducer from './breakdown';
import protectionReducer from './protection';

const taskConfigurationReducer = combineReducers({
  breakdown: breakdownReducer,
  protection: protectionReducer
});

export default taskConfigurationReducer;
