import { NotificationListItem } from 'store/slices/notifications/list/types';
import { forwardRef } from 'react';
import { selectIsNotificationChecked } from 'store/slices/notifications/list/selector';
import { useTypedSelector } from 'hooks';
import LeftPanel from './LeftPanel';
import RightPanel from './RightPanel';
import clsx from 'clsx';

type Props = {
  notification: NotificationListItem;
  onClose: () => void;
};

function NotificationCard(props: Props, ref: React.Ref<any>) {
  const { notification, onClose } = props;

  const isChecked = useTypedSelector((state) =>
    selectIsNotificationChecked(state, notification.id)
  );

  return (
    <div
      className={clsx({
        'bg-blue/10 border-blue border-solid': isChecked,
        'bg-lightGrey border-transparent': !isChecked,
        'grid w-full grid-cols-[28px,1fr] rounded-2xl border-[0.5px] transition': true,
        'opacity-80': notification.isRead
      })}
      ref={ref}
    >
      <LeftPanel isChecked={isChecked} notification={notification} />
      <RightPanel notification={notification} onClose={onClose} />
    </div>
  );
}

export default forwardRef(NotificationCard);
