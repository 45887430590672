import { CatDialog, CatDialogContent, CatDialogTitle, CatTypography } from '@borda/cat-ui';
import { CatTrans, useCatTranslation } from 'utils/localization';
import { IdentificationFieldInfo } from 'store/slices/search/types';
import { LoadingIcon, SearchIcon } from '@borda/cat-icons';
import React from 'react';
import clsx from 'clsx';

type Props = {
  identificationFields: IdentificationFieldInfo[];
  loading?: boolean;
  onToggle: () => void;
  open?: boolean;
};

function IdentificationFieldsDialog({ identificationFields, loading, onToggle, open }: Props) {
  const { t } = useCatTranslation();
  return (
    <CatDialog className="grid content-start justify-center" open={open} size="small">
      <CatDialogTitle
        className="opacity-80"
        closable
        iconComponent={SearchIcon}
        onClose={onToggle}
        title={t('layout.content_layout.search.searchable_fields')}
      />
      <CatDialogContent className="grid max-h-[540px] min-h-[300px] gap-2">
        <LoadingIcon
          className={clsx(loading ? '' : 'hidden', 'w-full self-center justify-self-center')}
        />
        {identificationFields.map((field) => (
          <div
            className="bg-lightGrey grid min-h-[80px] gap-2 rounded-2xl p-4"
            key={field.categoryName}
          >
            <CatTypography variant="body1">
              <CatTrans
                i18nKey="layout.content_layout.search.category"
                t={t}
                values={{ categoryName: field.categoryName }}
              />
            </CatTypography>
            <ul className="ml-4 grid gap-2">
              {field.titles.map((title) => (
                <li key={title} style={{ listStyleType: 'disc' }}>
                  <CatTypography variant="body2">{title}</CatTypography>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </CatDialogContent>
    </CatDialog>
  );
}

export default IdentificationFieldsDialog;
