import { AppThunk } from 'RootTypes';
import { ClientConfiguration, Settings, User, UserAuthorizations } from './types';
import { User as OidcUser, UserProfile } from 'oidc-client-ts';
import { apiUrls } from 'api';
import {
  availableAdditionalPermissions,
  patientRoles,
  userRoles
} from '../userManagement/users/common/roleRelated';
import { branchesLoaded } from '../branches';
import { cleanupLocalStorage } from 'helpers/localStorage/management';
import { getUserManager } from 'utils/userManager';
import { registerMixpanelUser } from 'utils/mixpanel';
import { selectSessionStatus, selectSessionUser } from './selectors';
import { sessionSlice } from './slice';
import { showSaveSuccessSnackbar, showSnackbarMessage } from 'utils/snackbar';
import { sleep } from '@borda/cat-core';
import { subscribeToFirebase, unsubscribeFromFirebase } from 'utils/firebase';
import { tCat } from 'utils/localization';
import axios from 'axios';
import i18n from 'utils/i18n';

const registerUser = (user: User) => {
  registerMixpanelUser({
    firstName: user.firstName,
    id: user.id,
    lastName: user.lastName,
    role: user.isGeneralAdmin ? 'GeneralAdmin' : user.role,
    username: user.username
  });
};

const buildUser = (profile: UserProfile, userAuthorizations: UserAuthorizations) => {
  const user: User = { additionalPermissions: [], branches: [], mainCategories: [] };
  const nameSplitted = profile.name.split(' ');

  user.id = profile.user_auth_id as string;
  user.firstName = profile.given_name ?? nameSplitted.slice(0, -1).join(' ');
  user.lastName = profile.family_name ?? nameSplitted[nameSplitted.length - 1];
  user.username = profile.username as string;
  user.branches = userAuthorizations.branches;
  user.settings = userAuthorizations.settings;
  user.mainCategories = [...userAuthorizations.mainCategories].sort((a, b) =>
    a.name.localeCompare(b.name, user.settings.locale)
  );

  const { role } = profile as any;
  if (Array.isArray(role)) {
    user.isGeneralAdmin = false;
    role.forEach((roleId: string) => {
      const additionalPermission = availableAdditionalPermissions[roleId];
      if (additionalPermission) {
        user.additionalPermissions.push(additionalPermission);
        return;
      }

      const userRole = userRoles.find((i) => i.id === roleId);
      if (userRole) {
        user.role = userRole.name;
      }

      const patientRole = patientRoles.find((i) => i.id === roleId);
      if (patientRole) {
        user.patientRole = patientRole.name;
      }
    });
  } else {
    const userRole = userRoles.find((i) => i.id === role);
    const patientRole = patientRoles.find((i) => i.id === role);

    if (userRole) {
      user.role = userRole.name;
    } else if (patientRole) {
      user.patientRole = patientRole.name;
    } else {
      user.isGeneralAdmin = true;
    }
  }

  return user;
};

const { clientConfigurationLoaded, userSignedOutFromAnotherTab } = sessionSlice.actions;

export { userSignedOutFromAnotherTab };

// Check user on initialization
export const checkUser = (): AppThunk => async (dispatch, getState) => {
  // Don't fetch authorizations on page navigation (useAuthInit on AuthGuard)
  if (selectSessionStatus(getState()) !== 'inProgress') {
    return;
  }

  let oidcUser: OidcUser;
  try {
    const userManager = await getUserManager();
    oidcUser = await userManager.getUser();
  } finally {
    if (oidcUser && !oidcUser.expired) {
      const user = await dispatch(fetchUserAuthorizations(oidcUser));
      if (user) {
        dispatch(sessionSlice.actions.userLoadedFromStore(user));
        registerUser(user);
      }

      // Get Firebase Token
      dispatch(subscribeToFirebase());
      // eslint-disable-next-line no-unsafe-finally
      return;
    }

    if (!oidcUser) {
      dispatch(sessionSlice.actions.userNotFoundInStore());
    } else {
      dispatch(sessionSlice.actions.userExpired());
    }

    await dispatch(silentRefresh(false));
  }
};

export const REDIRECT_URL_KEY = 'redirectUrl';
export type RedirectState = Record<typeof REDIRECT_URL_KEY, string>;

// Login callback
export const login = (): AppThunk<Promise<string>> => async (dispatch) => {
  try {
    const userManager = await getUserManager();
    const oidcUser = await userManager.signinRedirectCallback();
    const user = await dispatch(fetchUserAuthorizations(oidcUser));
    cleanupLocalStorage();
    dispatch(sessionSlice.actions.userLogin(user));
    registerUser(user);

    // Get Firebase Token
    dispatch(subscribeToFirebase());

    const { redirectUrl } = (oidcUser.state as RedirectState) ?? {};
    return redirectUrl;
  } catch {
    showSnackbarMessage(tCat('session.login_callback_invalid'), 'error', {
      persist: true
    });

    dispatch(sessionSlice.actions.loginCallbackInvalid());
    return '/';
  }
};

// Silent refresh
export const silentRefresh =
  (showError: boolean = true, forceLogout = false): AppThunk =>
  async (dispatch) => {
    try {
      const userManager = await getUserManager();
      const oidcUser = await userManager.signinSilent();
      userManager.clearStaleState();
      const user = await dispatch(fetchUserAuthorizations(oidcUser));

      dispatch(sessionSlice.actions.silentRefresh(user));
      registerUser(user);
    } catch {
      if (showError) {
        showSnackbarMessage(tCat('session.silent_refresh_failed'), 'error', {
          persist: true
        });
      }

      if (forceLogout) {
        await dispatch(logout());
      } else {
        dispatch(sessionSlice.actions.silentRefreshFailed());
      }
    }
  };

export const logout = (): AppThunk => async (dispatch) => {
  try {
    const userManager = await getUserManager();

    // Check session exists, if not, it'll throw exception
    // It is commented since not required right now, might be in future
    // await userManager.querySessionStatus();

    await unsubscribeFromFirebase();
    await userManager.signoutRedirect();
  } catch {
    dispatch(sessionSlice.actions.logout());
  }
};

export const fetchUserAuthorizations =
  (oidcUser: OidcUser, retryIfUnauthorized = true): AppThunk<Promise<User>> =>
  async (dispatch) => {
    setAccessToken(oidcUser);
    try {
      const { data: clientConfiguration } = await axios.get<ClientConfiguration>(
        apiUrls.gateway.getClientConfiguration()
      );

      const { branches, currentUser } = clientConfiguration;

      const user = buildUser(oidcUser.profile, currentUser);
      // Change user language
      if (i18n.language !== currentUser.settings.languageCode) {
        await i18n.changeLanguage(currentUser.settings.languageCode);
      }

      dispatch(clientConfigurationLoaded(clientConfiguration));
      dispatch(branchesLoaded({ branches, userBranches: currentUser.branches }));

      return user;
    } catch (error: any) {
      // Refresh token
      const userManager = await getUserManager();
      const oidcUserRetries = await userManager.signinSilent();

      // Retry one time
      if (retryIfUnauthorized && error?.response?.status === 401) {
        return dispatch(fetchUserAuthorizations(oidcUserRetries, false));
      }

      await dispatch(logout());
      throw error;
    }
  };

export const changeUserSettings =
  (settings: Partial<Settings>): AppThunk<Promise<Settings>> =>
  async (dispatch, getState) => {
    try {
      const state = getState();
      const existingSettings = state.session.user.settings;
      const newSettings = { ...existingSettings, ...settings };

      await axios.put('/api/user/currentuser/settings', newSettings);

      // User token will change, wait for async backend events
      await sleep(2000);
      await dispatch(silentRefresh());

      showSaveSuccessSnackbar();

      return newSettings;
    } catch {
      showSnackbarMessage('Settings change fail', 'error');
      return null;
    }
  };

const setAccessToken = (user: OidcUser) => {
  axios.defaults.headers.common.Authorization = `${user.token_type} ${user.access_token}`;
};

export const fetchAccountSettings =
  (): AppThunk<Promise<Settings>> => async (dispatch, getState) => {
    const accountSettingsPromise = await axios.get<Settings>('/api/public/user/general-settings');
    const settings = accountSettingsPromise.data;

    // Set account language if there is not user
    const sessionUser = selectSessionUser(getState());
    if (!sessionUser) {
      await i18n.changeLanguage(settings.languageCode);
    }

    dispatch(sessionSlice.actions.accountSettingsSet(settings));
    return settings;
  };
