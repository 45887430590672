import { combineReducers } from '@reduxjs/toolkit';
import assetSlice from './asset';
import myAssetsSlice from './myAssets';

const assetsReducer = combineReducers({
  assets: assetSlice,
  myAssets: myAssetsSlice
});

export default assetsReducer;
