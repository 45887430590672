import { Location } from '../common/types';
import { PayloadAction, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { initialState, parentLocationIdOfRoot } from './data';

export const locationsAdapter = createEntityAdapter<Location, string>({
  selectId: (location) => location.id
});

export const locationsSlice = createSlice({
  initialState: locationsAdapter.getInitialState(initialState),
  name: 'locations',
  reducers: {
    locationCollapsed: (draft, action: PayloadAction<Location>) => {
      const location = action.payload;
      const index = draft.expandedLocationIds.findIndex((i) => i === location.parentLocationId) + 1;

      draft.expandedLocationIds.splice(index, draft.expandedLocationIds.length - index);
    },
    locationExpanded: (draft, action: PayloadAction<Location>) => {
      const location = action.payload;
      const index =
        draft.expandedLocationIds.findIndex(
          (i) => i === (location?.parentLocationId ?? parentLocationIdOfRoot)
        ) + 1;

      draft.expandedLocationIds.splice(
        index,
        draft.expandedLocationIds.length - index,
        location?.id ?? parentLocationIdOfRoot
      );
    },
    locationsPageCleared: (draft) => {
      draft.expandedLocationIds = initialState.expandedLocationIds;
    }
  }
});

export default locationsSlice.reducer;

export const { locationCollapsed, locationExpanded, locationsPageCleared } = locationsSlice.actions;
