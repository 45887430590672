import { CancelIcon } from '@borda/cat-icons';
import { CatButton, CatButtonProps } from '@borda/cat-ui';
import { useCatTranslation } from 'utils/localization';

function CancelButton(props: CatButtonProps) {
  const { t } = useCatTranslation();
  const {
    children = t('common.cancel'),
    color = 'red',
    size = 'large',
    startIcon = <CancelIcon />,
    ...rest
  } = props;
  return (
    <CatButton color={color} size={size} startIcon={startIcon} {...rest}>
      {children}
    </CatButton>
  );
}

export default CancelButton;
