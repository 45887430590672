import { CancelIcon } from '@borda/cat-icons';
import {
  CatDialog,
  CatDialogAction,
  CatDialogContent,
  CatDialogTitle,
  CatTypography
} from '@borda/cat-ui';
import { DiscardButton, GoBackButton } from 'packages/cat-wrappers';
import { useCatTranslation } from 'utils/localization';
import { withDialogWrapper } from '../hooks';
import KeyboardButton from 'packages/cat-wrappers/KeyboardButton';

type Props = {
  onAction: (() => Promise<void>) | (() => void);
  onClose: () => void;
  open: boolean;
};

function DiscardDialog(props: Props) {
  const { onAction, onClose, open } = props;
  const { t } = useCatTranslation();

  return (
    <CatDialog open={open}>
      <CatDialogTitle iconComponent={CancelIcon} title={t('common.discard_changes')} />
      <CatDialogContent>
        <CatTypography variant="body1">{t('common.discard_changes_description')}</CatTypography>
      </CatDialogContent>
      <CatDialogAction>
        <KeyboardButton component={GoBackButton} keyboardKey="Escape" onClick={onClose} />
        <KeyboardButton component={DiscardButton} keyboardKey="Enter" onClick={onAction} />
      </CatDialogAction>
    </CatDialog>
  );
}

export default withDialogWrapper(DiscardDialog);
