import { CatTypography } from '@borda/cat-ui';
import { LoadingIcon } from '@borda/cat-icons';
import React from 'react';
import clsx from 'clsx';

type Props = {
  className?: string;
  disabled?: boolean;
  endAdornment?: React.ReactNode;
  errorText?: string;
  label?: string | React.ReactNode;
  loading?: boolean;
  multiline?: boolean;
  startAdornment?: React.ReactNode;
  text?: string | React.ReactNode;
};

function ReadonlyTextField(props: Props) {
  const {
    className,
    disabled = false,
    endAdornment,
    errorText,
    label,
    loading = false,
    multiline = false,
    startAdornment,
    text
  } = props;

  return (
    <div className="grid">
      <div
        className={clsx({
          'bg-lightGrey': !disabled && !errorText,
          'bg-lightGrey/40': disabled,
          'bg-red/20': !disabled && errorText,
          [className]: true,
          'grid w-full overflow-hidden rounded-lg px-2': true,
          'h-10 content-center': !multiline,
          'h-[94px] content-start pt-2.5': multiline
        })}
      >
        <div className="flex overflow-hidden">
          <div className="flex flex-wrap items-center">{startAdornment}</div>
          <div className="grow self-center overflow-hidden">
            {label && (
              <CatTypography
                className={clsx({
                  'text-[9px] leading-[10.8px]': !!text,
                  'text-darkGrey/60 max-w-sm overflow-hidden text-ellipsis whitespace-nowrap': true
                })}
                variant="body1"
              >
                {label}
              </CatTypography>
            )}
            {!!text && (
              <div className="flex">
                <CatTypography
                  className={clsx(disabled ? 'opacity-50' : 'opacity-100')}
                  component="span"
                  noWrap={!multiline}
                  variant="body1"
                >
                  {text}
                </CatTypography>
              </div>
            )}
          </div>
          <div className="flex flex-wrap items-center gap-1 pl-1">
            {endAdornment}
            {loading && <LoadingIcon color="blue" hoverable={false} />}
          </div>
        </div>
      </div>
      <CatTypography className="text-red -mb-2 ml-1 mt-0.5" variant="caption">
        {errorText}
      </CatTypography>
    </div>
  );
}

export default ReadonlyTextField;
