import { CatTypography } from '@borda/cat-ui';
import { List, Theme, useMediaQuery } from '@borda/cat-mui';
import { NavigationGroup } from './navigations';
import { useCatTranslation } from 'utils/localization';
import NavItem from 'packages/cat-wrappers/NavItem';
import React from 'react';
import clsx from 'clsx';

type Props = {
  className?: string;
  navigation: NavigationGroup;
};

function NavbarList(props: Props) {
  const { className, navigation } = props;
  const { t } = useCatTranslation();

  const showLarge = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const subHeader = t(navigation.subheader);
  const subHeaderShort = t(navigation.subheader_short);

  return (
    <List
      className={clsx({
        className,
        'grid w-full gap-2 py-0': true,
        'justify-items-center': !showLarge
      })}
    >
      {navigation.subheaderVisible !== false && (
        <CatTypography
          className={clsx({
            'pl-0 text-center text-[8px] leading-[11.25px]': !showLarge,
            'pl-0.5 text-left text-[11px] leading-[13px]': showLarge,
            'text-darkGrey opacity-60': true
          })}
          noWrap
        >
          {showLarge ? subHeader : subHeaderShort}
        </CatTypography>
      )}
      {navigation.items.map((item) => (
        <NavItem
          disabled={item.disabled}
          href={item.href}
          icon={item.icon}
          key={item.href}
          title={t(item.title)}
        />
      ))}
    </List>
  );
}

export default NavbarList;
