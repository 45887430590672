import { CatTypography } from '@borda/cat-ui';
import { GoBackButton, UploadButton } from 'packages/cat-wrappers';
import { PlusIcon } from '@borda/cat-icons';
import { fileConfirmedUpdated } from 'store/slices/asset/asset/import/slice';
import { selectInvalidAssetCount } from 'store/slices/asset/asset/import/selectors';
import { startImportAssets } from 'store/slices/asset/asset/import/actions';
import { useCatTranslation } from 'utils/localization';
import { useLoading, useTypedDispatch, useTypedSelector } from 'hooks';
import InvalidAsset from './InvalidAsset';
import React from 'react';
import ValidAsset from './ValidAsset';

export default function Report() {
  const { t } = useCatTranslation();
  const dispatch = useTypedDispatch();
  const [addAssetLoading, addAssetDispatch] = useLoading();

  const invalidAssetCount = useTypedSelector(selectInvalidAssetCount);

  const handleGoBack = () => {
    dispatch(fileConfirmedUpdated(false));
  };

  const handleAddAssets = () => {
    addAssetDispatch(startImportAssets());
  };

  return (
    <div className="flex gap-4">
      <PlusIcon className="opacity-80" />
      <div className="grid gap-1">
        <CatTypography className="opacity-80" variant="subtitle1">
          {t('assets.asset_import.step3.report.title')}
        </CatTypography>
        {invalidAssetCount ? <InvalidAsset /> : <ValidAsset />}
        <div className="mt-4 flex items-center gap-4">
          <GoBackButton onClick={handleGoBack} />
          <div className="divider-vertical" />
          <UploadButton
            disabled={!!invalidAssetCount}
            loading={addAssetLoading}
            onClick={handleAddAssets}
          />
        </div>
      </div>
    </div>
  );
}
