import { LocalizationProvider, SupportedLocales } from '@borda/cat-ui';
import { fetchAccountSettings, selectAccountSettings } from 'store/slices/session';
import { useConfigQuery } from 'utils/settings';
import { useTypedDispatch, useTypedSelector } from 'hooks';
import React, { useEffect } from 'react';
import Redirecting from 'views/Auth/Redirecting';

export type GuardProps = {
  children: React.ReactNode;
};

function PublicGuard(props: GuardProps) {
  const { children } = props;

  const { isSuccess } = useConfigQuery();

  const accountSettings = useTypedSelector(selectAccountSettings);
  const dispatch = useTypedDispatch();

  useEffect(() => {
    dispatch(fetchAccountSettings());
  }, [dispatch]);

  if (!isSuccess || !accountSettings) {
    return <Redirecting />;
  }

  return (
    <LocalizationProvider
      value={{
        language: accountSettings?.languageCode,
        locale: accountSettings?.locale as SupportedLocales,
        timeZone: accountSettings?.timeZone
      }}
    >
      {children}
    </LocalizationProvider>
  );
}

export default PublicGuard;
