import { PatientFilter, PatientFilterStateShape } from './types';

export const emptyFilter: PatientFilter = {
  assignedByUserIds: [],
  branches: [],
  checkInDate: {
    from: null,
    to: null,
    type: 'any'
  },
  createdDate: {
    from: null,
    to: null,
    type: 'any'
  },
  firstName: null,
  gender: null,
  lastName: null,
  protocolNo: null,
  roomNumber: null,
  tagAssignDate: {
    from: null,
    to: null,
    type: 'any'
  },
  tagUnassignDate: {
    from: null,
    to: null,
    type: 'any'
  },
  taggingStatuses: [],
  types: [],
  unassignedByUserIds: [],
  unit: {
    items: []
  }
};

export const defaultFilter: PatientFilter = {
  ...emptyFilter
};

export const patientFilterInitialState: PatientFilterStateShape = {
  activeFilter: defaultFilter,
  extraFilters: ['tagAssigned', 'noTagHasBeenAssigned']
};
