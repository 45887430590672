import { Config, fetchConfigIfNecessary, getAppSettings } from './settings';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import axios from 'axios';
import i18next, { ReadCallback } from 'i18next';
import resourcesToBackend from 'i18next-resources-to-backend';

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(
    resourcesToBackend(async (language, namespace, callback) => {
      const config = await fetchConfigIfNecessary();
      const { enableLiveTranslations } = getAppSettings();

      if (!enableLiveTranslations) {
        loadTranslationFromFile(language, namespace, callback);
        return;
      }

      try {
        const json = await loadTranslationFromTraduora(language, config);

        callback(null, json);
      } catch {
        loadTranslationFromFile(language, namespace, callback);
      }
    })
  )
  .init({
    contextSeparator: '__',
    debug: import.meta.env.NODE_ENV === 'development',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false
    },
    nonExplicitSupportedLngs: true,
    pluralSeparator: '_#',
    returnEmptyString: false,
    supportedLngs: ['en', 'tr', 'ro']
  });

const loadTranslationFromFile = async (
  language: string,
  namespace: string,
  callback: ReadCallback
) => {
  import(`../translations/${language}/${namespace}.json`)
    .then((resources) => {
      callback(null, resources);
    })
    .catch((error) => {
      callback(error, null);
    });
};

const loadTranslationFromTraduora = async (language: string, config: Config) => {
  const {
    common: {
      traduora: { projectId }
    }
  } = config;

  const token = await authenticateToTraduora(config);
  const response = await axios.get<Blob>(
    `/traduora/api/v1/projects/${projectId}/exports?locale=${language}&fallbackLocale=en&format=jsonnested`,
    {
      baseURL: '',
      headers: {
        Authorization: `Bearer ${token}`
      },
      responseType: 'blob'
    }
  );

  const json = JSON.parse(await response.data.text());
  return json;
};

const authenticateToTraduora = async (config: Config) => {
  const {
    common: {
      traduora: { clientId, secret }
    }
  } = config;

  const response = await axios.post<{ access_token: string }>(
    '/traduora/api/v1/auth/token',
    {
      client_id: clientId,
      client_secret: secret,
      grant_type: 'client_credentials'
    },
    { baseURL: '' }
  );

  return response.data.access_token;
};

export default i18next;
