import { Box } from '@borda/cat-ui';
import { Divider, Link, Theme, Typography, useMediaQuery } from '@borda/cat-mui';
import { LighthouseLogoIcon } from '@borda/cat-icons';
import { appVersion, useConfigQuery } from 'utils/settings';
import React from 'react';

function BordaIcon() {
  const showLarge = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));

  const { data } = useConfigQuery();
  const deployment = data?.deployment?.toLocaleUpperCase();

  const versionText = (
    <Typography
      className="text-darkGrey opacity-40"
      noWrap
      style={{
        fontSize: '8px',
        lineHeight: '9.68px'
      }}
    >
      {`v${appVersion}`}
    </Typography>
  );

  const deploymentText = (
    <Typography
      className="font-normal"
      style={{ color: '#55959F', fontSize: showLarge ? '10px' : '7px', lineHeight: '8px' }}
    >
      {deployment}
    </Typography>
  );

  const largeIcon = (
    <div className="flex">
      <LighthouseLogoIcon />
      <div className="mt-0.5 flex flex-grow flex-col">
        {deploymentText}
        <Typography
          className="font-black opacity-60"
          style={{
            color: '#F0552C',
            fontSize: '12px',
            lineHeight: '14.52px'
          }}
        >
          LIGHTHOUSE
        </Typography>
        <Divider className="bg-darkGrey/20 mb-1" style={{ height: '0.5px' }} />
        <Box alignItems="center" flex>
          <img
            alt="Borda"
            src="/borda.png"
            style={{
              height: '10px',
              marginBottom: '4px',
              marginRight: 4,
              opacity: '0.4',
              width: '32px'
            }}
          />
          {versionText}
        </Box>
      </div>
    </div>
  );

  const smallIcon = (
    <div className="flex flex-col items-center justify-center">
      <LighthouseLogoIcon />
      <img alt="Borda" className="mb-1 mr-1 h-2.5 w-8 opacity-40" src="/borda.png" />
      {deploymentText}
      {versionText}
    </div>
  );

  return (
    <Link className="w-full pb-2" href="/" underline="none">
      {showLarge ? largeIcon : smallIcon}
    </Link>
  );
}

export default BordaIcon;
