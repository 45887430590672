import { CatIconButton } from '@borda/cat-ui';
import { CloseIcon } from '@borda/cat-icons';
import { IconButtonProps } from '@borda/cat-mui';

import React from 'react';

function CloseIconButton(props: IconButtonProps) {
  return (
    <CatIconButton {...props}>
      <CloseIcon color="darkGrey" fontSize="medium" />
    </CatIconButton>
  );
}

export default CloseIconButton;
