import { CancelButton, MinimizeButton } from 'packages/cat-wrappers';
import { CatTrans, useCatTranslation } from 'utils/localization';
import { CatTypography } from '@borda/cat-ui';
import { LinearProgress } from '@borda/cat-mui';
import { PlusIcon } from '@borda/cat-icons';
import { cancelFileProgress } from 'store/slices/asset/asset/import/actions';
import { dialogToggled } from 'store/slices/asset/asset/import/slice';
import {
  selectAssetUploadPercentage,
  selectImportStatus,
  selectValidAssetCount
} from 'store/slices/asset/asset/import/selectors';
import { useDispatch } from 'react-redux';
import { useLoading, useTypedSelector } from 'hooks';
import React from 'react';

export default function Progress() {
  const dispatch = useDispatch();
  const { t } = useCatTranslation('translation', {
    keyPrefix: 'assets.asset_import'
  });
  const validAssetCount = useTypedSelector(selectValidAssetCount);
  const percentage = useTypedSelector(selectAssetUploadPercentage);
  const status = useTypedSelector(selectImportStatus);

  const [cancelLoading, cancelLoadingDispatch] = useLoading();

  const handleCancel = () => {
    cancelLoadingDispatch(cancelFileProgress());
  };
  const handleMinimize = () => {
    dispatch(dialogToggled(false));
  };

  return (
    <div className="grid">
      <div className="flex gap-4">
        <PlusIcon className="opacity-80" />
        {status === 'importingCanceling' ? (
          <CatTypography variant="subtitle1">{t('common.cancelling')}</CatTypography>
        ) : (
          <div className="grid gap-1">
            <CatTypography className="opacity-80" variant="subtitle1">
              {t('step4.progress.title')}
            </CatTypography>
            <CatTypography className="opacity-80" variant="subtitle1">
              <CatTrans count={validAssetCount} i18nKey="step4.progress.desc" t={t} />
            </CatTypography>
          </div>
        )}
      </div>
      <div className="grid gap-2 py-5">
        <LinearProgress className="h-2 rounded-2xl" value={percentage} variant="determinate" />
        <CatTypography className="justify-self-end opacity-60" variant="h2">
          {percentage}%
        </CatTypography>
      </div>
      <div className="flex items-center gap-4 pl-8">
        <CancelButton disabled={cancelLoading} onClick={handleCancel} />
        <div className="divider-vertical" />
        <MinimizeButton onClick={handleMinimize} />
      </div>
    </div>
  );
}
