import { CatTypography } from '@borda/cat-ui';
import { CheckIcon, FileIcon } from '@borda/cat-icons';
import { selectBranchById } from 'store/slices/branches';
import {
  selectBranchId,
  selectFileInfo,
  selectMainCategoryId
} from 'store/slices/asset/asset/import/selectors';
import { selectCategoryById } from 'store/slices/categories/selectors';
import { useCatTranslation } from 'utils/localization';
import { useTypedSelector } from 'hooks';
import React from 'react';

export default function Summary() {
  const { t } = useCatTranslation();

  const branchId = useTypedSelector(selectBranchId);
  const branch = useTypedSelector((state) => selectBranchById(state, branchId));

  const mainCategoryId = useTypedSelector(selectMainCategoryId);
  const mainCategory = useTypedSelector((state) => selectCategoryById(state, mainCategoryId));

  const fileInfo = useTypedSelector(selectFileInfo);

  return (
    <div className="flex gap-4">
      <FileIcon className="opacity-80" />
      <div className="grid gap-1">
        <CatTypography className="opacity-60" variant="body2">
          {t('assets.asset_import.step3.summary.title')}
        </CatTypography>
        <CatTypography className="opacity-80" variant="body1">
          {t('assets.asset_import.step3.summary.desc')}
        </CatTypography>
        <div className="mt-2 flex items-center gap-2">
          <CatTypography className="opacity-80" variant="body1">
            {t('assets.asset_import.step3.summary.branch')}
          </CatTypography>
          <CatTypography className="opacity-80" variant="subtitle1">
            {branch?.name}
          </CatTypography>
          <div className="divider-vertical" />
          <CatTypography className="opacity-80" variant="body1">
            {t('assets.asset_import.step3.summary.main_category')}
          </CatTypography>
          <CatTypography className="opacity-80" variant="subtitle1">
            {mainCategory?.name}
          </CatTypography>
          <div className="divider-vertical" />
          <CheckIcon />
          <CatTypography className="opacity-80" variant="body1">
            {t('assets.asset_import.step3.summary.file_uploaded')}
          </CatTypography>
          <CatTypography className="opacity-80" variant="subtitle1">
            {fileInfo?.name}
          </CatTypography>
        </div>
      </div>
    </div>
  );
}
