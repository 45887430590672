import { CatAlert, CatTypography } from '@borda/cat-ui';
import { CustomContentProps, SnackbarContent, closeSnackbar } from 'notistack';
import { Divider } from '@borda/cat-mui';
import { Ref, forwardRef } from 'react';
import clsx from 'clsx';

interface Props extends CustomContentProps {
  message: string;
  onClick?: () => void;
  title?: string;
}
export const CatSnackbar = forwardRef((props: Props, ref: Ref<any>) => {
  const { className, id, message, onClick, style, title, variant } = props;
  const snackbarContentProps = { className, style };

  const handleClose = (e: any) => {
    closeSnackbar(id);
    e.stopPropagation();
  };

  const handleClick = () => {
    if (onClick) {
      onClick();
      closeSnackbar(id);
    }
  };

  return (
    <SnackbarContent ref={ref} role="alert" {...snackbarContentProps}>
      <CatAlert
        className={clsx(onClick && 'cursor-pointer', 'max-w-[500px]')}
        onClick={handleClick}
        onClose={handleClose}
        severity={variant}
      >
        <div className="grid gap-1">
          {title && <CatTypography variant="h5">{title}</CatTypography>}
          {message.split('\n').map((line) => (
            <CatTypography key={line} variant="subtitle1">
              {line}
            </CatTypography>
          ))}
        </div>
        <Divider orientation="vertical" />
      </CatAlert>
    </SnackbarContent>
  );
});

declare module 'notistack' {
  interface VariantOverrides {
    default: false;
    info: {
      onClick?: () => void;
      title?: string;
    };
  }
}
