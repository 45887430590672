import { CONTRACTLIST, getColumnsKey } from 'helpers/localStorage';
import { ContractItem } from './types';
import { createListAdapter } from '@borda/cat-core';
import { createSlice } from '@reduxjs/toolkit';
import { initialColumnDynamicData } from './data';

export const listAdapter = createListAdapter<ContractItem>({
  initialDynamicListDefinitions: initialColumnDynamicData,
  localStorageKey: getColumnsKey(CONTRACTLIST)
});

export const contractListSlice = createSlice({
  initialState: listAdapter.getInitialState({}),
  name: 'contractList',
  reducers: {
    ...listAdapter.getReducers()
  }
});

export default contractListSlice.reducer;
