import { CatButton, CatButtonProps } from '@borda/cat-ui';
import { ExportIcon } from '@borda/cat-icons';
import { useCatTranslation } from 'utils/localization';
import React from 'react';

function UploadButton(props: CatButtonProps) {
  const { t } = useCatTranslation();
  const {
    children = t('common.upload'),
    color = 'green',
    endIcon = <ExportIcon />,
    size = 'large',
    ...rest
  } = props;
  return (
    <CatButton color={color} endIcon={endIcon} size={size} {...rest}>
      {children}
    </CatButton>
  );
}

export default UploadButton;
