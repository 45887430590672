import { DisplayType } from '@borda/cat-core';

export const attributeObjects = ['asset', 'patient', 'user', 'location'] as const;
export type AttributeObject = (typeof attributeObjects)[number];

export const attributeDataTypes = ['singleSelect', 'multiSelect'] as const;
export type AttributeDataType = (typeof attributeDataTypes)[number];

export type Attribute = {
  canUserEditExisting?: boolean;
  code: string;
  dataType: AttributeDataType;
  id?: string;
  isAssigned?: boolean;
  isConfigurable?: boolean;
  lastEdittedBy?: string;
  lastEdittedDate?: string;
  lastEdittedFirstName?: string;
  lastEdittedLastName?: string;
  mode?: DisplayType;
  objectType: AttributeObject;
  options: AttributeOption[];
  title: string;
};

export type AttributeOption = {
  id: string;
  isDeleted?: boolean;
  isNew?: boolean;
  isUpdated?: boolean;
  title: string;
};

export type AttributeFilter = {
  showOnlySelected: boolean;
  title: string;
};

export type AttributeAssignment = {
  dataType: AttributeDataType;
  id: string;
  isAllAssigned: boolean;
  isHalfAssigned: boolean;
  isMultiple: boolean;
  options: AttributeOption[];
  selectedOptionIds: string[];
  title: string;
};

export type ObjectAttributeAssignments = {
  attributeAssignments: AttributeAssignment[];
};

export type ListAttributeAssignment = {
  attributeId: string;
  isAssigned: boolean;
  optionValues: string[];
};

export type AttributeMode = 'assignment' | 'selection' | 'default';

export type AttributeSearchByIdsRequest = {
  ids: string[];
  objectType: string;
};
