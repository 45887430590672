import { COMMONFILTER, getFilterKey } from './storageConstant';
import { loadFromLocalStorage, saveToLocalStorage } from '@borda/cat-core';

export const cleanupLocalStorage = () => {
  const exceptionKeys = ['filter', 'tutorial', 'settings'];
  Object.keys(localStorage).forEach((localStorageKey) => {
    const isExceptionKey = exceptionKeys.some((exceptionKey) =>
      localStorageKey.startsWith(`quattro:${exceptionKey}`)
    );

    if (isExceptionKey) {
      return;
    }

    localStorage.removeItem(localStorageKey);
  });
};

export interface CommonFilter {
  branch?: string;
  mainCategory?: string;
}

export const getCommonFilterItemFromLocalStorage = (key: keyof CommonFilter) => {
  const filter = loadFromLocalStorage(getFilterKey(COMMONFILTER)) as CommonFilter;

  return filter?.[key];
};

export const setCommonFilterItemToLocalStorage = (key: keyof CommonFilter, value: string) => {
  const filter = (loadFromLocalStorage(getFilterKey(COMMONFILTER)) as CommonFilter) ?? {};
  filter[key] = value;

  saveToLocalStorage(getFilterKey(COMMONFILTER), filter);
};
