import { CatTrans, useCatTranslation } from 'utils/localization';
import { CatTypography } from '@borda/cat-ui';
import { DeviceTimeIcon } from '@borda/cat-icons';
import { TimeItem, TimeUnitTextForm } from './types';

type Props = {
  timeItem: TimeItem;
  timeUnitForm?: TimeUnitTextForm;
};

function TimeChip(props: Props) {
  const { timeItem, timeUnitForm = 'long' } = props;

  const { t: tTime } = useCatTranslation('translation', {
    keyPrefix: 'common.time.time_by_unit'
  });

  const { type: timeUnit, value } = timeItem;

  return (
    <div
      className="border-darkGrey/10 bg-darkGrey/10 flex items-center gap-1 rounded-lg border pr-1"
      title={tTime(`long.${timeUnit}`)}
    >
      <DeviceTimeIcon />
      <CatTypography className="truncate" variant="body1">
        <CatTrans
          i18nKey={`${timeUnitForm}.${timeUnit}`}
          t={tTime}
          values={{ value: value?.toString() }}
        />
      </CatTypography>
    </div>
  );
}

export default TimeChip;
