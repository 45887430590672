import { enqueueSnackbar } from 'notistack';
import { tCat } from './localization';

export type SnackbarTypes = 'error' | 'warning' | 'info' | 'success';

export interface SnackbarState {
  message?: string;
  onClick?: () => void;
  persist?: boolean;
  title?: string;
  type: SnackbarTypes;
}

export const showSnackbar = (snackbar: SnackbarState) => {
  const { message, onClick, persist, title, type } = snackbar;

  enqueueSnackbar({
    message,
    onClick,
    persist,
    title,
    variant: type
  });
};

export const showSnackbarMessage = (
  message: string,
  type: SnackbarTypes,
  options?: Partial<SnackbarState>
) => {
  const { persist } = options ?? {};
  showSnackbar({ message, persist, type });
};

export const showAddSuccessSnackbar = () => {
  showSnackbarMessage(tCat('snackbar.add_success_message'), 'success');
};

export const showDeleteSuccessSnackbar = () => {
  showSnackbarMessage(tCat('snackbar.delete_success_message'), 'warning');
};

export const showUpdateSuccessSnackbar = () => {
  showSnackbarMessage(tCat('snackbar.update_success_message'), 'success');
};

export const showSaveSuccessSnackbar = () => {
  showSnackbarMessage(tCat('snackbar.save_success_message'), 'success');
};
