import { useCatTranslation } from 'utils/localization';
import { useEffect } from 'react';
import axios from 'axios';

const useTranslationInit = () => {
  const { i18n } = useCatTranslation();
  useEffect(() => {
    if (i18n.language) {
      axios.defaults.headers.common['Accept-Language'] = i18n.language;
    }
  }, [i18n.language]);
};

export default useTranslationInit;
