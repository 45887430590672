import {
  AdditionalPermissionType,
  PatientRoleType,
  RoleType
} from '../userManagement/users/common/roleRelated';
import { AuthKeys, authMap } from './auth';
import { RootState } from 'RootTypes';
import { User } from './types';
import { WorkType } from '../common/types';
import { createSelector } from '@reduxjs/toolkit';

export const selectSessionUserId = (state: RootState) => state.session.user?.id;
export const selectSessionStatus = (state: RootState) => state.session.status;
export const selectSessionUser = (state: RootState) => state.session.user;

export const selectUseSocketForNotification = (state: RootState) =>
  state.session.useSocketForNotification;

export const selectUserSettings = (state: RootState) => state.session.user?.settings;
export const selectUserLanguage = (state: RootState) => state.session.user?.settings?.languageCode;
export const selectUserTimeZone = (state: RootState) => state.session.user.settings.timeZone;
export const selectUserLocale = (state: RootState) => state.session.user.settings.locale;

export const selectAccountSettings = (state: RootState) => state.session.account?.settings;
export const selectAccountName = (state: RootState) => state.session.account.settings.account;

export const selectClientSettings = (state: RootState) => state.session.client;

export const selectAllMainCategories = createSelector(
  selectSessionUser,
  (user) => user?.mainCategories ?? undefined
);

export const selectMainCategoriesByWorkType = createSelector(
  selectAllMainCategories,
  (state: RootState, workType: WorkType) => workType,
  (categories, workType) => categories.filter((c) => !workType || c.workTypes.includes(workType))
);

export const selectMainCategoryById = createSelector(
  selectSessionUser,
  (state: RootState, id: string) => id,
  (user, id) => user.mainCategories.find((i) => i.id === id)
);

export const isUserAuthorized = (user: User, authKey: AuthKeys) => {
  if (!user) {
    return false;
  }

  if (!authKey || user.isGeneralAdmin) {
    return true;
  }

  const roles = authMap[authKey];
  let mergedUserRoles: (RoleType | PatientRoleType | AdditionalPermissionType)[] = [];

  if (user.patientRole) {
    mergedUserRoles = [user.role, ...user.additionalPermissions, user.patientRole];
  } else {
    mergedUserRoles = [user.role, ...user.additionalPermissions];
  }

  const authorized = roles.some((role) => mergedUserRoles.includes(role));

  return authorized;
};

export const selectIsUserAuthorized = createSelector(
  selectSessionUser,
  (state: RootState, authKey: AuthKeys) => authKey,
  isUserAuthorized
);

export const selectCurrencies = (state: RootState) => state.session.client.currencies;
export const selectCurrencyByCode = createSelector(
  selectCurrencies,
  (state: RootState, code: string) => code,
  (currencies, code) => currencies.find((i) => i.code === code) ?? null
);
