import { Asset, AssetHistoryKeys } from './types';
import { PayloadAction, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { TaskType } from 'store/slices/tasks/common/type';
import { initialState } from './data';

export const assetsAdapter = createEntityAdapter<Asset, string>({
  selectId: (asset) => asset.id,
  sortComparer: (first, second) => first.code.localeCompare(second.code)
});

const assetSlice = createSlice({
  initialState: assetsAdapter.getInitialState(initialState),
  name: 'assets',
  reducers: {
    addAsset: assetsAdapter.upsertOne,
    assetHistoryFilterUpdated: (draft, action: PayloadAction<AssetHistoryKeys>) => {
      const historyKey = action.payload;

      if (action.payload) {
        const index = draft.assetHistoryFilterTypes.findIndex((item) => item === historyKey);
        if (index > -1) {
          draft.assetHistoryFilterTypes.splice(index, 1);
        } else {
          draft.assetHistoryFilterTypes.push(historyKey);
        }
      }
    },
    clearAssetForm: (draft) => {
      draft.assetForm = initialState.assetForm;
      draft.initialAssetForm = initialState.assetForm;
    },
    historyFilterCleared: (draft) => {
      draft.assetHistoryFilterTypes = initialState.assetHistoryFilterTypes;
      draft.taskHistoryFilterTypes = initialState.taskHistoryFilterTypes;
    },
    removeAssetBranchId: (draft) => {
      delete draft.assetForm.branchId;
    },
    removeMainCategoryId: (draft) => {
      delete draft.assetForm.mainCategoryId;
    },
    resetAssetForm: (draft) => {
      draft.assetForm = draft.initialAssetForm;
    },
    setInitialAssetForm: (draft, action: PayloadAction<Asset>) => {
      const assetForm = { ...action.payload };
      draft.assetForm = assetForm;
      draft.initialAssetForm = assetForm;
    },
    taskHistoryFilterUpdated: (draft, action: PayloadAction<TaskType>) => {
      const historyKey = action.payload;

      if (action.payload) {
        const index = draft.taskHistoryFilterTypes.findIndex((item) => item === historyKey);
        if (index > -1) {
          draft.taskHistoryFilterTypes.splice(index, 1);
        } else {
          draft.taskHistoryFilterTypes.push(historyKey);
        }
      }
    }
  }
});

export const {
  addAsset,
  assetHistoryFilterUpdated,
  clearAssetForm,
  historyFilterCleared,
  removeAssetBranchId,
  removeMainCategoryId,
  resetAssetForm,
  setInitialAssetForm,
  taskHistoryFilterUpdated
} = assetSlice.actions;

export default assetSlice.reducer;
