import { Navigate, Outlet, Route } from 'react-router-dom';
import { lazy } from 'react';
import PublicGuard from 'components/RouteGuards/PublicGuard';

const Error401 = lazy(() => import('views/Errors/Error401'));
const Error404 = lazy(() => import('views/Errors/Error404'));
const Error500 = lazy(() => import('views/Errors/Error500'));

export default function renderErrorRoutes() {
  const handleRefresh = () => {
    window.location.reload();
  };

  return (
    <Route
      element={
        <PublicGuard>
          <Outlet />
        </PublicGuard>
      }
      path="errors"
    >
      <Route element={<Error401 />} path="error-401" />
      <Route element={<Error404 onRedirect={handleRefresh} />} path="error-404" />
      <Route element={<Error500 onRedirect={handleRefresh} />} path="error-500" />
      <Route element={<Navigate to="error-404" />} index />
    </Route>
  );
}
