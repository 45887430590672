import { AssetStatus } from 'store/slices/common/types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { myAssetFilter } from './data';

export const assetFilterSlice = createSlice({
  initialState: myAssetFilter,
  name: 'myAssetFilter',
  reducers: {
    extraFiltersUpdated: (draft, action: PayloadAction<AssetStatus>) => {
      const status = action.payload;
      if (action.payload) {
        const index = draft.extraFilters.findIndex((item) => item === status);
        if (index > -1) {
          draft.extraFilters.splice(index, 1);
        } else {
          draft.extraFilters.push(status);
        }
      }
    }
  }
});

export const { extraFiltersUpdated } = assetFilterSlice.actions;

export default assetFilterSlice.reducer;
