import { getEnvironment, useConfigQuery } from 'utils/settings';
import { getMixPanelEnabled } from './common';
import { useEffect } from 'react';
import mixpanel from 'mixpanel-browser';

interface MixpanelUser {
  firstName: string;
  id: string;
  lastName: string;
  role: string;
  username: string;
}

const initializeMixPanel = (deployment: string) => {
  if (!getMixPanelEnabled()) {
    return;
  }

  // TODO: Read these keys from configmap - secret
  const developmentKey = 'e374ec80f83c703e18f1f07db7337b2a';
  const productionKey = '0decbbe4d0cc624c2e64500009c6ee7f';
  const environment = getEnvironment();

  if (environment !== 'prod') {
    // dev and test environments
    mixpanel.init(developmentKey);
  } else {
    mixpanel.init(productionKey);
  }

  mixpanel.register({ Deployment: deployment });
};

export const useInitializeMixPanel = () => {
  const { data: config, isSuccess } = useConfigQuery();
  useEffect(() => {
    if (isSuccess) {
      initializeMixPanel(config.deployment);
    }
  }, [isSuccess, config]);
};

export const registerMixpanelUser = (user: MixpanelUser) => {
  if (!getMixPanelEnabled()) {
    return;
  }
  mixpanel.identify(user.id);
  mixpanel.people.set({
    $email: user.username,
    $first_name: user.firstName,
    $last_name: user.lastName,
    Role: user.role
  });
};
