import { ContractFilter, ContractItem, ContractStatusType } from './types';
import { DynamicListDefinition } from '@borda/cat-core';

export const defaultContractFilter: ContractFilter = {
  contractTitle: null,
  status: ['active']
};

export const contractFilterStatuses: ContractStatusType[] = ['active', 'expired'];

export const initialColumnDynamicData: DynamicListDefinition<ContractItem> = {
  columns: [
    {
      column: 'status',
      visible: true,
      width: 50
    },
    {
      column: 'id',
      visible: false,
      width: 150
    },
    {
      column: 'title',
      visible: true,
      width: 150
    },
    {
      column: 'type',
      visible: true,
      width: 150
    },
    {
      column: 'firmName',
      visible: true,
      width: 150
    },
    {
      column: 'startDate',
      visible: true,
      width: 150
    },
    {
      column: 'endDate',
      visible: true,
      width: 150
    },
    {
      column: 'mainCategoryId',
      visible: true,
      width: 150
    },
    {
      column: 'isAssociatedAsset',
      visible: true,
      width: 150
    },
    {
      column: 'branchIds',
      visible: true,
      width: 75
    },
    {
      column: 'ownership',
      visible: true,
      width: 75
    }
  ],
  sorts: [{ column: 'endDate', direction: 'desc' }]
};
