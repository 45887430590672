import { ACCOUNTINFORMATION } from 'routes/constant-route';
import { Button, Typography } from '@borda/cat-mui';
import { NavLink } from 'react-router-dom';
import { selectSessionUser } from '../../../store/slices/session';
import { useTypedSelector } from 'hooks';
import AvatarItem from 'views/UserManagement/Users/UserSelector/UserSelectorItem/AvatarItem';

function ProfileButton() {
  const user = useTypedSelector(selectSessionUser);

  return (
    <NavLink to={ACCOUNTINFORMATION}>
      <Button className="bg-lightGrey/60 flex w-44 items-center justify-start gap-2 rounded-2xl p-2 normal-case">
        <AvatarItem color="darkGrey" size="medium" user={user} />
        <Typography
          className="truncate"
          title={`${user?.firstName} ${user?.lastName}`}
          variant="body2"
        >{`${user?.firstName} ${user?.lastName}`}</Typography>
      </Button>
    </NavLink>
  );
}

export default ProfileButton;
