import { DynamicListDefinition } from '@borda/cat-core';
import { Export } from './types';

export const initialColumnDynamicData: DynamicListDefinition<Export> = {
  columns: [
    {
      column: 'type',
      visible: true,
      width: 150
    },
    {
      column: 'requestDate',
      visible: true,
      width: 150
    },
    {
      column: 'status',
      visible: true,
      width: 150
    },
    {
      column: 'progress',
      visible: true,
      width: 150
    },
    {
      column: 'filePath',
      visible: true,
      width: 150
    }
  ],
  sorts: [{ column: 'requestDate', direction: 'desc' }]
};
