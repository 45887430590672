import { AssetListItem } from '../../asset/list/types';
import { MYASSETSLIST, getColumnsKey } from 'helpers/localStorage';
import { RootState } from 'RootTypes';
import { createListAdapter } from '@borda/cat-core';
import { createSlice } from '@reduxjs/toolkit';
import { initialColumnDynamicData } from '../../asset/list/data';

export const listAdapter = createListAdapter<AssetListItem>({
  initialDynamicListDefinitions: initialColumnDynamicData,
  localStorageKey: getColumnsKey(MYASSETSLIST)
});

export const myAssetsListSlice = createSlice({
  initialState: listAdapter.getInitialState({}),
  name: 'myAssetsList',
  reducers: {
    ...listAdapter.getReducers()
  }
});

export const myAssetsListActions = myAssetsListSlice.actions;

export const {
  selectItems: selectAllAssets,
  selectListState,
  selectTotal: selectTotalAssets
} = listAdapter.getSelectors<RootState>((state) => state.assets.myAssets.list);

export const selectMyAssetListColumns = (state: RootState) => state.assets.myAssets.list.columns;

export default myAssetsListSlice.reducer;
