import { AppThunk } from 'RootTypes';
import { apiCaller } from 'store/common/apiCaller';
import { apiUrls } from 'api';
import axios from 'axios';

export const getImage =
  (imagePath: string): AppThunk<Promise<string>> =>
  async (dispatch) => {
    const requestBuilder = () =>
      axios.get<Blob>(apiUrls.category.getModelPhotoByPhotoPath(imagePath), {
        responseType: 'blob'
      });
    const data = await dispatch(apiCaller(requestBuilder));
    const image = URL.createObjectURL(data);

    return image;
  };
