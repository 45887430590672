import { combineReducers } from '@reduxjs/toolkit';
import myRequestsListSlice from './myRequests/myRequests';
import protectionReducer from './protection';
import taskFilterSlice from './taskList/filter/slice';
import tasksSlice from './taskList/list/slice';
import waitingForConfirmationListSlice from './waitingForConformation/waitingForConfirmationList';

const taskReducer = combineReducers({
  myRequestsList: myRequestsListSlice,
  protections: protectionReducer,
  taskList: tasksSlice,
  taskListFilter: taskFilterSlice,
  waitingForConfirmationList: waitingForConfirmationListSlice
});

export default taskReducer;
