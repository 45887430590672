import { AppThunk, RootState } from 'RootTypes';
import { apiCaller } from 'store/common/apiCaller';
import { apiUrls } from 'api';
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { useEffect } from 'react';
import { useTypedDispatch } from 'hooks';
import axios from 'axios';

export interface Brand {
  id?: string;
  name?: string;
}

export const brandsAdapter = createEntityAdapter<Brand, string>({ selectId: (brand) => brand.id });

const brandSlice = createSlice({
  initialState: brandsAdapter.getInitialState(),
  name: 'assetBrands',
  reducers: {
    upsertBrands: brandsAdapter.upsertMany
  }
});

export const { selectAll: selectAllBrands, selectById: selectBrandById } =
  brandsAdapter.getSelectors<RootState>((state) => state.assets.assets.brands);

export default brandSlice.reducer;

export const { upsertBrands } = brandSlice.actions;

const getAllAuthorizedBrands =
  (mainCategoryIds: string[] = []): AppThunk =>
  async (dispatch) => {
    const requestBuilder = () =>
      axios.post<Brand[]>(apiUrls.category.getBrandsByMainCategoryIds(), mainCategoryIds);
    const data = await dispatch(apiCaller(requestBuilder));

    dispatch(upsertBrands(data));
  };

export function useGetAllBrands() {
  const dispatch = useTypedDispatch();

  useEffect(() => {
    dispatch(getAllAuthorizedBrands());
  }, [dispatch]);
}
