import { CatIconButton } from '@borda/cat-ui';
import { EditIcon } from '@borda/cat-icons';
import { styled } from '@borda/cat-mui';
import { useEffect, useState } from 'react';
import ReadonlyTextField from 'components/ReadonlyTextField/ReadonlyTextField';

const StyledReadonlyTextField = styled(ReadonlyTextField)({
  '& .edit-button': {
    opacity: 0,
    transition: 'opacity 0.3s ease-out'
  },
  '&:hover .edit-button': {
    opacity: 1
  }
});

type Props = {
  alwaysShowEditIcon?: boolean;
  disabled?: boolean;
  editable?: boolean;
  formatValue?: (value: string | React.ReactNode) => string | React.ReactNode;
  label: string;
  onEditClick?: () => void;
  readonly: boolean;
  readonlyAdornments?: React.ReactNode;
  value: string | React.ReactNode;
};

export type TextFieldProps<T> = T & {
  label: string;
  value: string | React.ReactNode;
};

export function withReadonlyMode<T>(WrappedComponent: React.FunctionComponent<TextFieldProps<T>>) {
  return function ReadonlyMode(props: T & Props) {
    const {
      alwaysShowEditIcon = false,
      disabled,
      editable = true,
      formatValue = (value) => value,
      label,
      onEditClick,
      readonly,
      readonlyAdornments,
      value,
      ...rest
    } = props;

    const [autoFocus, setAutoFocus] = useState(false);

    useEffect(() => {
      if (readonly && autoFocus) {
        setAutoFocus(false);
      }
    }, [readonly, autoFocus]);

    const handleEdit = () => {
      setAutoFocus(true);
      onEditClick();
    };

    if (readonly) {
      return (
        <StyledReadonlyTextField
          disabled={disabled}
          endAdornment={
            <div className="flex items-center gap-1">
              {readonlyAdornments && readonlyAdornments}
              {editable && (
                <CatIconButton
                  className={alwaysShowEditIcon ? '' : 'edit-button'}
                  onClick={handleEdit}
                >
                  <EditIcon color="blue" />
                </CatIconButton>
              )}
            </div>
          }
          label={label}
          text={formatValue(value)}
        />
      );
    }

    return (
      <WrappedComponent
        {...(rest as any as T)}
        autoFocus={autoFocus}
        disabled={disabled}
        label={label}
        value={value}
      />
    );
  };
}
