import { CatButton, CatButtonProps } from '@borda/cat-ui';
import {
  Handler,
  SupportedKeys,
  useKeyboardHandler,
  useLoadingWithoutDispatch
} from '@borda/cat-core';
import React, { forwardRef } from 'react';

type Props = CatButtonProps & {
  component?: React.ComponentType<CatButtonProps>;
  keyboardKey: SupportedKeys;
};

const KeyboardButton = forwardRef((props: Props, ref: React.Ref<any>) => {
  const {
    component: ButtonComponent = CatButton,
    disabled,
    keyboardKey,
    loading,
    onClick,
    ...rest
  } = props;

  const [innerLoading, loadingDispatch] = useLoadingWithoutDispatch();
  const handleClick = async (e: any) => {
    await loadingDispatch(onClick(e));
  };

  const buttonLoading = loading || innerLoading;
  const buttonDisabled = buttonLoading || disabled;

  useKeyboardHandler(keyboardKey, handleClick as Handler, disabled);

  return (
    <ButtonComponent
      disabled={buttonDisabled}
      loading={buttonLoading}
      onClick={handleClick}
      ref={ref}
      {...rest}
    />
  );
});

export default KeyboardButton;
