import { KeyPrefixesWithKeys } from './types/trans-keys-with-prefixes';
import { TFunction } from './common';
import { UseTranslationOptions, useTranslation as useTranslationOriginal } from 'react-i18next';
import i18next from 'utils/i18n';

export const useCatTranslation = <KPrefix extends keyof KeyPrefixesWithKeys = undefined>(
  translation?: 'translation',
  opts?: UseTranslationOptions<KPrefix>
) => {
  const { t: tOriginal, ...rest } = useTranslationOriginal(translation, {
    ...opts,
    keyPrefix: undefined
  });

  // Key prefix usage of i18next is removed since Bug 34432
  const t: TFunction<KPrefix> = (key, args) => {
    let finalKey: string = key;
    if (opts?.keyPrefix) {
      finalKey = `${opts?.keyPrefix}.${key}`;
    }

    return tOriginal(finalKey, args) as string;
  };

  return { t, ...rest };
};

export const tCat: TFunction<undefined> = (key, args) => i18next.t(key, args) as string;
