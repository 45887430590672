import { CatTrans, useCatTranslation } from 'utils/localization';
import { CatTypography } from '@borda/cat-ui';
import { CheckIcon } from '@borda/cat-icons';
import { OkButton, UploadAgainButton } from 'packages/cat-wrappers';
import { completeImportProcess } from 'store/slices/asset/asset/import/actions';
import { selectValidAssetCount } from 'store/slices/asset/asset/import/selectors';
import { useInvalidateAssetListQuery } from 'views/Asset/Assets/AssetList/List/queries';
import { useTypedDispatch, useTypedSelector } from 'hooks';
import React from 'react';

export default function Success() {
  const dispatch = useTypedDispatch();
  const { t } = useCatTranslation('translation', {
    keyPrefix: 'assets.asset_import.step4.result.success'
  });
  const validAssetCount = useTypedSelector(selectValidAssetCount);
  const invalidateAssetList = useInvalidateAssetListQuery();

  const handleUploadAgain = () => {
    dispatch(completeImportProcess({ uploadAgain: true }));
    invalidateAssetList();
  };
  const handleConfirm = () => {
    dispatch(completeImportProcess());
    invalidateAssetList();
  };

  return (
    <div className="flex gap-4">
      <CheckIcon color="green" contained={false} hoverable={false} />
      <div className="grid gap-1">
        <CatTypography className="opacity-80" variant="subtitle1">
          {t('title')}
        </CatTypography>
        <CatTypography className="opacity-80" variant="subtitle1">
          <CatTrans count={validAssetCount ?? null} i18nKey="desc" t={t} />
        </CatTypography>
        <div className="mt-6 flex items-center gap-4">
          <UploadAgainButton onClick={handleUploadAgain} />
          <div className="divider-vertical" />
          <OkButton onClick={handleConfirm} />
        </div>
      </div>
    </div>
  );
}
