import { MEDICAL_UNITS, MEDICAL_UNIT_MANAGEMENT } from 'routes/constant-route';
import { Navigate, Route } from 'react-router-dom';
import { RouteGuard } from 'components/RouteGuards/AuthGuard';
import { SuspenseOutlet, delayedLazy } from '../helpers';

const MedicalUnits = delayedLazy(() => import('views/MedicalUnits/MedicalUnits'));

export default function renderMedicalUnitsRoutes() {
  return (
    <Route
      element={
        <RouteGuard authKey="medicalUnits" products={['patient']}>
          <SuspenseOutlet />
        </RouteGuard>
      }
      path={MEDICAL_UNITS}
    >
      <Route element={<MedicalUnits />} path={MEDICAL_UNIT_MANAGEMENT} />
      <Route element={<Navigate replace to={MEDICAL_UNIT_MANAGEMENT} />} index />
    </Route>
  );
}
