import { AlertPanelFilter } from './types';

export const defaultAlertPanelFilter: AlertPanelFilter = {
  branchIds: [],
  extraFilters: [],
  priorities: [],
  productTypes: [],
  sort: null,
  statuses: []
};

export const clearableFieldsKeys: Array<keyof AlertPanelFilter> = [
  'branchIds',
  'extraFilters',
  'priorities',
  'productTypes',
  'sort'
];
