import { ASSETLIST, getColumnsKey } from 'helpers/localStorage';
import { AssetListItem } from './types';
import { createListAdapter } from '@borda/cat-core';
import { createSlice } from '@reduxjs/toolkit';
import { initialColumnDynamicData } from './data';

export const listAdapter = createListAdapter<AssetListItem>({
  initialDynamicListDefinitions: initialColumnDynamicData,
  localStorageKey: getColumnsKey(ASSETLIST)
});

export const assetListSlice = createSlice({
  initialState: listAdapter.getInitialState({}),
  name: 'assetList',
  reducers: {
    ...listAdapter.getReducers()
  }
});

export default assetListSlice.reducer;
