import { combineReducers } from '@reduxjs/toolkit';
import filter from './filter/slice';
import list from './list/slice';

const patientReducer = combineReducers({
  filter,
  list
});

export default patientReducer;
