import { Branch } from 'store/slices/branches';
import { CYLERA_LIST, RTLS_CONFIG } from 'routes/constant-route';
import { Navigate, Outlet, Route } from 'react-router-dom';
import { RouteGuard } from 'components/RouteGuards/AuthGuard';
import { SuspenseOutlet, delayedLazy } from '../helpers';
import { isArrayNullOrEmpty } from '@borda/cat-core';

const CyleraList = delayedLazy(() => import('views/Integrations/Cylera/CyleraList'));
const CyleraAssetAdd = delayedLazy(() => import('views/Asset/Assets/AssetDetail/AssetAdd'));
const RtlsConfig = delayedLazy(() => import('views/Integrations/Rtls/RtlsConfigPage'));

export default function renderIntegrationRoutes(
  cyleraEnable: boolean,
  rtlsEnabledBranches: Branch[]
) {
  const replaceTo = cyleraEnable ? 'cylera' : RTLS_CONFIG;
  return (
    <Route
      element={
        <RouteGuard
          authKey="integration"
          hidden={!cyleraEnable && isArrayNullOrEmpty(rtlsEnabledBranches)}
        >
          <SuspenseOutlet />
        </RouteGuard>
      }
      path="integration"
    >
      <Route
        element={
          <RouteGuard hidden={!cyleraEnable}>
            <Outlet />
          </RouteGuard>
        }
        path="cylera"
      >
        <Route element={<CyleraList />} index />
        <Route
          element={
            <RouteGuard authKey="assetList">
              <CyleraAssetAdd
                getCancelNavigationAddress={() => CYLERA_LIST}
                getSaveNavigationAddress={() => CYLERA_LIST}
              />
            </RouteGuard>
          }
          path="asset-add"
        />
      </Route>
      <Route
        element={
          <RouteGuard hidden={isArrayNullOrEmpty(rtlsEnabledBranches)}>
            <RtlsConfig />
          </RouteGuard>
        }
        path={RTLS_CONFIG}
      />
      <Route element={<Navigate replace to={replaceTo} />} index />
    </Route>
  );
}
