import { selectErrorAssetCount } from 'store/slices/asset/asset/import/selectors';
import { useTypedSelector } from 'hooks';
import Error from './Error/Error';
import React from 'react';
import Success from './Success';

export default function Result() {
  const errorAssetCount = useTypedSelector(selectErrorAssetCount);
  return errorAssetCount ? <Error /> : <Success />;
}
