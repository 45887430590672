import { CatColorTypes } from '@borda/cat-ui';
import { ResponseStatuses } from 'views/Alert/types';

export const getHeaderIconColor = (status: ResponseStatuses): CatColorTypes => {
  if (status === 'closed') {
    return 'green';
  }

  if (status === 'open') {
    return 'red';
  }

  return 'orange';
};

export const getAlertCardBorderHoverColor = (status: ResponseStatuses): string => {
  if (status === 'closed') {
    return 'hover:border-green';
  }

  if (status === 'open') {
    return 'hover:border-red';
  }

  return 'hover:border-orange';
};
