import { CatIconButton, CatTypography } from '@borda/cat-ui';
import { ExpandIcon } from '@borda/cat-icons';
import { LinearProgress } from '@borda/cat-mui';
import { dialogToggled } from 'store/slices/asset/asset/import/slice';
import { getImportSummary } from 'store/slices/asset/asset/import/actions';
import {
  selectAssetUploadPercentage,
  selectErrorAssetCount,
  selectImportStatus
} from 'store/slices/asset/asset/import/selectors';
import { useCatTranslation } from 'utils/localization';
import { useTypedDispatch, useTypedSelector } from 'hooks';
import React, { useEffect, useRef } from 'react';

function Step4Progress() {
  const { t } = useCatTranslation();
  const dispatch = useTypedDispatch();
  const percentage = useTypedSelector(selectAssetUploadPercentage);
  const errorAssetCount = useTypedSelector(selectErrorAssetCount);
  const status = useTypedSelector(selectImportStatus);
  const intervalRef = useRef<NodeJS.Timeout>(null);

  const preventRequest =
    status === 'validatedFail' ||
    status === 'imported' ||
    status === 'validatedSuccess' ||
    status === 'importedCanceled' ||
    !status;

  useEffect(() => {
    dispatch(getImportSummary());
  }, [dispatch]);

  useEffect(() => {
    if (preventRequest) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
      return;
    }

    if (!intervalRef.current) {
      const progressCheckInterval = setInterval(() => {
        dispatch(getImportSummary());
      }, 1000);
      intervalRef.current = progressCheckInterval;
    }
  }, [dispatch, preventRequest]);

  useEffect(
    () => () => {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    },
    []
  );

  const minimizeTitle = React.useMemo(() => {
    if (status === 'imported' && errorAssetCount) {
      return t('assets.asset_import.minimize.assets_error');
    }
    if (status === 'imported') {
      return t('assets.asset_import.minimize.assets_added');
    }
    if (status === 'importingCanceling') {
      return t('assets.asset_import.common.cancelling');
    }
    return t('assets.asset_import.minimize.assets_adding');
  }, [errorAssetCount, status, t]);

  const loadingColor = React.useMemo(() => {
    if (status === 'imported' && errorAssetCount) {
      return 'error';
    }
    if (status === 'imported') {
      return 'success';
    }
    return 'primary';
  }, [errorAssetCount, status]);

  const minimizeStatus = React.useMemo(() => {
    if (status === 'imported' && errorAssetCount) {
      return (
        <CatTypography className="text-red opacity-80" variant="caption">
          {t('assets.asset_import.minimize.error')}
        </CatTypography>
      );
    }
    if (status === 'imported') {
      return (
        <CatTypography className="text-green opacity-80" variant="caption">
          {t('assets.asset_import.minimize.completed')}
        </CatTypography>
      );
    }
    return (
      <CatTypography className="opacity-80" variant="caption">
        {`% ${percentage}`}
      </CatTypography>
    );
  }, [errorAssetCount, percentage, status, t]);

  const handleOpenDialog = () => {
    dispatch(dialogToggled(true));
  };

  return (
    <div className="grid">
      <CatTypography className="text-center opacity-80" variant="caption">
        {minimizeTitle}
      </CatTypography>
      <LinearProgress
        className="my-2 h-0.5 rounded-md"
        color={loadingColor}
        value={percentage}
        variant="determinate"
      />
      <div className="relative grid grid-flow-col items-center justify-center">
        {minimizeStatus}
        <CatIconButton className="absolute right-0 opacity-80" onClick={handleOpenDialog}>
          <ExpandIcon alwaysHovered contained fontSize="small" />
        </CatIconButton>
      </div>
    </div>
  );
}

export default Step4Progress;
