import AlertPanelSort from './AlertPanelSort';
import BranchFilter from './Filters/BranchFilter';
import ExtraFilters from './Filters/ExtraFilters';
import FilterClear from './FilterClear';
import HeaderContainer from 'views/Alert/AlertPanel/Common/HeaderContainer';
import PriorityFilter from './Filters/PriorityFilter';
import ProductTypeFilter from './Filters/ProductTypeFilter';

function FiltersAndSort() {
  return (
    <HeaderContainer className="shadow-2 py-2">
      <div className="flex w-full items-center justify-between">
        <div className="flex items-center gap-2">
          <PriorityFilter />
          <ProductTypeFilter />
          <BranchFilter />
        </div>
        <div className="flex items-center">
          <div className="flex items-center gap-2">
            <ExtraFilters />
            <FilterClear />
          </div>
          <div className="divider-vertical-dark h-4" />
          <AlertPanelSort />
        </div>
      </div>
    </HeaderContainer>
  );
}

export default FiltersAndSort;
