import { Priority, ProductType, ResponseStatuses } from '../types';

export const extraFilters = ['unresponded', 'responded', 'responsible', 'receive'] as const;
export type ExtraFilter = (typeof extraFilters)[number];

export const alertPanelSortings = [
  'creationDateByNewest',
  'creationDateByOldest',
  'lastUpdateByNewest',
  'lastUpdateByOldest',
  'priorityByHighest',
  'priorityByLowest'
] as const;
export type AlertPanelSort = (typeof alertPanelSortings)[number];

export type AlertPanelFilter = {
  branchIds: string[];
  extraFilters: ExtraFilter[];
  priorities: Priority[];
  productTypes: ProductType[];
  sort: AlertPanelSort;
  statuses: ResponseStatuses[];
};
