import { CatSelect } from '@borda/cat-ui';
import { Control, useController } from 'react-hook-form';
import React from 'react';

type CatSelectProps = React.ComponentPropsWithRef<typeof CatSelect>;
export type FormSelectProps = Omit<CatSelectProps, 'value'> & {
  control: Control<any, any>;
};

function FormSelect({ control, name, onChange, ...rest }: FormSelectProps) {
  const { field, fieldState } = useController({ control, name });

  const { onBlur, onChange: onChangeForm, ref: inputRef, value } = field;
  const { error } = fieldState;

  return (
    <CatSelect
      helperText={error?.message}
      inputRef={inputRef}
      onBlur={onBlur}
      onChange={(e, child) => {
        onChangeForm(e);
        onChange?.(e, child);
      }}
      value={value}
      {...rest}
    />
  );
}

export default FormSelect;
