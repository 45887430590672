import { LocationStateShape } from './types';

export const parentLocationIdOfRoot: string = null;
export const maxLocationDepth = 7;
export const temporaryLocationLevelId = '-1';
export const nonexistingParentLocationId = '-1';

export const initialState: LocationStateShape = {
  expandedLocationIds: [parentLocationIdOfRoot]
};
