import React from 'react';
import Report from './Report/Report';
import Summary from './Summary';

export default function Step3() {
  return (
    <>
      <Summary />
      <div className="divider-horizontal my-6 ml-10" />
      <Report />
    </>
  );
}
