import { CatChip, CatTooltip } from '@borda/cat-ui';
import { selectMultipleBranchesByIds } from 'store/slices/branches';
import { useTypedSelector } from 'hooks';

type Props = {
  branchIds?: string[];
};

function BranchesList(props: Props) {
  const { branchIds } = props;

  const branches = useTypedSelector((state) => selectMultipleBranchesByIds(state, branchIds));

  let branchesToDisplay = branches;
  let branchNamesOnTooltip: string[] = [];

  if (branches.length > 4) {
    branchesToDisplay = branches.slice(0, 4);
    branchNamesOnTooltip = branches.slice(4).map((i) => i.name);
  }

  return (
    <div className="grid w-full grid-cols-[repeat(4,_minmax(0,_1fr))_auto] gap-1 truncate">
      {branchesToDisplay.map((branch) => (
        <CatChip
          className="border-darkGrey min-w-full truncate border-solid"
          key={branch?.id}
          label={branch?.name}
          size="small"
          variant="filled"
        />
      ))}
      {branches.length > 4 && (
        <CatTooltip
          arrow
          title={<div className="whitespace-pre">{branchNamesOnTooltip.join('\r\n')}</div>}
        >
          <span className="flex items-center">
            <CatChip
              className="border-darkGrey truncate border-solid"
              label={`+${branches.length - 4}`}
              size="small"
              variant="filled"
            />
          </span>
        </CatTooltip>
      )}
    </div>
  );
}

export default BranchesList;
