import './assets/scss/main.scss';
import './mixins/momentMixin';
import '@borda/cat-ui/dist/index.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { CatSnackbar } from 'components/SmoothSnackbar';
import { CssBaseline, StyledEngineProvider, ThemeProvider } from '@borda/cat-mui';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import { LicenseInfo } from '@mui/x-data-grid-pro';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { SnackbarProvider } from 'notistack';
import { Provider as StoreProvider } from 'react-redux';
import { catTheme } from '@borda/cat-ui';
import { history } from 'utils/history';
import { initAxiosInterceptors } from 'utils/axiosUtils';
import { queryClient } from 'utils/react-query';
import { useInitializeMixPanel } from 'utils/mixpanel';
import { useRegisterKeyboardListener } from '@borda/cat-core';
import { useSentry } from 'hooks/app/useSentry';
import ErrorBoundary from './components/ErrorBoundary';
import LiveTranslationWarning from 'utils/localization/LiveTranslationWarning';
import Routes from 'routes/routes';
import ScrollReset from './packages/cat-wrappers/ScrollReset';
import store from './store';
import useTranslationInit from 'hooks/app/useTranslationInit';

LicenseInfo.setLicenseKey(import.meta.env.VITE_MUI_X_LICENSE_KEY);
initAxiosInterceptors();

function AppContent() {
  useSentry();
  useInitializeMixPanel();
  useTranslationInit();
  useRegisterKeyboardListener();

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={catTheme}>
        <SnackbarProvider
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          Components={{
            error: CatSnackbar,
            info: CatSnackbar,
            success: CatSnackbar,
            warning: CatSnackbar
          }}
          maxSnack={5}
        >
          <LiveTranslationWarning />
          <ReactQueryDevtools buttonPosition="top-left" />
          <CssBaseline />
          <HistoryRouter history={history as any}>
            <ErrorBoundary>
              <ScrollReset />
              <Routes />
            </ErrorBoundary>
          </HistoryRouter>
        </SnackbarProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

function App() {
  return (
    <StoreProvider store={store}>
      <QueryClientProvider client={queryClient}>
        <AppContent />
      </QueryClientProvider>
    </StoreProvider>
  );
}

export default App;
