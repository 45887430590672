import { CatPopover } from '@borda/cat-ui';
import { NotificationListItem } from 'store/slices/notifications/list/types';
import { PopupState, bindPopover } from 'material-ui-popup-state/hooks';
import { useInfiniteNotificationListQuery } from './queries';
import { useInfiniteQueryItems } from 'utils/react-query';
import { withDialogWrapper } from 'packages/cat-wrappers/hooks';
import Content from './Content/Content';
import Header from './Header/Header';

type Props = {
  open: boolean;
  popupState: PopupState;
};

function NotificationPopover(props: Props) {
  const { open, popupState } = props;

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isPending: notificationsPending,
    isSuccess
  } = useInfiniteNotificationListQuery();

  const { items: allNotifications, total } = useInfiniteQueryItems<NotificationListItem>(
    data,
    isSuccess,
    'id'
  );

  const handleClose = () => {
    popupState.close();
  };

  return (
    <CatPopover
      {...bindPopover(popupState)}
      anchorOrigin={{
        horizontal: 'right',
        vertical: 'bottom'
      }}
      className="mt-2"
      marginThreshold={8}
      open={open}
      transformOrigin={{
        horizontal: 'right',
        vertical: 'top'
      }}
      width="512px"
    >
      <div
        className="flex flex-col"
        style={{ height: 'calc(100vh - var(--page-space-notification-panel))' }}
      >
        <Header onClose={handleClose} total={total} />
        <Content
          allNotifications={allNotifications}
          fetchNextPage={fetchNextPage}
          hasNextPage={hasNextPage}
          notificationsLoading={notificationsPending}
          onClose={handleClose}
        />
      </div>
    </CatPopover>
  );
}

export default withDialogWrapper(NotificationPopover);
