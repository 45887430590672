import { getEnvironment } from 'utils/settings';

export const getMixPanelEnabled = () => {
  const environment = getEnvironment();
  if (environment === 'local') {
    return false;
  }

  return true;
};
