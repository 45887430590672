import { CatTrans, useCatTranslation } from 'utils/localization';
import { CatTypography } from '@borda/cat-ui';
import { CheckIcon } from '@borda/cat-icons';
import { OkButton, UploadAgainButton } from 'packages/cat-wrappers';
import {
  completeImportProcess,
  getImportedErrorFile
} from 'store/slices/asset/asset/import/actions';
import {
  selectErrorAssetCount,
  selectSuccessAssetCount
} from 'store/slices/asset/asset/import/selectors';
import { selectUserLanguage } from 'store/slices/session';
import { useInvalidateAssetListQuery } from 'views/Asset/Assets/AssetList/List/queries';
import { useTypedDispatch, useTypedSelector } from 'hooks';
import ErrorCard from './ErrorCard';
import React from 'react';
import SuccessCard from './SuccessCard';
import saveAs from 'file-saver';

export default function Error() {
  const dispatch = useTypedDispatch();
  const { t } = useCatTranslation('translation', {
    keyPrefix: 'assets.asset_import.step4.result.error'
  });
  const errorAssetCount = useTypedSelector(selectErrorAssetCount);
  const successAssetCount = useTypedSelector(selectSuccessAssetCount);
  const userLanguage = useTypedSelector(selectUserLanguage);

  const invalidateAssetList = useInvalidateAssetListQuery();

  const handleDownloadFile = async () => {
    const url = await dispatch(getImportedErrorFile());
    await saveAs(url, `asset_template_import_errors_${userLanguage}.xlsx`);
  };

  const handleUploadAgain = () => {
    dispatch(completeImportProcess({ uploadAgain: true }));
    invalidateAssetList();
  };

  const handleConfirm = async () => {
    await handleDownloadFile();
    dispatch(completeImportProcess());
    invalidateAssetList();
  };

  return (
    <div className="flex gap-4">
      <CheckIcon color="green" contained={false} hoverable={false} />
      <div className="grid gap-1">
        <CatTypography className="opacity-80" variant="subtitle1">
          {t('title')}
        </CatTypography>
        <div className="flex items-center">
          <CatTypography className="opacity-80" variant="body1">
            <CatTrans count={successAssetCount} i18nKey="add" t={t} />
          </CatTypography>
          <div className="divider-vertical" />
          <CatTypography className="opacity-80" variant="body1">
            <CatTrans count={errorAssetCount} i18nKey="fail" t={t} />
          </CatTypography>
        </div>
        <div className="flex gap-4">
          <ErrorCard />
          <SuccessCard />
        </div>
        <div className="mt-6 flex items-center gap-4">
          <UploadAgainButton onClick={handleUploadAgain} />
          <div className="divider-vertical" />
          <OkButton onClick={handleConfirm} />
        </div>
      </div>
    </div>
  );
}
