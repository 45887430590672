import { combineReducers } from 'redux';
import filterSlice from './cyleraFilter';
import listSlice from './cyleraList';

const cyleraReducer = combineReducers({
  filter: filterSlice,
  list: listSlice
});

export default cyleraReducer;
