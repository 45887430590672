import { BordaTextIcon } from '@borda/cat-icons';
import { CatTypography } from '@borda/cat-ui';
import { Divider, Typography } from '@borda/cat-mui';
import { selectAccountName } from 'store/slices/session';
import { useTypedSelector } from 'hooks';
import React from 'react';
import classes from './PublicLayout.module.scss';

type Props = {
  children: React.ReactNode;
};

function PublicLayout({ children }: Props) {
  const accountName = useTypedSelector(selectAccountName);

  return (
    <main className="grid h-screen grid-cols-1 p-2 sm:grid-cols-2">
      <section className="relative hidden h-full overflow-hidden rounded-2xl sm:!block">
        <img
          alt="lighthouse-fullscreen"
          className="z-10 h-full w-full object-cover"
          src="/static/images/lighthouse-fullscreen.png"
        />
        <div className="bg-borda3 absolute left-0 top-0 z-20 h-full w-full opacity-60" />
        <div className="absolute left-0 top-0 z-30 p-14 opacity-60">
          <Typography className="text-lightGrey mb-4 text-sm italic opacity-60" variant="body1">
            “There is, one knows not what sweet mystery about this sea, whose gently awful stirrings
            seem to speak of some hidden soul beneath...”
          </Typography>
          <Typography className="text-lightGrey text-xs opacity-40" variant="body1">
            Herman Melville, Moby-Dick or, the Whale
          </Typography>
        </div>
        <div className="absolute bottom-0 left-0 z-40 w-auto p-14">
          <BordaTextIcon
            className="text-[56px] text-white"
            fontSize="large"
            height={18}
            viewBox="0 0 56 18"
            width={56}
          />
        </div>
      </section>
      <section className="grid grid-rows-3 gap-[10px] p-8 md:p-14">
        <div className="flex items-center gap-4 self-start">
          <img
            alt="lighthouse-quattro"
            className="w-56"
            src="/static/images/lighthouse-quattro.png"
          />
          <Divider flexItem orientation="vertical" />
          <CatTypography className="text-borda2" variant="h1">
            {accountName}
          </CatTypography>
        </div>

        <div className="self-center">{children}</div>
        <div className="min-h-[100px]" />
      </section>
    </main>
  );
}

export default PublicLayout;
