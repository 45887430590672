import { AlertIcon } from '@borda/cat-icons';
import { CatTypography } from '@borda/cat-ui';
import { CloseIconButton } from 'packages/cat-wrappers';
import { useCatTranslation } from 'utils/localization';
import HeaderContainer from '../Common/HeaderContainer';

type Props = {
  onClose: () => void;
};

function PanelHeader(props: Props) {
  const { onClose } = props;

  const { t: tAlertPanel } = useCatTranslation('translation', {
    keyPrefix: 'alert_panel'
  });

  return (
    <HeaderContainer className="justify-between py-4">
      <div className="flex items-center gap-2">
        <AlertIcon color="darkGrey" contained={false} hoverable={false} />
        <CatTypography variant="h2">{tAlertPanel('title')}</CatTypography>
      </div>
      <CloseIconButton onClick={onClose} />
    </HeaderContainer>
  );
}

export default PanelHeader;
