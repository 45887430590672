import { UserFilter, UserFilterStateShape } from './types';

const defaultFilter: UserFilter = {
  additionalPermissions: [],
  branches: [],
  departments: [],
  email: null,
  extraFilters: [],
  firstName: null,
  generalAdmin: false,
  jobTitles: [],
  lastName: null,
  lastUpdateFilter: {
    from: null,
    to: null,
    type: 'any'
  },
  roles: [],
  userAddedFilter: {
    from: null,
    to: null,
    type: 'any'
  },
  userStatuses: ['active', 'waiting']
};

export const emptyFilter: UserFilter = {
  additionalPermissions: [],
  branches: [],
  departments: [],
  email: null,
  extraFilters: [],
  firstName: null,
  generalAdmin: false,
  jobTitles: [],
  lastName: null,
  lastUpdateFilter: {
    from: null,
    to: null,
    type: 'any'
  },
  roles: [],
  userAddedFilter: {
    from: null,
    to: null,
    type: 'any'
  },
  userStatuses: []
};

export const userFilterInitialState: UserFilterStateShape = {
  activeFilter: defaultFilter
};
