import { PATIENTLIST, getColumnsKey } from 'helpers/localStorage/storageConstant';
import { PatientListItem } from './types';
import { RootState } from 'RootTypes';
import { createListAdapter } from '@borda/cat-core';
import { createSlice } from '@reduxjs/toolkit';
import { initialColumnDynamicData } from './data';

const listAdapter = createListAdapter<PatientListItem>({
  initialDynamicListDefinitions: initialColumnDynamicData,
  localStorageKey: getColumnsKey(PATIENTLIST)
});

export const patientListSlice = createSlice({
  initialState: listAdapter.getInitialState({}),
  name: 'patientList',
  reducers: {
    ...listAdapter.getReducers()
  }
});

export const { selectListState } = listAdapter.getSelectors<RootState>(
  (state) => state.patients.list
);
export const selectPatientListSorts = (state: RootState) => state.patients.list.sorts;
export const selectPatientListColumns = (state: RootState) => state.patients.list.columns;

export default patientListSlice.reducer;
export const patientListActions = patientListSlice.actions;
