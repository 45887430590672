import { NotificationFilter } from 'store/slices/notifications/filter/notificationFilter';

export const notificationsKeys = {
  all: () => ['notifications'],
  list: {
    all: () => [...notificationsKeys.all(), 'list'],
    byFilter: (notificationFilter: NotificationFilter) => [
      ...notificationsKeys.list.all(),
      notificationFilter
    ]
  },
  notificationDialog: {
    all: () => [...notificationsKeys.all(), 'dialog'],
    assetsByIds: (assetIds: string[]) => [
      ...notificationsKeys.notificationDialog.all(),
      'assets',
      assetIds
    ],
    byId: (notificationId: string) => [
      ...notificationsKeys.notificationDialog.all(),
      notificationId
    ]
  },
  preferences: {
    all: () => [...notificationsKeys.all(), 'preferences'],
    system: () => [...notificationsKeys.preferences.all(), 'system'],
    user: () => [...notificationsKeys.preferences.all(), 'user']
  },
  unreadCount: () => [...notificationsKeys.all(), 'unreadCount']
};
