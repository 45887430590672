export type RoleType = 'Admin' | 'Executive' | 'Manager' | 'Technician' | 'RequestOnly';

export type PatientRoleType =
  | 'PatientAdmin'
  | 'ReportExecutive'
  | 'ClinicalManager'
  | 'ClinicalUser'
  | 'HospitalMonitorization';

export type AssetRole = {
  id: string;
  level: number;
  name: RoleType;
  roleStrength: number;
};

export type PatientRole = {
  data?: boolean;
  id: string;
  level: number;
  name: PatientRoleType;
  roleStrength: number;
};

export type QuattroRoles = PatientRole | AssetRole;

type AdditionalPermissions = {
  [id: string]: AdditionalPermissionType;
};

export type AdditionalPermissionType = 'UserAdmin' | 'LocationAdmin';

export const userRoles: AssetRole[] = [
  {
    id: '56873d5d-6882-42e0-81a9-96657a8a2e2a',
    level: 50,
    name: 'RequestOnly',
    roleStrength: 1
  },
  {
    id: '89ba1771-37cf-450b-a351-a812c4dacc17',
    level: 40,
    name: 'Technician',
    roleStrength: 2
  },
  {
    id: 'a3e8fb8c-e28c-45c3-9c3f-4f50fa2bc56c',
    level: 30,
    name: 'Manager',
    roleStrength: 3
  },
  {
    id: 'f6c45c13-f6f5-4850-bd20-4b7edbb0275a',
    level: 20,
    name: 'Executive',
    roleStrength: 4
  },
  {
    id: 'c0f2e2a5-391a-49d1-9a6e-6af9c90370b1',
    level: 10,
    name: 'Admin',
    roleStrength: 5
  }
];
export const patientRoles: PatientRole[] = [
  {
    id: '4aa039ad-0299-4bd6-ab1b-a00214ed549d',
    level: 50,
    name: 'HospitalMonitorization',
    roleStrength: 1
  },

  {
    id: 'b4028643-4d07-4b76-9242-9e4ff936a7ac',
    level: 40,
    name: 'ClinicalUser',
    roleStrength: 2
  },

  {
    id: '0525ff08-6574-4605-bc1a-8507ad5a0e3d',
    level: 30,
    name: 'ClinicalManager',
    roleStrength: 3
  },

  {
    id: '3dc89459-d3b8-4a9d-8829-e892f0e27b02',
    level: 20,
    name: 'ReportExecutive',
    roleStrength: 4
  },

  {
    id: '5f3faafb-0126-48b4-b132-c66a2d3f3bda',
    level: 10,
    name: 'PatientAdmin',
    roleStrength: 5
  }
];

export const userRolesByName: Record<RoleType, AssetRole> = {
  Admin: {
    id: 'c0f2e2a5-391a-49d1-9a6e-6af9c90370b1',
    level: 10,
    name: 'Admin',
    roleStrength: 5
  },
  Executive: {
    id: 'f6c45c13-f6f5-4850-bd20-4b7edbb0275a',
    level: 20,
    name: 'Executive',
    roleStrength: 4
  },
  Manager: {
    id: 'a3e8fb8c-e28c-45c3-9c3f-4f50fa2bc56c',
    level: 30,
    name: 'Manager',
    roleStrength: 3
  },
  RequestOnly: {
    id: '56873d5d-6882-42e0-81a9-96657a8a2e2a',
    level: 50,
    name: 'RequestOnly',
    roleStrength: 1
  },
  Technician: {
    id: '89ba1771-37cf-450b-a351-a812c4dacc17',
    level: 40,
    name: 'Technician',
    roleStrength: 2
  }
};

export const patientRolesByName: Record<PatientRoleType, PatientRole> = {
  ClinicalManager: {
    id: '0525ff08-6574-4605-bc1a-8507ad5a0e3d',
    level: 30,
    name: 'ClinicalManager',
    roleStrength: 3
  },
  ClinicalUser: {
    id: 'b4028643-4d07-4b76-9242-9e4ff936a7ac',
    level: 50,
    name: 'ClinicalUser',
    roleStrength: 1
  },
  HospitalMonitorization: {
    id: '4aa039ad-0299-4bd6-ab1b-a00214ed549d',
    level: 40,
    name: 'HospitalMonitorization',
    roleStrength: 2
  },
  PatientAdmin: {
    id: '5f3faafb-0126-48b4-b132-c66a2d3f3bda',
    level: 10,
    name: 'PatientAdmin',
    roleStrength: 5
  },
  ReportExecutive: {
    id: '3dc89459-d3b8-4a9d-8829-e892f0e27b02',
    level: 20,
    name: 'ReportExecutive',
    roleStrength: 4
  }
};

export const availableAdditionalPermissions: AdditionalPermissions = {
  '04b6faf8-f411-4a73-82eb-4e811fd87072': 'UserAdmin',
  '24099bff-144e-48fb-b4d6-2df5ecfaeee7': 'LocationAdmin'
} as const;
