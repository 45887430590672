import 'utils/i18n';
import * as serviceWorker from './serviceWorker';
import { getAppSettings } from 'utils/settings';
import App from './App';
import React from 'react';
import ReactDOM from 'react-dom/client';
import Redirecting from 'views/Auth/Redirecting';

async function prepareMocks() {
  const { useMockData } = getAppSettings();
  if (useMockData) {
    const { worker } = await import('_mocks_');
    await worker.start({
      onUnhandledRequest(req) {
        const url = new URL(req.url);
        if (url.pathname.startsWith('/api')) {
          // eslint-disable-next-line no-console
          console.error('Found an unhandled %s request to %s', req.method, url.href);
        }
      }
    });
  }
}

prepareMocks().then(() => {
  ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <React.Suspense fallback={<Redirecting />}>
      <App />
    </React.Suspense>
  );

  serviceWorker.unregister();
});
