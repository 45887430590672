import { PayloadAction, createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit';
import { ProductKeys, isUserAuthorizedOnProducts } from 'utils/settings';
import { RootState } from 'RootTypes';

export interface Branch {
  assetEnabled: boolean;
  id: string;
  isRtlsEnabled: boolean;
  name: string;
  patientEnabled: boolean;
}

export type BranchCountPair = {
  branchId: string;
  count: number;
};

const branchesAdapter = createEntityAdapter<Branch>();

type BranchState = {
  userBranchIds: string[];
};

const initialState: BranchState = { userBranchIds: [] };

const branchSlice = createSlice({
  initialState: branchesAdapter.getInitialState(initialState),
  name: 'branches',
  reducers: {
    branchesLoaded: (
      draft,
      action: PayloadAction<{ branches: Branch[]; userBranches: Branch[] }>
    ) => {
      const { branches, userBranches } = action.payload;

      branchesAdapter.setAll(draft, branches);
      draft.userBranchIds = userBranches.map((i) => i.id);
    },
    upsertBranches: branchesAdapter.upsertMany
  }
});

export const { branchesLoaded, upsertBranches } = branchSlice.actions;

const {
  selectById: selectBranchById,
  selectEntities,
  selectIds: selectAllSystemBranchIds
} = branchesAdapter.getSelectors<RootState>((state) => state.branches);

export const selectAllBranchIds = (state: RootState) => state.branches.userBranchIds;

export const selectAllBranches = createSelector(
  selectEntities,
  selectAllBranchIds,
  (entities, userBranchIds) => userBranchIds.map((id) => entities[id])
);

export const selectMultipleBranchesByIds = createSelector(
  selectEntities,
  (state: RootState, ids: string[]) => ids,
  (entities, ids) => ids.map((id) => entities[id])
);

const selectBranchIdFromProps = (_: any, id: string) => id;
const selectBranchesEntities = (state: RootState) => state.branches.entities;

export const selectBranchNameById = createSelector(
  [selectBranchIdFromProps, selectBranchesEntities],
  (id, branches) => branches[id]?.name ?? ''
);

export const selectAssetEnabledBranchIds = createSelector(selectAllBranches, (branches) =>
  branches.filter((i) => i.assetEnabled).map((i) => i.id)
);

export const selectAssetEnabledBranches = createSelector(selectAllBranches, (branches) =>
  branches.filter((i) => i.assetEnabled)
);

export const selectIsPatientEnabled = createSelector(selectAllBranches, (branches) =>
  branches.some((i) => i.patientEnabled)
);

export const selectPatientEnabledBranches = createSelector(selectAllBranches, (branches) =>
  branches.filter((i) => i.patientEnabled)
);

export const selectIsAssetEnabled = createSelector(selectAllBranches, (branches) =>
  branches.some((i) => i.assetEnabled)
);

export const selectIsUserAuthorizedOnProducts = createSelector(
  selectAllBranches,
  (state: RootState, productKeys: ProductKeys[]) => productKeys,
  (branches, productKeys) => isUserAuthorizedOnProducts(productKeys, branches)
);

export const selectEnabledProducts = createSelector(
  selectIsAssetEnabled,
  selectIsPatientEnabled,
  (assetEnabled, patientEnabled): ProductKeys[] => {
    if (assetEnabled && patientEnabled) {
      return ['asset', 'patient'];
    }
    if (assetEnabled) {
      return ['asset'];
    }

    return ['patient'];
  }
);

export const selectAllBranchesByProduct = createSelector(selectAllBranches, (branches) => {
  const isAssetEnabled = branches.some((i) => i.assetEnabled);
  const isPatientEnabled = branches.some((i) => i.patientEnabled);

  if (isAssetEnabled && isPatientEnabled) {
    return branches;
  }

  if (isAssetEnabled) {
    return branches.filter((i) => i.assetEnabled);
  }

  return branches.filter((i) => i.patientEnabled);
});

export const selectRtlsEnabledBranches = createSelector(selectAllBranchesByProduct, (branches) =>
  branches.filter((i) => i.isRtlsEnabled)
);

export { selectBranchById, selectAllSystemBranchIds };

export default branchSlice.reducer;
