import { PLANLIST } from 'helpers/localStorage/storageConstant';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { PlanItem, PlanStatusType } from './types';
import { createListAdapter } from '@borda/cat-core';
import { defaultPlanListState, initialColumnDynamicData } from './data';
import { getColumnsKey } from 'helpers/localStorage';

export const listAdapter = createListAdapter<PlanItem>({
  initialDynamicListDefinitions: initialColumnDynamicData,
  localStorageKey: getColumnsKey(PLANLIST)
});

export const planListSlice = createSlice({
  initialState: listAdapter.getInitialState(defaultPlanListState),
  name: 'planList',
  reducers: {
    ...listAdapter.getReducers(),
    clearFilterPlanName: (draft) => {
      draft.filter.planName = defaultPlanListState.filter.planName;
    },
    updateFilterBranchId: (draft, action: PayloadAction<string>) => {
      draft.filter.branchId = action.payload;
    },
    updateFilterPlanName: (draft, action: PayloadAction<string>) => {
      draft.filter.planName = action.payload;
    },
    updateQuickFilter: (draft, action: PayloadAction<PlanStatusType>) => {
      const status = action.payload;
      const oldStatus = draft.filter.statuses;

      if (status) {
        const index = oldStatus.findIndex((item) => item === status);
        if (index > -1) {
          oldStatus.splice(index, 1);
        } else {
          oldStatus.push(status);
        }
      }
    }
  }
});

export const {
  clearFilterPlanName,
  updateFilterBranchId,
  updateFilterPlanName,
  updateQuickFilter
} = planListSlice.actions;

export default planListSlice.reducer;
