import { NotificationListItem } from './types';
import { createListAdapter } from '@borda/cat-core';
import { createSlice } from '@reduxjs/toolkit';

export const listAdapter = createListAdapter<NotificationListItem>({ size: 5 });

const notificationListSlice = createSlice({
  initialState: listAdapter.getInitialState({}),
  name: 'notificationList',
  reducers: {
    checkAll: listAdapter.checkAll,
    clearChecked: listAdapter.clearChecked,
    itemChecked: listAdapter.checkItem,
    loadingChanged: listAdapter.setLoadingReducer,
    notificationListCleared: listAdapter.clear,
    notificationListRefreshed: listAdapter.setAllItemsReducer
  }
});

export const {
  checkAll,
  clearChecked,
  itemChecked,
  loadingChanged,
  notificationListCleared,
  notificationListRefreshed
} = notificationListSlice.actions;

export default notificationListSlice.reducer;
