import { CatTrans, useCatTranslation } from 'utils/localization';
import { CatTypography } from '@borda/cat-ui';
import { LighthouseOkIcon } from '@borda/cat-icons';
import { LinearProgress } from '@borda/cat-mui';
import { NotificationListItem } from 'store/slices/notifications/list/types';
import { isArrayNullOrEmpty } from '@borda/cat-core';
import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import NotificationCard from './NotificationCard/NotificationCard';
import Scrollbars from 'react-custom-scrollbars-2';

type Props = {
  allNotifications: NotificationListItem[];
  fetchNextPage: () => void;
  hasNextPage: boolean;
  notificationsLoading: boolean;
  onClose: () => void;
};

function Content(props: Props) {
  const { allNotifications, fetchNextPage, hasNextPage, notificationsLoading, onClose } = props;

  const { t } = useCatTranslation();

  const isNoDataAvailable = isArrayNullOrEmpty(allNotifications) && !notificationsLoading;

  const { inView, ref: infiniteScrollRef } = useInView();

  useEffect(() => {
    if (inView && hasNextPage) {
      // fetchNextPage cancels any running query
      // If query invalidation starts and fetchNextPage is called after that, invalidation fails
      // e.g. remounting the list with 3 items doesn't invalidate the query
      // Alternative: fetchNextPage?.({cancelRefetch:false}); without hasNextPage
      fetchNextPage?.();
    }
  }, [inView, fetchNextPage, hasNextPage]);

  return (
    <div className="flex grow flex-col pt-2.5">
      {isNoDataAvailable ? (
        <div className="grid h-full items-center">
          <div className="grid justify-items-center gap-4">
            <LighthouseOkIcon style={{ fontSize: '64px' }} />
            <div className="grid gap-1">
              <CatTypography className="text-center opacity-80" variant="subtitle1">
                {t('notifications.empty_list_title')}
              </CatTypography>
              <CatTypography className="text-center opacity-60" variant="body1">
                <CatTrans i18nKey="notifications.empty_list_description" t={t} />
              </CatTypography>
            </div>
          </div>
        </div>
      ) : (
        <>
          {notificationsLoading && <LinearProgress className="w-full" />}
          <Scrollbars>
            <div className="grid content-start justify-items-center px-6">
              <div className="grid w-full gap-2">
                {allNotifications.map((notification) => (
                  <NotificationCard
                    key={notification.id}
                    notification={notification}
                    onClose={onClose}
                    ref={infiniteScrollRef}
                  />
                ))}
              </div>
            </div>
          </Scrollbars>
        </>
      )}
    </div>
  );
}

export default Content;
