import { ArrowLeftIcon } from '@borda/cat-icons';
import { CatButton, CatButtonProps } from '@borda/cat-ui';
import { useCatTranslation } from 'utils/localization';
import React from 'react';

function GoBackButton(props: CatButtonProps) {
  const { t } = useCatTranslation();
  const {
    children = t('common.back'),
    color = 'darkGrey',
    size = 'large',
    startIcon = <ArrowLeftIcon />,
    ...rest
  } = props;
  return (
    <CatButton
      backgroundColor="transparent"
      color={color}
      size={size}
      startIcon={startIcon}
      {...rest}
    >
      {children}
    </CatButton>
  );
}

export default GoBackButton;
