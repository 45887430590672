import { Brand } from 'store/slices/asset/asset/common/brands';
import { PayloadAction, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { initialState } from './data';

export const brandsAdapter = createEntityAdapter<Brand, string>({
  selectId: (brand) => brand.id,
  sortComparer: (first, second) => first.name.localeCompare(second.name)
});

const brandSlice = createSlice({
  initialState: brandsAdapter.getInitialState(initialState),
  name: 'brands',
  reducers: {
    removeExpandedBrand: (draft) => {
      delete draft.expandedBrandId;
    },
    setExpandedBrand: (draft, action: PayloadAction<string>) => {
      draft.expandedBrandId = action.payload;
    }
  }
});

export default brandSlice.reducer;

export const { removeExpandedBrand, setExpandedBrand } = brandSlice.actions;
