import { CancelIcon } from '@borda/cat-icons';
import { CatButton, CatButtonProps } from '@borda/cat-ui';
import { useCatTranslation } from 'utils/localization';
import React from 'react';

function DiscardButton(props: CatButtonProps) {
  const { t } = useCatTranslation();
  const {
    children = t('common.discard'),
    color = 'red',
    endIcon = <CancelIcon />,
    size = 'large',
    ...rest
  } = props;
  return (
    <CatButton color={color} endIcon={endIcon} size={size} {...rest}>
      {children}
    </CatButton>
  );
}

export default DiscardButton;
