import { CatIconButton } from '@borda/cat-ui';
import { Control, get, useFormState, useWatch } from 'react-hook-form';
import { EditIcon } from '@borda/cat-icons';
import { styled } from '@borda/cat-mui';
import { useEffect, useState } from 'react';
import ReadonlyTextField from 'components/ReadonlyTextField/ReadonlyTextField';

const StyledReadonlyTextField = styled(ReadonlyTextField)({
  '& .edit-button': {
    opacity: 0,
    transition: 'opacity 0.3s ease-out'
  },
  '&:hover .edit-button': {
    opacity: 1
  }
});

type Props = {
  control: Control<any, any>;
  disabled?: boolean;
  editable?: boolean;
  formatValue?: (value: string) => string;
  label: string;
  loading?: boolean;
  name: string;
  onEditClick?: () => void;
  readonly: boolean;
  readonlyAdornments?: React.ReactNode;
};

export type FormComponentProps<T> = T & {
  control: Control<any, any>;
  label: string;
  name: string;
};

export function withFormReadonlyMode<T>(
  WrappedComponent: React.FunctionComponent<FormComponentProps<T>>
) {
  return function ReadonlyMode(props: T & Props) {
    const {
      control,
      disabled,
      editable = true,
      formatValue = (value) => value,
      label,
      loading = false,
      name,
      onEditClick,
      readonly,
      readonlyAdornments,
      ...rest
    } = props;

    const value = useWatch({ control, name });
    const { errors } = useFormState({ control, name });
    const error = get(errors, name);

    const [autoFocus, setAutoFocus] = useState(false);

    useEffect(() => {
      if (readonly && autoFocus) {
        setAutoFocus(false);
      }
    }, [readonly, autoFocus]);

    const handleEdit = () => {
      setAutoFocus(true);
      onEditClick();
    };

    if (readonly) {
      return (
        <StyledReadonlyTextField
          {...(rest as any as T)}
          disabled={disabled}
          endAdornment={
            <>
              {readonlyAdornments && readonlyAdornments}
              {editable && (
                <CatIconButton className="edit-button" onClick={handleEdit}>
                  <EditIcon color="blue" />
                </CatIconButton>
              )}
            </>
          }
          errorText={error?.message}
          label={label}
          loading={loading}
          text={formatValue(value)}
        />
      );
    }

    return (
      <WrappedComponent
        {...(rest as any as T)}
        autoFocus={autoFocus}
        control={control}
        disabled={disabled}
        label={label}
        name={name}
      />
    );
  };
}
