import { ATTRIBUTE_CONFIGURATION } from 'routes/constant-route';
import { AttributeObject, attributeObjects } from 'views/AttributeConfigurations/types';
import { AuthKeys, User, isUserAuthorized } from 'store/slices/session';
import { Navigate, Route } from 'react-router-dom';
import { RouteGuard } from 'components/RouteGuards/AuthGuard';
import { SuspenseOutlet, delayedLazy } from 'routes/helpers';

const Attributes = delayedLazy(() => import('views/AttributeConfigurations/Attributes'));
const AddAttribute = delayedLazy(
  () => import('views/AttributeConfigurations/Modify/AddAttributePage')
);
const EditAttribute = delayedLazy(
  () => import('views/AttributeConfigurations/Modify/EditAttributePage')
);
export default function renderAttributeRoutes(sessionUser: User) {
  return (
    <Route
      element={
        <RouteGuard authKey="attributeConfiguration">
          <SuspenseOutlet />
        </RouteGuard>
      }
      path={ATTRIBUTE_CONFIGURATION}
    >
      <Route
        element={
          <RouteGuard authKey={attributeObjectAuthKeys.asset} products={['asset']}>
            <Attributes attributeObject="asset" />
          </RouteGuard>
        }
        path="asset"
      >
        <Route element={<AddAttribute attributeObject="asset" />} path="add" />
        <Route element={<EditAttribute attributeObject="asset" />} path="edit/:attributeId" />
      </Route>
      <Route
        element={
          <RouteGuard authKey={attributeObjectAuthKeys.patient} products={['patient']}>
            <Attributes attributeObject="patient" />
          </RouteGuard>
        }
        path="patient"
      >
        <Route element={<AddAttribute attributeObject="patient" />} path="add" />
        <Route element={<EditAttribute attributeObject="patient" />} path="edit/:attributeId" />
      </Route>
      <Route
        element={
          <RouteGuard authKey={attributeObjectAuthKeys.user}>
            <Attributes attributeObject="user" />
          </RouteGuard>
        }
        path="user"
      >
        <Route element={<AddAttribute attributeObject="user" />} path="add" />
        <Route element={<EditAttribute attributeObject="user" />} path="edit/:attributeId" />
      </Route>
      <Route
        element={
          <RouteGuard authKey={attributeObjectAuthKeys.location}>
            <Attributes attributeObject="location" />
          </RouteGuard>
        }
        path="location"
      >
        <Route element={<AddAttribute attributeObject="location" />} path="add" />
        <Route element={<EditAttribute attributeObject="location" />} path="edit/:attributeId" />
      </Route>
      <Route element={<Navigate replace to={calculateDefaultNavigation(sessionUser)} />} index />
    </Route>
  );
}

const attributeObjectAuthKeys: Record<AttributeObject, AuthKeys> = {
  asset: 'attributeConfiguration_Asset',
  location: 'attributeConfiguration_Location',
  patient: 'attributeConfiguration_Patient',
  user: 'attributeConfiguration_User'
};

const calculateDefaultNavigation = (sessionUser: User) =>
  attributeObjects.find((obj) => isUserAuthorized(sessionUser, attributeObjectAuthKeys[obj]));
